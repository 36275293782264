import React, { useCallback, useMemo } from 'react';

import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Card, Table, Spin, Button, Modal, Dropdown, Menu } from 'antd';
import { map, size } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useWallet } from 'use-wallet';

import organizationAuctionBeacon from 'contracts/organization-auction-beacon';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import { verified as verifiedState } from 'hooks/verify-organization/state';
import Layout from 'layout/AdminLayout';
import env from 'lib/env';

import { drafts } from '../create/state/auction';

import { useAuctions } from './Auctions.state';
import useColumnDefinition from './components/ColumnDefinitions';
import TransferForm from './components/TransferForm';

export default function Auctions() {
  const wallet = useWallet();
  const saved = useRecoilValue(drafts);
  const verified = useRecoilValue(verifiedState);
  const [state, , loading] = useAuctions(verified.organizationId);
  const columnDefinition = useColumnDefinition();
  const beacon = useContract(organizationAuctionBeacon, {
    address: env.MONEGRAPH_ORGAUCTIONS_FACTORY,
  });
  const executeMethod = useExecuteMethod();

  const history = useHistory();

  const hasState = !loading;
  const savedSize = size(saved);

  const activate = useCallback(async () => {
    await executeMethod(beacon.deployContract, [verified.organizationId], {
      from: wallet.account,
    });
  }, [beacon?.address, verified.organizationId, wallet.account]);

  const createAuction = useCallback(() => {
    if (hasState) {
      if (state.contract) {
        history.push(`/auction/create/${state.contract}`);
      } else {
        const hasAuctions = !!state.auctions.length;

        Modal.confirm({
          title: hasAuctions ? 'Auction Platform Upgraded' : 'Auction Platform',
          icon: <ExclamationCircleOutlined />,
          content: hasAuctions
            ? 'Click below to upgrade the auction platform for your organization'
            : 'Click below to activate the auction platform for your organization',
          okText: hasAuctions ? 'Upgrade' : 'Activate',
          cancelText: 'Later',
          onOk: activate,
        });
      }
    }
  }, [history, state?.contract, state?.auctions, hasState, activate]);

  const draftClick = useCallback(
    (item) => {
      history.push(`/auction/create/${state.contract}?draft=${item.key}`);
    },
    [savedSize, state?.contract]
  );

  const menu = useMemo(
    () =>
      size(saved) ? (
        <Menu mode="vertical" onClick={draftClick}>
          {map(saved, (_, name) => (
            <Menu.Item key={name}>{name}</Menu.Item>
          ))}
        </Menu>
      ) : null,
    [savedSize, draftClick]
  );

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Title>Auctions</Typography.Title>
              </Col>
              <Col>
                {menu ? (
                  <Dropdown.Button
                    type="primary"
                    className="btn-primary"
                    icon={<DownOutlined />}
                    overlay={menu}
                    onClick={createAuction}>
                    New Auction
                  </Dropdown.Button>
                ) : (
                  <Button type="primary" className="btn-primary" onClick={createAuction}>
                    {hasState && !state.contract && !state.auctions.length ? 'Activate' : 'New Auction'}
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center">
              <Col span={24}>
                <Spin spinning={!hasState}>
                  <Table columns={columnDefinition} dataSource={hasState ? state.auctions : []} scroll={{ x: true }} />
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <TransferForm />
    </Layout>
  );
}
