import React from 'react';

import { Image } from 'antd';
import cx from 'classnames';

import styles from './AuctionPlayer.module.css';
import { AuctionPlayerProps } from './AuctionPlayer.types';

export default function AuctionPlayer({ name, creative, cover = false }: AuctionPlayerProps) {
  return (
    <Image
      src={creative}
      preview={false}
      alt={name}
      width="100%"
      height="100%"
      wrapperStyle={{ maxHeight: 'inherit' }}
      className={cx([styles.auctionImage, { [styles.auctionCover]: cover }])}
    />
  );
}
