import { atom } from 'recoil';

export function localStorageEffect(key: string) {
  return function effect({ setSelf, onSet }: any) {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
}

export const defaultState = {
  signature: '',
  organizationId: '',
  timestamp: 0,
  expires: 0,
  error: '',
};

export const verified = atom({
  key: 'verifyOrganization.verified',
  effects: [localStorageEffect('verifyOrganization.verified')],
  default: defaultState,
});

export default {
  atoms: { verified },
};
