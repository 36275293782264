import React, { useCallback, useMemo, useState } from 'react';

import { Alert, Button } from 'antd';
import BN from 'bignumber.js';
import { get } from 'lodash';
import { useHistory } from 'react-router';
import { useWallet } from 'use-wallet';

import buyNowContract from 'contracts/buy-now-auction';
import organizationAuction from 'contracts/organization-auction';
import traditionalContract from 'contracts/traditional-auction';
import useCheckNetwork from 'hooks/use-check-network';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import { AnalyticService, events } from 'lib/analytic';
import { currentNetwork, currentNetworkName } from 'lib/wallet-connect/connectors';
import { AuctionStatus, AuctionType } from 'transforms/auction.types';

export default function BuyButton({ auction, bid, setError }: any) {
  const wallet = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const executeMethod = useExecuteMethod();
  const check = useCheckNetwork();
  const wrongNetwork = currentNetwork != wallet.chainId;

  const { active, isFinalized, auctionContract } = useMemo(() => {
    const [, ...rest] = auction.id.split('/');

    return {
      active: wallet.status === 'connected',
      isFinalized: get(auction, 'status', AuctionStatus.OPEN) === AuctionStatus.FINALIZED,
      auctionContract: rest.length
        ? organizationAuction
        : auction.type === AuctionType.BUYNOW
        ? buyNowContract
        : traditionalContract,
    };
  }, [wallet.status, auction.id, auction.status]);

  const contract = useContract(auctionContract, {
    address: auction.contract,
  });

  const onSettle = useCallback(async () => {
    setError('');
    try {
      if (!isFinalized && auction.hasEnded) {
        const args = auction.isOrganizational ? [auction.index] : [];

        await executeMethod(contract.finalize, args, {
          from: wallet.account,
        });

        AnalyticService.track(events.finalize, { auction: auction.id, type: auction.type });
      }
    } catch (e) {
      switch (e.code) {
        // user rejected the transaction
        case 4001:
          break;

        // insufficient funds
        case -32000:
          console.error(e);
          setError('Please deposit ETH into your metamask wallet, insufficient funds to complete transaction.');
          break;

        default:
          console.log(e);
      }
    }
  }, [executeMethod, isFinalized, auction.hasEnded, contract, wallet.account]);

  const onBuy = useCallback(async () => {
    setError('');
    setIsLoading(true);

    const minimumBid = BN.isBigNumber(auction.minimumBid) ? auction.minimumBid : new BN(auction.minimumBid);

    try {
      const args = auction.isOrganizational ? [auction.index] : [];

      await executeMethod(contract.bid, args, {
        from: wallet.account,
        value: minimumBid.gt(bid) ? auction.minimumBid : bid,
      });

      AnalyticService.track(events.bid, { auction: auction.id, value: bid || auction.minimumBid, type: auction.type });
    } catch (e) {
      switch (e.code) {
        // user rejected the transaction
        case 4001:
          AnalyticService.track(events.cancelBid, {
            auction: auction.id,
            value: bid || auction.minimumBid,
            type: auction.type,
          });
          break;

        // insufficient funds
        case -32000:
          console.error(e);
          setError('Please deposit ETH into your metamask wallet, insufficient funds to complete transaction.');
          break;

        default:
          console.log(e);
      }
    } finally {
      setIsLoading(false);
    }
  }, [executeMethod, contract, wallet.account, auction.minimumBid, bid, currentNetwork, wallet.chainId]);

  const { text, onClick, disabled } = useMemo(() => {
    let text = auction.type === AuctionType.BUYNOW ? 'Buy Now' : 'Place a bid';
    let onClick: () => void | Promise<void> = onBuy;
    let disabled = false;

    if (active && wrongNetwork) {
      text = 'Switch Network';
      onClick = () => {
        check();
      };

      disabled = wallet.connector === 'walletconnect';
    } else if (!active) {
      text = 'Connect Wallet';
      onClick = () => history.push('/wallet');
    } else if (isFinalized) {
      text = 'Sold';
      disabled = true;
    } else if (auction.hasEnded) {
      text = 'Finalize Auction';
      onClick = onSettle;
    }

    return { text, onClick, disabled };
  }, [onSettle, active, isFinalized, wrongNetwork, wallet.connector, onBuy, auction, check]);

  return auction.status !== AuctionStatus.UPCOMING ? (
    <>
      <Button
        type="primary"
        size="large"
        loading={isLoading}
        className="w-100 btn-primary buy-button"
        disabled={disabled}
        onClick={onClick}>
        {text}
      </Button>
      {disabled && active && wrongNetwork ? (
        <Alert
          message="Switch Network"
          description={`Please switch networks on your mobile wallet to the ${currentNetworkName} network`}
          type="info"
          showIcon
          className="mt-2"
          style={{
            margin: 10,
          }}
        />
      ) : null}
    </>
  ) : null;
}
