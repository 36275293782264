import { atom } from 'recoil';

import { ProgressState } from './state.types';

export const progressAtom = atom<ProgressState>({
  key: 'publicmint.status',
  default: {
    status: '',
    title: 'Processing Public Mint',
    subTitle: '',
    steps: {
      current: 0,
      percent: 0,
      status: 'wait',
    },
    errors: [],
  },
});
