import React from 'react';

import { Form, Input } from 'antd';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';

import styles from './RoyaltyAddress.module.css';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please set the royalty address',
  },
  {
    async validator(field: any, address: string) {
      addressCheck.toChecksumAddress(address);
    },
  },
];

export default function RoyaltyAddress() {
  return (
    <Item
      label="Royalty Address"
      rules={rules}
      name="royaltyAddress"
      extra="The ethereum wallet address that will receive the resale royalty"
      validateTrigger="onBlur">
      <Input className={styles.input} />
    </Item>
  );
}
