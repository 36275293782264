import { useState } from 'react';

import { Card, Col, Row, Button, Divider, Collapse } from 'antd';

import AuctionPreview from './AuctionPreview';
import AuctionForm from './Form';
import { DeviceSpecificFormProp } from './Form.type';
import styles from './FormMobile.module.css';

const { Panel } = Collapse;

export default function FormMobile({ form }: DeviceSpecificFormProp) {
  const [activeKey, setActiveKey] = useState('Form');

  const showPreview = () => {
    setActiveKey('Preview');
  };

  return (
    <Row justify="center">
      <Col span={24}>
        <Card>
          <Collapse activeKey={activeKey} bordered={false} ghost>
            <Panel className={styles.collapseItem} showArrow={false} header={() => {}} key="Form">
              <AuctionForm form={form} nextStep={showPreview} isMobile />
            </Panel>
            <Panel className={styles.collapseItem} showArrow={false} header={() => {}} key="Preview">
              <AuctionPreview />
              <Divider />
              <Button type="primary" className="w-100 btn-primary mb-1" onClick={form.submit}>
                Deploy Auction
              </Button>
              <Button type="default" className="w-100 btn-secondary text-white" onClick={(_) => setActiveKey('Form')}>
                Back
              </Button>
            </Panel>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
}
