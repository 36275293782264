/* eslint-disable react/no-multi-comp */
import React from 'react';

import cx from 'classnames';
import { omit } from 'lodash';

export function getCreativeWrapper(custom: any) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return (props: any) => <CreativeWrapper {...props} {...custom} />;
}

export default function CreativeWrapper(props: any) {
  const classes = cx({
    'auction-creative-player-container': true,
    'auction-creative-player-playing': props.playing,
    'auction-creative-player-not-playing': !props.playing,
  });

  return (
    <div className={classes} {...omit(props, 'playing')}>
      {props.children}
    </div>
  );
}
