import React, { useMemo } from 'react';

import { Form, Grid } from 'antd';
import { useHistory } from 'react-router';
import { useWallet } from 'use-wallet';

import Layout from 'layout/AdminLayout';

import usePermissions from '../hooks/use-permissions';

import FormMobile from './components/FormMobile';
import FormPc from './components/FormPc';

const { useForm } = Form;
const { useBreakpoint } = Grid;

export default function Create() {
  const wallet = useWallet();
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints.md === false;
  const [form] = useForm();
  const history = useHistory();
  const { loading, permissions } = usePermissions();

  useMemo(async () => {
    if (!loading && permissions?.auctions?.creator === false) {
      history.push('/');
    }
  }, [permissions, wallet.account, history]);

  const Form = useMemo(() => (isMobile ? <FormMobile form={form} /> : <FormPc form={form} />), [isMobile, form]);

  return <Layout>{Form}</Layout>;
}
