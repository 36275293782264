import { useMemo } from 'react';

import { Button, Form } from 'antd';
import { useWallet } from 'use-wallet';

export default function Submit({ disabled, active }: any) {
  const wallet = useWallet();
  const config = useMemo(() => {
    return active
      ? {
          text: 'Mint Token',
          onClick() {},
        }
      : {
          text: 'Connect with MetaMask',
          onClick(event: any) {
            event?.preventDefault();
            wallet.connect('injected');
          },
        };
  }, [active, wallet]);

  return (
    <Form.Item label="">
      <Button
        type="primary"
        className="w-100 btn-primary"
        htmlType="submit"
        disabled={disabled}
        onClick={config.onClick}>
        {config.text}
      </Button>
    </Form.Item>
  );
}
