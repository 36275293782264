import React, { useCallback } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Input, Upload } from 'antd';

import styles from './FileInput.module.css';

interface FileInputProps {
  onChange?: (value: string | File) => void;
  value?: string | File;
  onBlur?: () => void;
  id?: string;
  label?: string;
  accept?: string;
}

export default function FileInput({ onChange, onBlur, value, id, label = 'Upload Content', accept }: FileInputProps) {
  const beforeUpload = useCallback(
    (file: any) => {
      onChange?.(file);
      onBlur?.();
      return false;
    },
    [onChange, onBlur]
  );

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  const textValue = typeof value == 'string' ? value : value?.name;

  return (
    <span style={{ display: 'flex' }}>
      <Input
        className={styles.textInput}
        id={id}
        onBlur={onBlur}
        onChange={onTextChange}
        value={textValue}
        allowClear
        suffix={
          <Upload beforeUpload={beforeUpload} accept={accept} itemRender={() => null}>
            <Button type="text" size='small' className={styles.uploadButton} icon={<UploadOutlined />}>
              {label}
            </Button>
          </Upload>
        }
      />
    </span>
  );
}
