import crypto from 'crypto';

import { useCallback, useMemo, useState } from 'react';

import * as ethers from 'ethers';
import { isPlainObject } from 'lodash';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';

import env from 'lib/env';

import { verified as verifiedState, defaultState } from './verify-organization/state';
const host = env.MONEGRAPH_RELAY;

function getMessage(organization: string, timestamp: string) {
  const hash = crypto
    .createHash('sha256')
    .update(`${organization}-${env.ETHERNET_CHAIN_ID}-${timestamp}`, 'utf8')
    .digest('hex');

  return ethers.utils.hexlify(ethers.utils.toUtf8Bytes(`Please, sign this message to proceed: ${hash}`));
}

export default function useVerifyOrganization(_organizationId?: string): any {
  const wallet = useWallet();
  const [verified, setVerified] = useRecoilState(verifiedState);
  const [loading, setLoading] = useState(false);

  const organizationId = _organizationId || verified.organizationId;

  useMemo(() => {
    if (verified.expires !== 0 && verified.expires <= parseInt(moment().format('X'))) {
      setVerified(defaultState);
    }
  }, [verified.expires]);

  const sign = useCallback(async () => {
    let verified: any = {};

    try {
      setLoading(true);

      const timestamp = moment().format('X');
      const message = getMessage(organizationId, timestamp);
      const signRequest = await wallet?.ethereum?.send('personal_sign', [message, wallet.account]);

      let signature = signRequest;

      if (isPlainObject(signature)) {
        signature = signRequest.result;
      }

      const response = await fetch(`${host}/verify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signature,
          organizationId,
          timestamp,
        }),
      });

      const { status, error } = await response.json();

      if (status) {
        verified = {
          signature,
          organizationId,
          timestamp: parseInt(timestamp),
          expires: parseInt(moment(timestamp, 'X').add(24, 'hours').format('X')),
          error: '',
        };
      } else {
        verified = {
          ...defaultState,
          error: error,
        };
      }
    } catch (error) {
      verified = {
        ...defaultState,
        error: 'user:rejected',
      };
    } finally {
      setLoading(false);
      setVerified(verified);
    }

    return verified;
  }, [wallet?.ethereum, wallet?.account, organizationId]);

  const getSignature = useCallback(
    async (payload: Record<string, unknown>, params = verified, retry: boolean = false): Promise<Buffer | false> => {
      const current = parseInt(moment().format('X'));

      if (current < params.expires) {
        try {
          setLoading(true);

          const response = await fetch(`${host}/verify`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              signature: params.signature,
              organizationId,
              timestamp: params.timestamp,
              payload,
            }),
          });

          if (response.ok) {
            const { status, code, signature } = await response.json();

            if (status) {
              return Buffer.from(signature.substr(2), 'hex');
            } else if (code === 401 && !retry) {
              const nextVerified = await sign();

              return getSignature(payload, nextVerified, true);
            } else {
              return false;
            }
          } else {
            return false;
          }
        } catch (e) {
          return false;
        } finally {
          setLoading(false);
        }
      } else {
        const nextVerified = await sign();

        return getSignature(payload, nextVerified, true);
      }
    },
    [wallet?.ethereum, wallet?.account, organizationId, verified.timestamp, setLoading]
  );

  return [
    verified,
    {
      sign,
      getSignature,
    },
    {
      loading: loading,
    },
  ];
}
