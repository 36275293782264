import { useCallback, useEffect, useMemo, useState } from 'react';

import { Card, Col, Row, Spin, Typography, message } from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';

import Form from 'components/PublicMintForm/PublicMintForm';
import { PublicMint, PublicMintFormValue } from 'components/PublicMintForm/PublicMintForm.type';
import { progressAtom } from 'components/PublicMintForm/state';

import { ERC1155, ERC721 } from 'constants/collection';
import monegraphErc1155V2 from 'contracts/monegraph-erc1155-v2';
import monegraphErc721V2 from 'contracts/monegraph-erc721-v2';
import monegraphERC721V3 from 'contracts/monegraph-erc721-v3';
import { useCollection } from 'hooks/use-collection';
import { getContract } from 'hooks/use-contract';
import useQuery from 'hooks/use-query';
import Layout from 'layout/AdminLayout';
import { pinJSONToIPFS } from 'lib/ipfs';
import { pinNftToIPFS } from 'utils/mint/pinToIPFS';

import { FETCH_PUBLIC_MINT_BY_ID, FETCH_PUBLIC_MINT_BY_ID_KEY } from './EditPublicMint.queries';
import { useNfts } from './EditPublicMint.state';

export default function EditPublicMint() {
  const wallet = useWallet();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams<{ contract: string; publicMint: string }>();
  const [, setProgress] = useRecoilState(progressAtom);

  const [data] = useQuery(
    {
      query: FETCH_PUBLIC_MINT_BY_ID,
      variables: {
        publicMintId: params.publicMint,
      },
      validationKey: FETCH_PUBLIC_MINT_BY_ID_KEY,
      transform: ({ data }) => {
        return {
          id: data?.publicMint.id,
          name: data?.publicMint.name,
          allowList: data?.publicMint?.allowlist?.enabled
            ? {
                startTime: moment.unix(data?.publicMint.allowlist.start),
                endTime:
                  data?.publicMint.allowlist.end !== '0' ? moment.unix(data?.publicMint.allowlist.end) : undefined,
                tokensAllowed: data?.publicMint.allowlist.quantity,
                discount: data?.publicMint.allowlist.discount,
                wallets: data?.publicMint.allowlist.wallets.map((wallet: any) => wallet.id),
              }
            : undefined,
          generalPublic: data?.publicMint?.general?.enabled
            ? {
                startTime: moment.unix(data?.publicMint.general.start),
                endTime: data?.publicMint.general.end !== '0' ? moment.unix(data?.publicMint.general.end) : undefined,
                tokensAllowed: data?.publicMint.general.quantity,
                discount: data?.publicMint.general.discount,
              }
            : undefined,
          beneficiaries: data?.publicMint?.beneficiaries?.map((beneficiary: any) => ({
            wallet: beneficiary.wallet.id,
            percentage: beneficiary.percentage / 100,
          })),
          conditions: data?.publicMint?.conditions.map((condition: any) => ({
            ...condition,
            payload: JSON.parse(condition.payload),
          })),
          nfts: data?.publicMint?.tokens?.map((token: any) => {
            const editable = !data.publicMint.recipients.some((recipients: any) => recipients.token.id === token.id);
            return {
              id: token.id,
              tokenId: token.tokenId,
              title: token?.attributes.title,
              artist: token?.attributes.artist,
              year: token?.attributes.year,
              cost: token.cost,
              royalty: token?.attributes.royalty,
              quantity: token.quantity,
              metadataUrl: token.metadata,
              editable: editable,
            };
          }),
          contract: '',
          language: '',
        };
      },
    },
    [params.publicMint]
  );

  const nfts = useNfts(data?.nfts);

  const [collection] = useCollection(wallet?.account, params.contract);

  const contract = useMemo(() => {
    if (collection?.type === ERC1155 && collection?.version === '2') {
      return monegraphErc1155V2;
    }
    if (collection?.type === ERC721 && collection?.version === '2') {
      return monegraphErc721V2;
    }
    if (collection?.type === ERC721 && collection?.version === '3') {
      return monegraphERC721V3;
    }
  }, [collection]);

  const onFinish = useCallback(
    async (values: PublicMintFormValue) => {
      const walletAddress = wallet.account;

      if (!walletAddress) {
        throw Error('Account not connected');
      }

      setProgress((progress) => {
        return {
          ...progress,
          status: 'info',
          title: 'Processing Public Mint',
          subTitle: '',
          steps: {
            current: 1,
            percent: 0,
            status: 'process',
          },
          errors: [],
        };
      });

      try {
        const generatedTokenId = new Date().getTime();

        const hashNft: any[] = [];

        for (let i = 0; i < values.nfts.length; i++) {
          const nft = values.nfts[i];

          setProgress((progress) => {
            return {
              ...progress,
              status: 'info',
              title: 'Processing Public Mint',
              subTitle: '',
              steps: {
                current: 1,
                percent: Math.floor((i / values.nfts.length) * 100),
                status: 'process',
              },
              errors: [],
            };
          });

          hashNft.push(
            await pinNftToIPFS(
              { ...nft, tokenId: nft.tokenId || generatedTokenId + i, language: values.language },
              walletAddress
            )
          );
        }

        const walletHash = await pinJSONToIPFS({
          wallets: values?.allowList?.wallets ?? [],
        });

        const nftHash = await pinJSONToIPFS({
          tokens: hashNft.map((nft: any) => ({
            tokenId: nft.values.tokenId as number,
            cost: nft.values.cost as number,
            metadata: `https://monegraph.mypinata.cloud/ipfs/${nft.hash}`,
            quantity: nft?.values.quantity,
            attributes: {
              artist: nft.values.artist as string,
              year: nft.values.year as string,
              royalty: nft.values.royalty as number,
              title: nft.values.title as string,
            },
          })),
        });

        const publicMint: PublicMint = {
          id: values.id,
          name: values.name,
          allowlist: {
            enabled: !!values.allowList,
            quantity: values?.allowList?.tokensAllowed ?? 0,
            discount: values?.allowList?.discount ?? 0,
            wallets: walletHash,
            startTime: values?.allowList?.startTime.unix() ?? 0,
            endTime: values?.allowList?.endTime?.unix() ?? 0,
          },
          public: {
            enabled: !!values.generalPublic,
            quantity: values?.generalPublic?.tokensAllowed ?? 0,
            discount: values?.generalPublic?.discount ?? 0,
            startTime: values?.generalPublic?.startTime.unix() ?? 0,
            endTime: values?.generalPublic?.endTime?.unix() ?? 0,
          },
          beneficiaries: values.beneficiaries.map((beneficiary: any) => ({
            ...beneficiary,
            percentage: beneficiary.percentage * 100,
          })),
          conditions: values?.conditions,
          nfts: nftHash,
        };

        const hash = await pinJSONToIPFS(publicMint);

        setProgress((progress) => {
          return {
            ...progress,
            status: 'info',
            title: 'Processing Public Mint',
            subTitle: '',
            steps: {
              current: 2,
              percent: 0,
              status: 'process',
            },
            errors: [],
          };
        });

        const contractInstance = await getContract(wallet.ethereum, contract, collection.address);

        await contractInstance.updatePublicMint(values.id, `https://monegraph.mypinata.cloud/ipfs/${hash}`, {
          from: walletAddress,
        });

        message.success('Public Mint created successfully');

        history.push(`/collections/${params.contract}/public-mints`);
      } catch (error: any) {
        console.error(error);

        setProgress((progress) => {
          return {
            ...progress,
            status: 'error',
            steps: {
              ...progress.steps,
              status: 'error',
            },
            errors: [error.message || error.reason || error],
          };
        });
      }
    },
    [collection?.address, contract, wallet?.account, wallet?.ethereum, setProgress]
  );

  useEffect(() => {
    if (data && nfts) {
      setIsLoading(false);
    }
  }, [data, nfts]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col xs={24}>
          <Card>
            <Typography.Title>Edit Public Mint</Typography.Title>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} md={22}>
          <Card>
            <Row justify="center" className="p-2">
              <Col xs={24} lg={24}>
                <Spin spinning={isLoading}>
                  {data && nfts ? <Form initialValues={{ ...data, nfts }} onFinish={onFinish} /> : null}
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
