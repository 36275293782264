import React, { useMemo } from 'react';

import { Col, Empty, Row, Typography } from 'antd';
import cx from 'classnames';
import { get, isPlainObject, keys, map, size, times } from 'lodash';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';

import AuctionCard from 'components/auctioncard/AuctionCard';
import AuctionCardLoading from 'components/auctioncard/loading/Loading';

import { useQueryResultsFromHash } from 'hooks/use-query.state';
import { iframeConfigState } from 'iframeconfig.state';
import { GalleryFilterTypes } from 'iframeconfig.types';

import { selectedFilter } from '../Gallery.state';

import styles from './Auctions.module.css';

export default function Auctions({ hash }: { hash: string }) {
  const auctions = get(useQueryResultsFromHash(hash), 'auctions', null);
  const config = useRecoilValue(iframeConfigState);
  const selected = useRecoilValue(selectedFilter);
  const type = get(config, 'gallery.filters.type', false);
  const hiddeSidebar = get(config, 'gallery.filters.hidden', false);
  const grouped = config?.gallery?.groupBy;
  const orderBy = config?.gallery?.orderBy || [];

  const params = useParams();
  const organizationId = get(params, 'id');

  return useMemo(() => {
    let output = null;

    if (auctions) {
      const number = size(auctions);

      if (grouped && isPlainObject(auctions) && number) {
        const ids = new Set([...orderBy, ...keys(auctions)]);

        output = map(Array.from(ids), (id: string) => {
          const group = auctions[id];
          const filterGroup = type === grouped;
          let groupedAuctions = group?.auctions || [];

          if (grouped !== type) {
            groupedAuctions = groupedAuctions.filter((auction: any) => {
              const identifier = type === GalleryFilterTypes.COLLECTION ? auction.nft.collection.id : auction.seller.id;

              return !selected || identifier === selected;
            });
          }

          return groupedAuctions.length && (!filterGroup || !selected || selected == id) ? (
            <div key={id} style={{ marginBottom: 30 }}>
              <Row>
                <Col>
                  <Typography.Title level={2} style={{ fontSize: 26 }}>
                    {group.name}
                  </Typography.Title>
                </Col>
              </Row>
              <Row>
                <div className={cx(styles.gridContainer, 'auctions_grid')}>
                  {groupedAuctions.length === 1 ? <div /> : null}
                  {groupedAuctions.map((auction: any) => (
                    <AuctionCard key={auction.id} auction={auction} />
                  ))}
                  {groupedAuctions.length === 1 ? <div /> : null}
                </div>
              </Row>
            </div>
          ) : null;
        });

        output = (
          <Col xs={24} md={type && !hiddeSidebar ? 18 : 24}>
            {output}
          </Col>
        );
      } else if (Array.isArray(auctions) && number) {
        output = (
          <Col xs={24} md={type && !hiddeSidebar ? 18 : 24}>
            <div className={cx(styles.gridContainer, 'auctions_grid')}>
              {auctions.map((auction: any) => {
                const id = type === GalleryFilterTypes.COLLECTION ? auction.nft.collection.id : auction.seller.id;

                return !type || !selected || id === selected ? (
                  <AuctionCard key={auction.id} auction={auction} showSpecialLayout={organizationId === '7'} />
                ) : null;
              })}
            </div>
          </Col>
        );
      } else {
        output = (
          <div className={cx(styles.noAuctionsContainer, 'auctions_no_auctions_container')}>
            <Empty description="No Auctions Available" />
          </div>
        );
      }
    } else {
      output = (
        <Col xs={24} md={type && !hiddeSidebar ? 18 : 24}>
          <div className={cx(styles.gridContainer, 'auctions_grid_loading')}>
            {times(6, () => (
              <AuctionCardLoading />
            ))}
          </div>
        </Col>
      );
    }

    return output;
  }, [selected, grouped, orderBy, auctions]);
}
