import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { useWallet } from 'use-wallet';

import env from 'lib/env';

import { BeneficiaryData } from './Beneficiaries.types';
import Beneficiary from './components/Beneficiary';

const { List } = Form;

const rules = [
  {
    async validator(field: RuleObject, beneficiaries: BeneficiaryData[]) {
      if (beneficiaries.length) {
        let total = 0;
        let hasMonegraph = false;

        beneficiaries.forEach((beneficiary) => {
          if (beneficiary) {
            if (beneficiary.wallet === env.MONEGRAPH_WALLET) {
              hasMonegraph = true;
            }

            total += parseFloat(beneficiary.percentage);
          }
        });

        if (total !== 100) {
          throw new Error('Beneficiary percentages must total to 100%');
        } else if (!hasMonegraph) {
          throw new Error('Monegraph wallet missing from beneficiaries');
        }
      }
    },
  },
];

export default function Beneficiaries({ percentage }: any) {
  const wallet = useWallet();

  return (
    <List
      name="beneficiaries"
      rules={rules}
      initialValue={[
        {
          wallet: env.MONEGRAPH_WALLET,
          percentage,
        },
        {
          wallet: wallet.account,
          percentage: 100 - percentage,
        },
      ]}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field: any) => {
            return <Beneficiary key={field.key} field={field} remove={remove} monegraphPercentage={percentage} />;
          })}

          <Form.ErrorList errors={errors} />

          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Beneficiary
            </Button>
          </Form.Item>
        </>
      )}
    </List>
  );
}
