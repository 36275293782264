import moment from 'moment';

export function getHoursMinutesSeconds(isoDate?: string) {
  if (!isoDate) return [0, 0, 0];

  const now = moment();
  const end = moment(isoDate, 'X');
  const diff = moment.duration(end.diff(now));

  return [end.diff(now, 'days'), diff.hours(), diff.minutes(), diff.seconds(), end.diff(now, 'seconds')];
}

export function getReadableDate(isoDate: string) {
  return moment(isoDate).format('MMM D[,] YYYY [at] hh:mm a');
}

export function isCloseToEnd(isoDate: string, minutes: number) {
  const now = moment();
  const end = moment(isoDate);
  const diff = moment.duration(end.diff(now));

  return diff.asMinutes() < minutes;
}
