import React, { useState, useEffect, useMemo } from 'react';

import { Button, Col, Row, Typography } from 'antd';
import cx from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import AuctionCreative from 'components/auction/Creative';

import { iframeConfigState } from 'iframeconfig.state';
import { AuctionStatus, AuctionType } from 'transforms/auction.types';
import { formatNumber } from 'utils/numberFormatter';
import { getHoursMinutesSeconds } from 'utils/time';

import styles from './AuctionCard.module.css';
import { AuctionCardProps } from './AuctionCard.types';

export default function AuctionCard({ auction, showSpecialLayout = false }: AuctionCardProps) {
  const timer = auction.status === AuctionStatus.UPCOMING ? auction.dateStarted : auction.dateEnding;
  const [time, setTime] = useState(getHoursMinutesSeconds(timer));
  const [days, hours, minutes, seconds, left] = time;
  const now = moment().format('X');
  const isSold = [AuctionStatus.FINALIZED, AuctionStatus.ENDED].indexOf(get(auction, 'status')) !== -1;
  const history = useHistory();
  const config = useRecoilValue(iframeConfigState);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getHoursMinutesSeconds(timer));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const classes = cx({
    [styles.container]: true,
    sold: isSold,
    traditional: auction.type === AuctionType.TRADITIONAL,
    buyNow: auction.type === AuctionType.BUYNOW,
    [`auction-${auction.id}-card`]: true,
  });

  const endingIn = useMemo(() => {
    let text = '';
    let className = '';

    if (auction.status === AuctionStatus.UPCOMING) {
      text = `Starting in ${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${
        minutes && !days ? `${minutes}m ` : ''
      }${seconds && !days && !hours ? `${seconds}s` : ''}`;
    } else if (auction.dateEnding != '0') {
      if (auction.status === AuctionStatus.OPEN && left > 30) {
        text = `Ending in ${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${
          minutes && !days ? `${minutes}m ` : ''
        }${seconds && !days && !hours ? `${seconds}s` : ''}`;
      } else if ([AuctionStatus.FINALIZED, AuctionStatus.ENDED].indexOf(auction.status) !== -1) {
        text = 'Auction Closed';
      } else if (left < 30) {
        text = 'Auction Closing';
        className = styles.closing;
      }
    }

    return text ? (
      <Col span={12}>
        <Typography.Title level={5} className={`m-0 text-end ${className}`} type="secondary">
          {text}
        </Typography.Title>
      </Col>
    ) : null;
  }, [days, hours, minutes, seconds, now, auction.status, auction.dateEnding]);

  const { text: buyButtonText, ...buyButtonProps } = useMemo(() => {
    if (isSold) {
      return {
        disabled: true,
        text: 'Sold',
      };
    } else if (auction.type === AuctionType.TRADITIONAL) {
      return {
        text: 'Place Bid',
        onClick: () => history.push(`/auctions/${auction.id}`),
      };
    } else {
      return {
        text: 'Buy',
        onClick: () => history.push(`/auctions/${auction.id}`),
      };
    }
  }, [auction.id, auction.type, history, isSold]);

  return (
    <div className={classes}>
      <Link to={`/auctions/${auction.id}`}>
        <Row className={styles.playerRow}>
          <AuctionCreative
            nft={auction.nft}
            muted={config.gallery?.video?.autoplay}
            autoplay={config.gallery?.video?.autoplay}
            showControls={config.gallery?.video?.controls}
            videoDisabled={config.gallery?.video?.disabled === true}
          />
        </Row>
      </Link>

      <Row className={`px-2 pt-2 ${styles.infoRow}`} justify="space-between">
        <Col className={`o-hidden ${styles.infoCol}`}>
          {showSpecialLayout ? (
            <>
              <Typography.Title level={4} type="secondary" ellipsis={{ rows: 1, expandable: false }}>
                {auction.nft.artist}
              </Typography.Title>
              <Link to={`/auctions/${auction.id}`}>
                <Typography.Title
                  level={3}
                  style={{ marginTop: 0 }}
                  ellipsis={{ rows: 2, expandable: false }}
                  className="auction-card-title-special-layout">
                  {auction.nft.name}
                </Typography.Title>
              </Link>
            </>
          ) : (
            <Link to={`/auctions/${auction.id}`}>
              <Typography.Title level={3} ellipsis={{ rows: 2, expandable: false }} className="auction-card-title">
                {auction.nft.name}
              </Typography.Title>
            </Link>
          )}
        </Col>
      </Row>

      <Row className={`px-2 ${styles.infoRow}`} justify="space-between">
        <Col>
          <Typography.Title level={5} type="secondary" className="auction-card-seller-username">
            @{auction.seller.username}
          </Typography.Title>
        </Col>
        {endingIn}
      </Row>

      <Row className={`px-2 pt-1 pb-2 ${styles.priceRow} auction-card-bottom-row`} align="bottom">
        <Col span={12}>
          <Typography.Title level={5} className="text-white m-0 auction-card-bottom-row-title">
            {auction.type === AuctionType.TRADITIONAL
              ? auction?.bids?.length
                ? 'Current Bid'
                : 'Starting Bid'
              : 'Price'}
          </Typography.Title>
          <Typography.Title level={4} className="text-white m-0 auction-card-bottom-row-price">
            {formatNumber(auction.currentBidInETH)} ETH
          </Typography.Title>
        </Col>
        <Col span={12}>
          {auction.status !== AuctionStatus.UPCOMING ? (
            <Button
              type="primary"
              className="w-100 btn-primary auction-card-bottom-row-button gallery-auction-button"
              {...buyButtonProps}>
              {buyButtonText}
            </Button>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
