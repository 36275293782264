import React from 'react';

import { Col, Row, Typography } from 'antd';
import { padStart } from 'lodash';

import { AuctionStatus } from 'transforms/auction.types';

export default function CountdownTimer({ time, status }: any) {
  const [days, hours, minutes, seconds] = time;

  return (
    <Col xs={24} md={15} className="auction-date-ending">
      <Row>
        <Typography.Title level={5} className="pt-1 auction-date-ending-label">
          Auction {status === AuctionStatus.UPCOMING ? 'starting in' : 'ending in'}
        </Typography.Title>
      </Row>
      <Row>
        {days ? (
          <Col span={8} className="auction-date-ending-days">
            <Typography.Title level={5} className="m-0">
              Days
            </Typography.Title>
            <Typography.Title className="m-0" level={2}>
              {days}
            </Typography.Title>
          </Col>
        ) : null}
        {hours ? (
          <Col span={8} className="auction-date-ending-hours">
            <Typography.Title level={5} className="m-0">
              Hours
            </Typography.Title>
            <Typography.Title className="m-0" level={2}>
              {hours}
            </Typography.Title>
          </Col>
        ) : null}
        {minutes ? (
          <Col span={8} className="auction-date-ending-minutes">
            <Typography.Title level={5} className="m-0">
              Minutes
            </Typography.Title>
            <Typography.Title className="m-0" level={2}>
              {days > 0 ? `${minutes}:${padStart(seconds.toString(), 2, '0')}` : minutes}
            </Typography.Title>
          </Col>
        ) : null}
        {days === 0 ? (
          <Col span={8} className="auction-date-ending-seconds">
            <Typography.Title level={5} className="m-0">
              Seconds
            </Typography.Title>
            <Typography.Title className="m-0" level={2}>
              {seconds}
            </Typography.Title>
          </Col>
        ) : null}
      </Row>
    </Col>
  );
}
