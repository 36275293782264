import monegraphERC1155 from 'contracts/monegraph-erc1155';
import monegraphERC721 from 'contracts/monegraph-erc721';
import useContract from 'hooks/use-contract';
import useQuery from 'hooks/use-query';

import { FETCH_TOKENS_METADATA, FETCH_TOKENS_METADATA_KEY } from '../Create.queries';

export function useTokenMetadata(accountId: string | null, collectionId: string | null, tokenId: string) {
  const contract = useContract(monegraphERC721, {
    address: collectionId as string,
  });

  const contract1155 = useContract(monegraphERC1155, {
    address: collectionId as string,
  });

  return useQuery(
    {
      query: FETCH_TOKENS_METADATA,
      variables: {
        accountId: accountId?.toLowerCase(),
        collectionId: collectionId?.toLowerCase(),
        tokenId,
      },
      validationKey: FETCH_TOKENS_METADATA_KEY,
      async transform({ data }) {
        if (!data?.collection || !data.collection.tokens.length) {
          try {
            const metadata = await contract.tokenURI.call(tokenId);

            const resp = await fetch(metadata.replace(/ipfs:\/\//i, 'https://ipfs.io/ipfs/'));
            const json = await resp.json();

            data.collection = {
              type: 'erc721',
              tokens: [
                {
                  quantity: '1',
                  creative: json.image || json.animation_url,
                  description: json.description,
                  name: json.name,
                },
              ],
            };
          } catch (e) {
            try {
              const metadata = await contract1155.uri.call(tokenId);

              const resp = await fetch(
                metadata.replace(/ipfs:\/\//i, 'https://ipfs.io/ipfs/').replace('{id}', tokenId)
              );
              const json = await resp.json();

              data.collection = {
                type: 'erc1155',
                tokens: [
                  {
                    creative: json.image || json.animation_url,
                    description: json.description,
                    name: json.name,
                  },
                ],
              };
            } catch (e2) {
              console.error(e, e2);
            }
          }
        }

        return data?.collection
          ? {
              type: data.collection.type,
              ...data.collection.tokens[0],
            }
          : null;
      },
    },
    [tokenId, collectionId, contract?.address, contract1155?.address]
  );
}
