import { Form } from 'antd';

import FileInput from 'components/fileinput/FileInput';

import hashURLTransform from './transforms/hash-url';

const { Item } = Form;

export default function Creative({ form }: any) {
  const rules = [
    {
      transform: (value: string | File) => {
        if (typeof value === 'string') {
          const url = hashURLTransform(value);
          return url;
        }

        return value;
      },
      async validator(_: any, value: string | File) {
        if (typeof value == 'string' && value?.length) {
          const response = await fetch(value, {
            method: 'GET',
            mode: 'cors',
            cache: 'reload',
          });

          if (!response.ok) {
            throw new Error(`The url "${value}" did not return a success status code`);
          }

          form.setFieldsValue({ creative: value });
        } else if (!(value instanceof File)) {
          throw new Error('Preview images is required');
        }
      },
    },
  ];

  return (
    <Item
      label="Preview"
      name="creative"
      rules={rules}
      required
      extra="The link / IPFS Hash to preview media for the artwork. This appears in a collector’s wallet or on 3rd party sites."
      validateTrigger="onBlur">
      <FileInput accept="image/*,video/*,audio/*" />
    </Item>
  );
}
