import React, { useMemo } from 'react';

import { Col, Row, Typography } from 'antd';
import { size } from 'lodash';

import { AuctionType } from 'transforms/auction.types';

import { AlreadyOwn } from './AlreadyOwn';
import { BidRow } from './BidRow';
import styles from './Winners.module.css';

export default function Winners({ auction }: any) {
  const bids = auction.history.filter((history: any) => {
    return history.type === 'Bid';
  });
  const number = size(bids);
  const showWinners = useMemo(() => {
    let show = false;

    if (number) {
      if (auction.type === AuctionType.BUYNOW) {
        show = true;
      } else if (auction.type === AuctionType.TRADITIONAL && auction.hasEnded) {
        show = true;
      }
    }
    return show;
  }, [auction.type, auction.hasEnded, number]);

  if (!showWinners) {
    return null;
  }

  return auction.quantity > 1 ? (
    <div className="winners">
      <Typography.Title level={3} className="mt-3">
        Winners
      </Typography.Title>
      <Row className={styles.winnersContainer}>
        <Col span={24}>
          {bids.slice(0, auction.quantity).map((history: any) => {
            return <BidRow key={history.id} history={history} />;
          })}
        </Col>
      </Row>
    </div>
  ) : (
    <AlreadyOwn auction={auction} />
  );
}
