import React, { useCallback, useMemo } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Image, Button } from 'antd';
import { useHistory } from 'react-router';

import Logo from 'assets/metadyn.png';
import useIsEmbedded from 'hooks/use-is-embedded';

import styles from './Header.module.css';
import WalletButton from './header/Wallet';

const { Header } = Layout;

export default function PageHeader() {
  const embedded = useIsEmbedded();
  const history = useHistory();

  const goHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const goBack = useMemo(() => {
    const canGoBack = history.action !== 'POP';

    return canGoBack ? (
      <div className="pt-3 px-3">
        {canGoBack ? <Button type="text" icon={<ArrowLeftOutlined />} onClick={history.goBack} /> : null}
      </div>
    ) : null;
  }, [history]);

  return embedded ? (
    goBack
  ) : (
    <>
      <Header className={styles.container}>
        <Row align="middle" wrap={false}>
          <Col xs={{ flex: 1 }} flex={1}>
            <Image className={styles.logo} preview={false} src={Logo} onClick={goHome} />
          </Col>

          <Col xs={{ flex: 1 }}>
            <WalletButton />
          </Col>
        </Row>
      </Header>
      {goBack}
    </>
  );
}
