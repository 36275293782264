import { useCallback, useMemo, useState } from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, Form, AutoComplete, InputNumber, DatePicker } from 'antd';
import { get } from 'lodash';
import moment from 'moment';

const { Item } = Form;

export default function Trait({ field, remove, form }: any) {
  const values = form.getFieldsValue();
  const [displayType, setDisplayType] = useState(get(values.traits[field.name], 'display_type', 'string'));

  const onDisplayTypeChanged = useCallback((value) => {
    setDisplayType(value);
  }, []);

  const component = useMemo(() => {
    let component = <Input />;

    switch (displayType) {
      case 'number':
        component = <InputNumber type="number" style={{ width: '100%' }} />;
        break;

      case 'date':
        component = <DatePicker defaultValue={moment()} className="w-100" />;
        break;

      default:
        component = <Input />;
    }

    return component;
  }, [displayType]);

  return (
    <Row key={field.key} gutter={16} wrap={false}>
      <Col span={8}>
        <Item
          {...field}
          label="Display Type"
          name={[field.name, 'display_type']}
          fieldKey={[field.fieldKey, 'display_type']}
          rules={[{ required: true, message: 'Display Type is required' }]}>
          <AutoComplete
            options={[{ value: 'string' }, { value: 'number' }, { value: 'date' }]}
            placeholder="Enter Display type"
            onChange={onDisplayTypeChanged}
          />
        </Item>
      </Col>
      <Col span={8}>
        <Item
          {...field}
          label="Trait Type"
          name={[field.name, 'trait_type']}
          fieldKey={[field.fieldKey, 'trait_type']}
          rules={[{ required: true, message: 'Trait Type is required' }]}>
          <Input />
        </Item>
      </Col>
      <Col span={8}>
        <Item
          {...field}
          label="Trait Value"
          name={[field.name, 'value']}
          fieldKey={[field.fieldKey, 'value']}
          rules={[{ required: true, message: 'Trait Value is required' }]}>
          {component}
        </Item>
      </Col>
      <Col span={1} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <MinusCircleOutlined onClick={() => remove(field.name)} />
      </Col>
    </Row>
  );
}
