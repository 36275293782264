import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { ApolloClient, ApolloLink, InMemoryCache } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';

import env from 'lib/env';
const errorLink = onError(({ networkError, graphQLErrors }: any) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, ...error }: any) => {
      // eslint-disable-next-line no-console
      console.error(message, error);
    });
  }
  if (networkError) {
    throw new Error(networkError.toString());
  }
});

const apolloLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      ethereum: env.THEGRAPH_URL,
      polygon: env.THEGRAPH_POLYGON_URL,
    },
    httpSuffix: '',
    // @ts-ignore
    createHttpLink: createHttpLink,
  }) as never,
  errorLink,
]);

export default new ApolloClient({
  cache: new InMemoryCache(),
  link: apolloLink as never,
});
