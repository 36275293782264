import env from 'lib/env';
import isEmbedded from 'utils/is-embedded';

import { gtagFunction } from './ga4.types';

let ga: gtagFunction;

function initialize(measurementId: string) {
  const parent = window.location !== window.parent.location ? document.referrer : document.location.href;
  const params = isEmbedded() ? { embedded_on: parent } : {};

  window.gtag?.('js', new Date());
  window.gtag?.('config', measurementId, {
    debug_mode: env.NODE_ENV === 'development',
    send_page_view: false,
    ...params,
  });

  if (window.gtag) {
    ga = window.gtag;
  } else {
    throw Error('Failed to initialized analytics services');
  }
}

function pageView() {
  if (ga) {
    ga('event', 'page_view');
  } else {
    console.error('Analytics services was not initialized');
  }
}

function track(action: string, data?: { [key: string]: string | number }) {
  if (ga) {
    ga('event', action, data);
  } else {
    console.error('Analytics services was not initialized');
  }
}

function setUserProperty(data: { [key: string]: string | number }) {
  if (ga) {
    ga('set', 'user_properties', data);
  } else {
    console.error('Analytics services was not initialized');
  }
}

function setProperty(data: { [key: string]: string | number }) {
  if (ga) {
    ga('set', data);
  } else {
    console.error('Analytics services was not initialized');
  }
}

export default {
  initialize,
  pageView,
  track,
  setUserProperty,
  setProperty,
};
