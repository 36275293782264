import DeleteButton from './DeleteButton';
import EditButton from './EditButton';

export default [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Seats Used',
    dataIndex: 'accounts',
    key: 'accounts',
  },
  {
    title: 'Seats Available',
    dataIndex: 'seats',
    key: 'seats',
  },
  {
    title: 'Auctions',
    dataIndex: 'auctions',
    key: 'auctions',
  },
  {
    title: 'Collections',
    dataIndex: 'collections',
    key: 'collections',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (_: any, record: any) => (
      <>
        <EditButton record={record} /> <DeleteButton record={record} />{' '}
      </>
    ),
  },
];
