import { useState } from 'react';

import { Row, Col, Typography, Card, Table, Spin, Button } from 'antd';
import { useRecoilValueLoadable } from 'recoil';

import Layout from 'layout/AdminLayout';

import { fetchUsers } from './Users.state';
import columnDefinition from './components/ColumnDefinitions';
import UserForm from './components/UserForm';

export default function Users() {
  const { state: usersState, contents: users } = useRecoilValueLoadable<any>(fetchUsers);
  const [visible, setVisible] = useState(false);

  const createUser = () => {
    setVisible(true);
  };

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Title>Users</Typography.Title>
              </Col>
              <Col>
                <Button type="primary" className="btn-primary" onClick={createUser}>
                  New User
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center">
              <Col span={24}>
                <Spin spinning={usersState === 'loading'}>
                  <Table
                    columns={columnDefinition}
                    dataSource={usersState === 'hasValue' ? users : []}
                    scroll={{ x: true }}
                  />
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <UserForm visible={visible} closeModal={() => setVisible(false)} />
    </Layout>
  );
}
