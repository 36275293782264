/* eslint-disable css-modules/no-undef-class */
import React, { useCallback, useMemo } from 'react';

import { Avatar, Button } from 'antd';
import makeBlockie from 'ethereum-blockies-base64';
import { Link } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import styles from 'layout/header/Wallet.module.css';
import { login } from 'state/accounts';

export default function WalletButton(name: any) {
  const wallet = useWallet();
  const { contents: user } = useRecoilValueLoadable(login(wallet.account));

  const active = useMemo(() => wallet.status === 'connected', [wallet.status]);

  const disconnect = useCallback(() => {
    wallet.reset();
  }, [wallet, localStorage]);

  return (
    <>
      {!active ? (
        <Link to={active ? '' : '/wallet'}>
          <Button type="primary" shape="round" size="large" className={styles.wallet}>
            Connect Wallet
          </Button>
        </Link>
      ) : (
        <div className={styles.avatarContainer}>
          <div className={styles.avatarInnerContainer}>
            <Avatar
              size="default"
              style={{ marginRight: '0.5rem', backgroundColor: '#87d068' }}
              // @ts-ignore
              src={user?.avatar ? user.avatar : makeBlockie(wallet.account)}
            />
            {user?.name ? (
              // @ts-ignore
              user.name
            ) : (
              <>
                {wallet.account?.substring(0, 4)}...
                {wallet.account?.substring(wallet.account.length - 4, wallet.account.length)}
              </>
            )}
          </div>
          <div className={styles.avatarSubSection}>
            <button className={styles.avatarSubSectionBtn} onClick={disconnect}>
              Disconnect
            </button>
          </div>
        </div>
      )}
    </>
  );
}
