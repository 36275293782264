import { Skeleton } from 'antd';

import styles from './Loading.module.css';

const AuctionCardLoading = () => (
  <div>
    <Skeleton.Image className={styles.loadingImage} style={{ height: '100%', width: '100%' }} />
    <Skeleton active title paragraph={{ rows: 2 }} />
  </div>
);

export default AuctionCardLoading;
