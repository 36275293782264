import { useCallback, useMemo } from 'react';

import { Card, Col, message, Row, Typography } from 'antd';
import { chunk } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';

import Form from 'components/PublicMintForm/PublicMintForm';
import { PublicMint, PublicMintFormValue } from 'components/PublicMintForm/PublicMintForm.type';
import { progressAtom } from 'components/PublicMintForm/state';

import { ERC1155, ERC721 } from 'constants/collection';
import monegraphErc1155V2 from 'contracts/monegraph-erc1155-v2';
import monegraphErc721V2 from 'contracts/monegraph-erc721-v2';
import monegraphERC721V3 from 'contracts/monegraph-erc721-v3';
import { useCollection } from 'hooks/use-collection';
import { getContract } from 'hooks/use-contract';
import Layout from 'layout/AdminLayout';
import { pinJSONToIPFS } from 'lib/ipfs';
import { pinNftToIPFS } from 'utils/mint/pinToIPFS';

export default function NewPublicMint() {
  const wallet = useWallet();
  const params = useParams<any>();
  const history = useHistory();
  const [, setProgress] = useRecoilState(progressAtom);

  const [collection] = useCollection(wallet?.account, params.contract);

  const contract = useMemo(() => {
    if (collection?.type === ERC1155 && collection?.version === '2') {
      return monegraphErc1155V2;
    }
    if (collection?.type === ERC721 && collection?.version === '2') {
      return monegraphErc721V2;
    }
    if (collection?.type === ERC721 && collection?.version === '3') {
      return monegraphERC721V3;
    }
  }, [collection]);

  const onFinish = useCallback(
    async (values: PublicMintFormValue) => {
      const walletAddress = wallet.account;

      if (!walletAddress) {
        throw Error('Wallet not connected');
      }

      setProgress((progress) => {
        return {
          ...progress,
          status: 'info',
          title: 'Processing Public Mint',
          subTitle: '',
          steps: {
            current: 1,
            percent: 0,
            status: 'process',
          },
          errors: [],
        };
      });

      try {
        const generatedTokenId = new Date().getTime();

        const hashNft: any[] = [];

        const chunkedNfts = chunk(values.nfts, 5);

        let hasCost = false;

        for (let i = 0; i < chunkedNfts.length; i++) {
          const nfts = chunkedNfts[i];

          setProgress((progress) => {
            return {
              ...progress,
              status: 'info',
              title: 'Processing Public Mint',
              subTitle: '',
              steps: {
                current: 1,
                percent: Math.floor((i / chunkedNfts.length) * 100),
                status: 'process',
              },
              errors: [],
            };
          });

          const promises = nfts.map(async (nft) => {
            const { hash, values: returned } = await pinNftToIPFS(
              { ...nft, tokenId: nft.tokenId || generatedTokenId + i, language: values.language },
              walletAddress
            );

            if (!hasCost && returned.cost > 0) {
              hasCost = true;
            }

            return pinJSONToIPFS({
              tokenId: returned.tokenId as number,
              cost: returned.cost as number,
              metadata: `https://monegraph.mypinata.cloud/ipfs/${hash}`,
              quantity: returned.quantity,
              attributes: {
                artist: returned.artist as string,
                year: returned.year as string,
                royalty: returned.royalty as number,
                title: returned.title as string,
              },
            });
          });

          const toAdd = await Promise.all(promises);

          hashNft.push(...toAdd);
        }

        const walletHash = await pinJSONToIPFS({
          wallets: values?.allowList?.wallets ?? [],
        });

        const nftHash = await pinJSONToIPFS({
          tokens: hashNft,
        });

        const publicMint: PublicMint = {
          id: values.id,
          name: values.name,
          allowlist: {
            enabled: !!values.allowList,
            quantity: values?.allowList?.tokensAllowed ?? 0,
            discount: values?.allowList?.discount ?? 0,
            wallets: walletHash,
            startTime: values?.allowList?.startTime.unix() ?? 0,
            endTime: values?.allowList?.endTime?.unix() ?? 0,
          },
          public: {
            enabled: !!values.generalPublic,
            quantity: values?.generalPublic?.tokensAllowed ?? 0,
            discount: values?.generalPublic?.discount ?? 0,
            startTime: values?.generalPublic?.startTime.unix() ?? 0,
            endTime: values?.generalPublic?.endTime?.unix() ?? 0,
          },
          beneficiaries: hasCost
            ? values.beneficiaries.map((beneficiary: any) => ({
                ...beneficiary,
                percentage: beneficiary.percentage * 100,
              }))
            : [],
          conditions: values.conditions,
          nfts: nftHash,
        };

        setProgress((progress) => {
          return {
            ...progress,
            status: 'info',
            title: 'Processing Public Mint',
            subTitle: '',
            steps: {
              current: 2,
              percent: 0,
              status: 'process',
            },
            errors: [],
          };
        });

        const hash = await pinJSONToIPFS(publicMint);

        const contractInstance = await getContract(wallet.ethereum, contract, collection.address);

        await contractInstance.createPublicMint(values.id, `https://monegraph.mypinata.cloud/ipfs/${hash}`, {
          from: walletAddress,
        });

        message.success('Public Mint created successfully');
        history.push(`/collections/${params.contract}/public-mints`);
      } catch (error: any) {
        console.error(error);

        setProgress((progress) => {
          return {
            ...progress,
            status: 'error',
            steps: {
              ...progress.steps,
              status: 'error',
            },
            errors: [error.message || error.reason || error],
          };
        });
      }
    },
    [collection?.address, contract, wallet?.account, wallet?.ethereum]
  );

  return (
    <Layout>
      <Row className="mb-3">
        <Col xs={24}>
          <Card>
            <Typography.Title>New Public Mint</Typography.Title>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} md={16}>
          <Card>
            <Row justify="center" className="p-2">
              <Col xs={24} lg={18}>
                <Form onFinish={onFinish} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
