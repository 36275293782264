import React from 'react';

import { Form } from 'antd';

import FileInput from 'components/fileinput/FileInput';

import hashURLTransform from './transforms/hash-url';

const { Item } = Form;

export default function Archive({ form }: any) {
  const rules = [
    {
      transform: hashURLTransform,
      async validator(_: any, value: string | File) {
        if (typeof value == 'string' && value?.length) {
          try {
            const response = await fetch(value, {
              method: 'GET',
              mode: 'cors',
              cache: 'reload',
            });

            if (!response.ok) {
              throw new Error(`The url "${value}" did not return a success status code`);
            }
          } catch (e) {}

          form.setFieldsValue({ archive: value });
        }
      },
    },
  ];

  return (
    <Item
      label="Archive"
      rules={rules}
      name="archive"
      validateTrigger="onBlur"
      validateFirst={true}
      extra="A link to a file or archive that contains higher resolution media or any additional material beyond the preview media above">
      <FileInput />
    </Item>
  );
}
