import React from 'react';

import { StopOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Typography } from 'antd';
import cx from 'classnames';

import { transformAddress } from 'transforms/auction';

import styles from './Forbidden.module.css';

export default function UnauthorizedAccount({ account, isAdmin }: any) {
  return account && isAdmin === false ? (
    <>
      <Row justify="center">
        <Col>
          <StopOutlined className="p-3 fs-8" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography.Title level={5} className={cx(['text-center', styles.text])}>
            This account does not currently hold a seat. You are currently connected as:&nbsp;
            {account ? transformAddress(account) : ''}
          </Typography.Title>
        </Col>
      </Row>

      <Divider className={styles.divider} />
    </>
  ) : null;
}
