import moment from 'moment';
import { selectorFamily } from 'recoil';

import apollo from 'lib/apollo/client';
import { priceInEthereum } from 'pages/auctions/home/state';
import { transformAuction } from 'transforms/auction';

import { FETCH_AUCTIONS } from './AllAuctions.queries';

export const fetchAuctions = selectorFamily({
  key: 'auctions.fetch',
  get: () => async ({ get }) => {
    const ethereumValue = await get(priceInEthereum);

    const request = await apollo.query({
      query: FETCH_AUCTIONS,
      variables: {},
    });

    const response = request.data.auctions.map((auction: any) =>
      transformAuction(auction, { ethereumValue, blockTimestamp: parseInt(moment().format('X')) })
    );
    return response;
  },
});
