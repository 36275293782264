import React from 'react';

import { Form, FormInstance, Input } from 'antd';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';

import useLocationQuery from 'hooks/use-location-query';

const { Item } = Form;

const rules = [
  ({ getFieldValue }: any) => ({
    async validator(field: any, address: string) {
      const asset = getFieldValue('asset');

      if (asset !== 'external') {
        if (address) {
          addressCheck.toChecksumAddress(address);
        } else {
          throw new Error('Contract Address is required');
        }
      }
    },
  }),
];

export default function ContractAddress({ form }: { form: FormInstance }) {
  const location = useLocationQuery();
  const asset = form.getFieldValue('asset');

  return (
    <Item
      label="NFT Contract"
      required
      name="contract"
      extra="Contract Address that holds the NFT"
      rules={rules}
      hidden={asset !== 'custom'}
      normalize={(s) => s.trim()}>
      <Input readOnly={!!location.get('address')} />
    </Item>
  );
}
