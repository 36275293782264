import React from 'react';

import { Col, List } from 'antd';
import { get, size } from 'lodash';
import { useRecoilValue } from 'recoil';

import { useQueryResultsFromHash } from 'hooks/use-query.state';
import { iframeConfigState } from 'iframeconfig.state';

import ArtistListItem from './ListItem';

export default function Fitlers({ hash }: { hash: string }) {
  const { auctions, filters: available } = useQueryResultsFromHash(hash, { auctions: [], filters: [] });
  const config = useRecoilValue(iframeConfigState);

  return get(config, 'gallery.filters.hidden', false) !== true && size(auctions) ? (
    <Col xs={24} md={6}>
      <List
        locale={{ emptyText: () => null }}
        dataSource={available}
        renderItem={(filter: any) => <ArtistListItem filter={filter} />}
      />
    </Col>
  ) : null;
}
