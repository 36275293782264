export interface Config {
  organization?: number;
  gallery?: Gallery;
  auction?: Auction;
  theme: Theme;
}

export enum GalleryGroups {
  ARTIST = 'artist',
  COLLECTION = 'collection',
}

export enum GalleryFilterTypes {
  ARTIST = 'artist',
  COLLECTION = 'collection',
}

interface VideoConfig {
  autoplay?: boolean;
  controls?: boolean;
  disabled?: boolean;
}

interface Gallery {
  filters?: GalleryFilters;
  groupBy?: GalleryGroups;
  orderBy?: string[];
  video?: VideoConfig;
  sortBy?: string;
  sortDirection?: string;
}

interface GalleryFilters {
  type?: GalleryFilterTypes;
  title?: string;
  hidden?: boolean;
  include?: string[];
  exclude?: string[];
}

interface Auction {
  hideSeller?: boolean;
  video?: VideoConfig;
}

interface Theme {
  global: {
    backgroundColor: string;
    primaryColor: string;
    secondaryColor: string;
  };
  stylesheet?: string;
}
