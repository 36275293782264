import React, { useCallback, useMemo, useState } from 'react';

import { Col, Divider, Form, Input, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import web3 from 'web3';

import AuctionCreative from 'components/auction/Creative';
import BuyButton from 'components/buybutton/BuyButton';
import { UserButton } from 'components/userbutton/UserButton';

import { AuctionType } from 'transforms/auction.types';
import { formatNumber } from 'utils/numberFormatter';

import styles from './FeaturedAuction.module.css';
import TransactionError from './TransactionError';

const { Item } = Form;

export default function FeaturedAuction({ auction }: any) {
  const goToAuction = useCallback(() => {}, []);

  const minimumBid = useMemo(() => {
    return auction?.id ? web3.utils.fromWei(auction.minimumBid.toString(), 'ether') : void 0;
  }, [auction?.id, auction?.minimumBid]);

  const [bid, setBid] = useState(auction?.minimumBid);
  const [error, setError] = useState<string>('');

  const onChange = useCallback(
    (event) => {
      let next = (event.target.value || '').replace(/[^0-9.]/g, '');

      if (next.length) {
        next = web3.utils.toWei(next, 'ether');
      }

      if (auction.minimumBid.gt(next)) {
        setError(`Minimum bid amount is ${web3.utils.fromWei(auction.minimumBid.toString(), 'ether')} ETH`);
      } else {
        setError('');
      }

      setBid(next);
    },
    [setBid, auction?.minimumBid]
  );

  return (
    <Row justify="center" className="py-3">
      <Row className={`container-xl ${styles.content}`} gutter={[{ sm: 0, md: 0, lg: 100 }, 0]} justify="center">
        <Col span={24} lg={12}>
          <Row className="h-100" justify="center" align="middle">
            <Col onClick={goToAuction}>
              <AuctionCreative nft={auction.nft} />
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Row className="h-100 pt-3" align="middle">
            <Col span={24} md={20}>
              <UserButton username={auction.seller.username} />
              <Link to={`/auctions/${auction.id}`}>
                <Typography.Title className="fs-8 m-0 pt-1" onClick={goToAuction}>
                  {auction.nft.name}
                </Typography.Title>
              </Link>
              <Typography.Title level={5} className="m-0 pt-3">
                Price
              </Typography.Title>
              <Typography.Title className="m-0">{formatNumber(auction.currentBidInETH)} ETH</Typography.Title>
              <Typography.Title className="m-0" type="secondary" level={4}>
                ${formatNumber(auction.currentBidInUSD)} USD
              </Typography.Title>
              <TransactionError error={false} />
              <Divider />
              {auction.type === AuctionType.TRADITIONAL ? (
                <Row className="px-3">
                  <Col span={24}>
                    <Item help={error ? error : void 0} validateStatus={error ? 'error' : void 0}>
                      <Input placeholder={minimumBid} addonAfter="ETH" onChange={onChange} />
                    </Item>
                  </Col>
                </Row>
              ) : null}
              <BuyButton auction={auction} bid={bid} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
}
