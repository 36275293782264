import React, { useMemo } from 'react';

import { Form, Input } from 'antd';
import { get, parseInt } from 'lodash';

import { ERC1155 } from 'constants/collection';
import { useQueryResultsFromHash } from 'hooks/use-query.state';

import styles from './Editions.module.css';

const { Item } = Form;

export default function Editions({ hash, form }: any) {
  const token = useQueryResultsFromHash(hash);
  const held = get(token, 'holders[0].quantity', 0);
  const total = token?.quantity || false;
  const asset = form.getFieldValue('asset');

  const rules = useMemo(
    () => [
      {
        required: true,
        message: 'Please set the number of editions to sell',
      },
      {
        async validator(field: any, value: string) {
          const num = parseInt(value);

          if (isNaN(num) || num <= 0) {
            throw new Error(`Editions must be a positive number, received ${num}`);
          }

          if (total && asset !== 'external' && num > total) {
            throw new Error(`Editions succeed available supply`);
          }
        },
      },
    ],
    [total, asset]
  );

  const extra = useMemo(() => {
    return total && asset === 'external'
      ? 'The number of editions to be sold in this auction.'
      : `The number of editions to be sold in this auction. ${total} in circulation, ${held} owned`;
  }, [asset, total, held]);

  return token?.type === ERC1155 || asset === 'external' ? (
    <Item
      label="Editions to Sell"
      rules={rules}
      name="quantity"
      extra={extra}
      validateFirst={true}
      normalize={(value) => {
        return value.replace(/[^\d]+/g, '');
      }}>
      <Input className={styles.input} />
    </Item>
  ) : null;
}
