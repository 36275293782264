import React from 'react';

import { Form, Input } from 'antd';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';

const { Item } = Form;

const rules = [
  {
    required: true,
    async validator(field: any, address: string) {
      addressCheck.toChecksumAddress(address);
    },
  },
];

export default function OrganizationAdmin({ organizationId }: any) {
  return !organizationId ? (
    <>
      <Item label="Organization Admin">
        <Item
          label="Name"
          name="adminName"
          extra="Organization admin name"
          required
          rules={[{ required: true }]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
          <Input />
        </Item>
        <Item
          label="Wallet"
          name="adminWallet"
          extra="Organization admin wallet address"
          required
          rules={rules}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
          <Input />
        </Item>
      </Item>
    </>
  ) : null;
}
