import { useCallback, useState } from 'react';

import { ColumnsState } from '@ant-design/pro-table';

import { defaultColumns } from '../constants/default-columns';

function extractAvailibleColumns(columns: Record<string, ColumnsState>): string[] {
  return Object.entries(columns)
    .filter(([, value]) => value.show)
    .map(([key]) => key);
}

export default function useVisibleColumns(): [string[], (s: any) => void] {
  const [visible, setVisible] = useState<string[]>(extractAvailibleColumns(defaultColumns));

  const setter = useCallback((s) => {
    setVisible(extractAvailibleColumns(s));
  }, []);

  return [visible, setter];
}
