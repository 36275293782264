export default {
  artistv5: {
    name: 'STD Non-Commercial',
    language: `1.0 Declaration

1.1 $ARTIST is the artist who created this artwork. 

1.2 The artist declares that the following statements are essential and inseparable elements of this artwork.

1.3 These statements constitute a contract between the artist and any owner of this artwork.

1.4 Ongoing ownership and possession of this artwork indicates the owner's acceptance of these terms.


2.0 Definition

2.1 This artwork is the data that returns a value of $SHAHASH from a SHA256 hash function.

2.2 Such data can be found at the URL $URL but may not always be at that URL.

2.3 Any data that reproduces the above value through a SHA256 hash function is the authentic and original artwork and supersedes any changes at the specified url.

2.4 There are no other existing or authorized versions of this artwork besides the one that reproduces the above SHA256 hash value.


3.0 Ownership

3.1 Ownership of this artwork rests with whomever controls this blockchain entry.

3.2 Ownership of this artwork is singular, unique and noneditioned.

3.3 No other ownership of this artwork exists beyond this ownership nor is any other ownership recognized or authorized in a manner that conflicts with the rights granted herein. 


4.0 Rights

4.1 The artist retains all legal right, title, and interest in all intellectual property rights underlying the artwork, including, but not limited to, copyrights and trademarks.

4.2 The owner shall have a non-exclusive, world-wide, assignable, sublicensable, perpetual, and royalty-free license to display the artwork for non-commercial purposes.

4.3 Any exhibition of this artwork shall include the following attributions: $TITLE and $ARTIST and $YEAR.

4.4 A resale royalty of $ROYALTYRATE of the sale price of this artwork is owed by any owner who sells this artwork. 

4.5 Resale royalties are payable in $TOKEN to the address $ADDRESS

4.6 Upon sale of this artwork, the buyer has the right to demand delivery from the seller of data that reproduces the above hash value by any agreed means.

4.7 The artist's rights and conditions contained in this declaration are not ended through any transfer, sale or change in ownership of the artwork.

4.8 The artist's resale royalty and copyrights may be transferred to other parties at the sole discretion of the artist or the artist's estate or agents. 

This document copyright 2021 Monegraph, Inc.
Used under license $LICENSE`,
  },

  custom: {
    name: 'No Template',
    language: '',
  },
};
