import { gql } from 'apollo-boost';

export const WALLET_ACCOUNT = gql`
  query GetAccount($accountId: String) @api(name: "polygon") {
    seat(id: $accountId) {
      id
      name
      avatar
      organization {
        id
        name
      }
      permissions {
        monegraph
        organizationAdmin
        createAuctions
        createCollections
      }
    }
  }
`;
