import { atom } from 'recoil';

import useQuery from 'hooks/use-query';

import { FETCH_COLLECTION } from '../queries/collection';

export const contractAtom = atom<string>({
  key: 'mint.contract',
  default: '',
});

export const isMintingAtom = atom<boolean>({
  key: 'mint.minting',
  default: false,
});

export function useCollection(accountId: string | null, collectionId: string) {
  return useQuery(
    {
      query: FETCH_COLLECTION,
      variables: {
        accountId: accountId?.toLowerCase(),
        collectionId: collectionId.toLowerCase(),
      },
      transform({ data }) {
        return data?.collection;
      },
    },
    [accountId, collectionId]
  );
}

export default {
  atoms: {
    contractAtom,
    isMintingAtom,
  },
};
