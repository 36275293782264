import React from 'react';

import { Col, Row, Typography } from 'antd';

import { BidRow } from './BidRow';
import styles from './History.module.css';

export default function History({ auction }: any) {
  return (
    <>
      <Typography.Title level={3} className="mt-3 auction-detalis-history">
        History
      </Typography.Title>
      <Row className={`${styles.historyContainer} auction-detalis-history-container`}>
        <Col span={24}>
          {auction.history.map((history: any) => {
            return <BidRow key={history.id} history={history} />;
          })}
        </Col>
      </Row>
    </>
  );
}
