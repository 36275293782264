import React, { useCallback, useMemo, useState } from 'react';

import { HighlightOutlined, SendOutlined, SwapOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import useTransferToken from 'hooks/use-transfer-token';

import { transferState } from '../Auctions.state';

export default function AuctionActions({
  record,
  canFinalize,
  onSettle,
}: {
  record: any;
  canFinalize: boolean;
  onSettle: any;
}) {
  const [finalized, setFinalized] = useState(false);
  const [stateTransfer, setTransferState] = useRecoilState(transferState);
  const { canTransfer } = useTransferToken();

  const [isTransferable, setIsTransferable] = useState(false);
  const [address, token] = useMemo(() => (record?.nft?.id ? record.nft.id.split('-') : []), [record?.nft?.id]);

  useMemo(async () => {
    if (record.highestBid !== null && record.hasEnded && record.dateFinalized && (await canTransfer(address, token))) {
      setIsTransferable(true);
    } else {
      setIsTransferable(false);
    }
  }, [address, token, setIsTransferable]);

  const onTransfer = useCallback(
    (auction: any) => {
      setTransferState({
        ...stateTransfer,
        visible: true,
        auction,
      });
    },
    [setTransferState, stateTransfer]
  );

  const finalize = useCallback(
    async (record: any) => {
      await onSettle(record);
      setFinalized(true);
    },
    [onSettle, setFinalized]
  );

  return (
    <>
      {record.status !== 'Ended' && !canFinalize ? (
        <Link to={`/auction/${record.id}/wizard`}>
          <Button icon={<HighlightOutlined />}></Button>
        </Link>
      ) : null}

      {canFinalize && !finalized && (
        <Button
          icon={<SendOutlined />}
          title="Finalize Auction"
          style={{ marginLeft: '0.35rem' }}
          onClick={() => finalize(record)}></Button>
      )}

      {isTransferable && (
        <Button
          title="Transfer NFT"
          icon={<SwapOutlined />}
          style={{ marginLeft: '0.35rem' }}
          onClick={() => onTransfer(record)}></Button>
      )}
    </>
  );
}
