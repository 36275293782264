import { useMemo } from 'react';

import { transform } from 'lodash';
import { cloneDeep, each, get, values } from 'lodash';
import { atom, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import useBlockTimestamp from 'hooks/use-block-timestamp';
import useMonitorQuery from 'hooks/use-monitor-query';
import { iframeConfigState } from 'iframeconfig.state';
import { GalleryFilterTypes, GalleryGroups } from 'iframeconfig.types';
import ajv from 'lib/ajv';
import { transformAuction } from 'transforms/auction';

import { priceInEthereum } from '../home/state';

import { fetchGallery, GET_ORGANIZATION_WALLETS } from './Gallery.queries';

const GET_GALLERY_KEY = 'gallery.get.auctions';

ajv.compile({
  $id: GET_GALLERY_KEY,
  $schema: 'http://json-schema.org/draft-07/schema#',
  required: ['wallets'],
  properties: {
    tags: {
      type: 'array',
      items: {
        type: 'string',
      },
      minItems: 1,
    },
  },
  type: 'object',
});

export const selectedFilter = atom<string | null>({
  key: 'gallery.filters.selected',
  default: null,
});

export const auctions = atom<any>({
  key: 'gallery.auctions',
  default: null,
});

export const filters = atom<any[]>({
  key: 'gallery.filters',
  default: [],
});

export function useMonitorGallery(organizationId: number) {
  const { contents: ethereumValue } = useRecoilValueLoadable<any>(priceInEthereum);
  const config = useRecoilValue(iframeConfigState);
  const getBlockTimestamp = useBlockTimestamp();
  const include = config?.gallery?.filters?.include;
  const exclude = config?.gallery?.filters?.exclude;
  const type = config?.gallery?.filters?.type;
  const groupBy = config?.gallery?.groupBy;
  const sortBy = config?.gallery?.sortBy;
  const sortDirection = config?.gallery?.sortDirection;

  const query = useMemo(() => {
    return fetchGallery(sortBy, sortDirection);
  }, [sortBy, sortDirection]);

  const [wallets] = useMonitorQuery(
    {
      query: GET_ORGANIZATION_WALLETS,
      variables: {
        id: organizationId,
      },
      transform({ data }) {
        return data?.seats.map((seat: any) => seat.id);
      },
    },
    [type, groupBy, include, exclude, organizationId, ethereumValue]
  );

  return useMonitorQuery(
    {
      query,
      variables: {
        wallets,
      },
      validationKey: GET_GALLERY_KEY,
      validate: {
        ethereumValue,
      },
      async transform({ data }) {
        if (data && ethereumValue) {
          const blockTimestamp = await getBlockTimestamp(data._meta.block.number);

          let output: any = {
            filters: [],
            auctions: [],
            collections: {},
            artists: {},
          };

          each(get(data, 'accounts'), (account: any) => {
            if (account.auctions.length) {
              if (type === GalleryFilterTypes.ARTIST) {
                if (
                  (!include || include.indexOf(account.id) !== -1) &&
                  (!exclude || exclude.indexOf(account.id) == -1)
                ) {
                  output.artists[account.id] = {
                    id: account.id,
                    name: account.name,
                  };

                  output.auctions = output.auctions.concat(
                    account.auctions.map((auction: any) =>
                      transformAuction(cloneDeep(auction), { ethereumValue, blockTimestamp })
                    )
                  );
                }
              } else if (type === GalleryFilterTypes.COLLECTION) {
                each(account.auctions, (auction: any) => {
                  const collectionId = auction.nft.collection.id;
                  if (
                    (!include || include.indexOf(collectionId) !== -1) &&
                    (!exclude || exclude.indexOf(collectionId) == -1)
                  ) {
                    output.collections[collectionId] = {
                      id: collectionId,
                      name: auction.nft.collection.name,
                    };

                    output.auctions.push(transformAuction(cloneDeep(auction), { ethereumValue, blockTimestamp }));
                  }
                });
              } else {
                output.auctions = output.auctions.concat(
                  account.auctions.map((auction: any) =>
                    transformAuction(cloneDeep(auction), { ethereumValue, blockTimestamp })
                  )
                );
              }
            }
          });

          output.auctions = output.auctions.filter(
            (auction: any) => auction.id !== '0x979c897b3389cea590b6398511123e8805ff9ca0'
          );

          if (type === GalleryFilterTypes.ARTIST) {
            output.filters = values(output.artists);
          } else if (type === GalleryFilterTypes.COLLECTION) {
            output.filters = values(output.collections);
          }

          if (groupBy) {
            if (groupBy === GalleryGroups.ARTIST) {
              output.auctions = transform(
                output.auctions,
                (transformed: Record<string, any>, auction: any) => {
                  if (!transformed[auction.seller.id]) {
                    transformed[auction.seller.id] = {
                      id: auction.seller.id,
                      name: auction.seller.name,
                      auctions: [],
                    };
                  }

                  transformed[auction.seller.id].auctions.push(auction);

                  return transformed;
                },
                {}
              );
            } else {
              output.auctions = transform(
                output.auctions,
                (transformed: Record<string, any>, auction: any) => {
                  if (!transformed[auction.nft.collection.id]) {
                    transformed[auction.nft.collection.id] = {
                      id: auction.nft.collection.id,
                      name: auction.nft.collection.name,
                      auctions: [],
                    };
                  }

                  transformed[auction.nft.collection.id].auctions.push(auction);

                  return transformed;
                },
                {}
              );
            }
          }

          return {
            auctions: output.auctions,
            filters: output.filters,
          };
        }
      },
    },
    [type, groupBy, include, exclude, wallets, ethereumValue]
  );
}

export default {
  atoms: {
    selectedFilter,
    auctions,
  },
};
