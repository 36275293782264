import { Form, FormItemProps, Input } from 'antd';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';

const { Item } = Form;

export const AddressInputRules = [
  { required: true, message: 'Wallet Address is required' },
  {
    transform(value: string = '') {
      return value?.trim?.();
    },

    async validator(field: any, address: string) {
      addressCheck.toChecksumAddress(address);
    },
  },
];

export default function AddressInput(props: FormItemProps) {
  return (
    <Item rules={AddressInputRules} {...props}>
      <Input />
    </Item>
  );
}
