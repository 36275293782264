import React from 'react';

import { Col, Card, Row } from 'antd';

import AuctionPreview from './AuctionPreview';
import AuctionForm from './Form';
import { DeviceSpecificFormProp } from './Form.type';
import styles from './FormPc.module.css';

export default function FormPc({ form }: DeviceSpecificFormProp) {
  return (
    <Row justify="center">
      <Col span={16}>
        <Card>
          <Row justify="center" className="p-2">
            <Col xs={24} lg={18}>
              <AuctionForm form={form} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={8} className={styles.stickyReview}>
        <Card>
          <AuctionPreview />
        </Card>
      </Col>
    </Row>
  );
}
