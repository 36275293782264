import React, { useCallback, useState } from 'react';

import { Card, Row, Col, Typography, Table, Spin, Button } from 'antd';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useWallet } from 'use-wallet';

import useRecoilEffect from 'hooks/use-recoil-effect';
import Layout from 'layout/AdminLayout';
import apollo from 'lib/apollo/client';

import { FETCH_USER_COLLECTIONS } from './Collections.queries';
import columnsDefinition from './components/ColumnDefinition';

export default function Collections() {
  const [list, setList] = useState(null);
  const wallet = useWallet();
  const history = useHistory();

  useRecoilEffect(
    ({ set }) => () => {
      const subscription = apollo
        .watchQuery({
          query: FETCH_USER_COLLECTIONS,
          fetchPolicy: 'cache-and-network',
          pollInterval: 5000,
          variables: {
            seats: wallet.account?.toLowerCase(),
          },
        })
        .subscribe({
          next: function ({ data }: any) {
            if (data) {
              setList(get(data, 'account.collections', []));
            }
          },
          error: (e: any) => console.error(e),
        });

      return subscription;
    },
    (subscription: ZenObservable.Subscription) => {
      return subscription.unsubscribe();
    },
    [setList, wallet.account]
  );

  const createCollection = useCallback(() => {
    history.push('/collections/create');
  }, [history]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Title>Collections</Typography.Title>
              </Col>
              <Col>
                <Button type="primary" className=" btn-primary" onClick={createCollection}>
                  New Collection
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center">
              <Col span={24}>
                <Spin spinning={!list}>
                  <Table columns={columnsDefinition} dataSource={list ?? []} scroll={{ x: true }} />
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
