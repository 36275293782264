import { useCallback } from 'react';

import { useWallet } from 'use-wallet';
import web3 from 'web3';

import Controller from 'contracts/controller';
import useContract from 'hooks/use-contract';
import env from 'lib/env';

const host = env.MONEGRAPH_RELAY;

const msgParams = {
  domain: {
    chainId: env.ETHERNET_CHAIN_ID,
    name: 'Monegraph Polygon Seat Management',
    verifyingContract: env.MONEGRAPH_CONTROLLER,
    version: '1',
  },
  primaryType: 'ForwardRequest',
  types: {
    EIP712Domain: [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'verifyingContract', type: 'address' },
    ],
    ForwardRequest: [
      { name: 'from', type: 'address' },
      { name: 'to', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'gas', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'data', type: 'bytes' },
    ],
  },
};

export function useRelayMethod() {
  const wallet = useWallet();

  const controller = useContract(Controller, {
    address: env.MONEGRAPH_CONTROLLER,
    provider: new web3.providers.HttpProvider(env.MONEGRAPH_RELAY_PROVIDER),
  });

  const relay = useCallback(
    async (methodKey: string, args: any[]): Promise<any> => {
      const method = controller.methods[methodKey];
      const request = await method.request(...args);
      const gas = await method.estimateGas(...args, {
        from: wallet.account,
      });

      const data = {
        from: wallet.account,
        to: env.MONEGRAPH_CONTROLLER,
        value: '0',
        gas: gas,
        data: request.data,
        nonce: new Date().getTime(),
      };

      // @ts-ignore
      const signature = await wallet.ethereum?.request({
        method: 'eth_signTypedData_v4',
        params: [
          wallet.account,
          JSON.stringify({
            ...msgParams,
            message: data,
          }),
        ],
      });

      return fetch(`${host}/execute`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signature,
          data,
        }),
      });
    },
    [wallet.account, controller?.address]
  );

  return relay;
}
