import React from 'react';

import { Col, Row, Skeleton, Divider, Typography } from 'antd';
import moment from 'moment';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import AuctionCard from 'components/auctioncard/AuctionCard';

import { priceInEthereum } from 'pages/auctions/home/state';
import { transformAuction } from 'transforms/auction';

import { previewState } from '../state/preview';

import styles from './AuctionPreview.module.css';

export default function AuctionPreview() {
  const { contents: ethereumValue, state: ethereumValueState } = useRecoilValueLoadable<any>(priceInEthereum);
  const wallet = useWallet();
  const preview = useRecoilValue(previewState);
  const reservePrice = preview.reservePrice ? preview.reservePrice : '0';

  const mockAuction = transformAuction(
    {
      id: 'id',
      nft: {
        creative: preview.creative?.replace(/ipfs:\/\//i, 'https://ipfs.io/ipfs/'),
        name: preview.name,
      },
      seller: { id: wallet.account },
      reservePriceInETH: web3.utils.toWei(reservePrice, 'ether'),
      dateEnding: preview.closing,
      dateStarted: preview.starting,
    },
    {
      ethereumValue: ethereumValueState === 'hasValue' ? ethereumValue : 1,
      blockTimestamp: parseInt(moment().format('X')),
    }
  );

  return (
    <>
      <Row justify="center">
        <Col span={24}>
          {preview.creative ? (
            <AuctionCard auction={mockAuction} />
          ) : (
            <Skeleton.Image className={styles.imageSkeleton} />
          )}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          {preview.starting ? (
            <Typography.Text className={styles.textSkeleton}>UTC Starting date: {preview.starting}</Typography.Text>
          ) : (
            <Skeleton active={false} title={false} paragraph={{ rows: 1 }} />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {preview.closing && preview.closing !== '0' ? (
            <Typography.Text className={styles.textSkeleton}>UTC Ending date: {preview.closing}</Typography.Text>
          ) : (
            <Skeleton active={false} title={false} paragraph={{ rows: 1 }} />
          )}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          {preview.description ? (
            <Typography.Text className={styles.textSkeleton}>{preview.description}</Typography.Text>
          ) : (
            <Skeleton active={false} title={false} paragraph={{ rows: 3 }} />
          )}
        </Col>
      </Row>
    </>
  );
}
