import { Form, Input } from 'antd';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please provide public mint name',
  },
];

export default function Name() {
  return (
    <Item label="Name" rules={rules} required name="name">
      <Input />
    </Item>
  );
}
