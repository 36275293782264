import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

export default function EditButton({ record }: any): JSX.Element {
  const history = useHistory();

  const editOrganization = () => {
    history.push(`/organization/${record.id}`);
  };

  return (
    <Button icon={<EditOutlined />} onClick={editOrganization}>
      Edit
    </Button>
  );
}
