import React from 'react';

import { Form, Input } from 'antd';
import { parseInt } from 'lodash';

import styles from './Year.module.css';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please enter a valid year',
  },
  {
    async validator(field: any, value: string) {
      const num = parseInt(value);
      const currentYear = new Date().getFullYear();

      if (isNaN(num) || num < 1900 || num > currentYear) {
        throw new Error(`Please enter a valid year between 1900 and ${currentYear}`);
      }
    },
  },
];

export default function Year() {
  return (
    <Item
      label="Year"
      rules={rules}
      name="year"
      extra="The year the work was made"
      validateFirst={true}
      normalize={(value) => {
        return value.replace(/[^\d]+/g, '');
      }}>
      <Input className={styles.input} />
    </Item>
  );
}
