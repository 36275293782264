/* eslint-disable react/no-multi-comp */
import { TableColumnType } from 'antd';
import { Link } from 'react-router-dom';

import { CollectionsActions } from './CollectionActions';

const columns: Array<TableColumnType<any>> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name: string, collection) => <Link to={`/collections/${collection.address}/tokens`}>{name}</Link>,
  },
  { title: 'Symbol', dataIndex: 'symbol' },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Actions',
    dataIndex: 'address',
    render: (address: string) => <CollectionsActions address={address} />,
  },
];

export default columns;
