import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Form, FormInstance, Input, Tag } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { useRecoilValue } from 'recoil';

import { contractAtom } from '../../../../../hooks/use-collection';
import { contractVariable } from '../../contractLanguage';

import contracts from './contract/definitions';

interface ContractLanguageProps {
  form: FormInstance;
}

const { Item } = Form;
const { TextArea } = Input;

const rules = [
  {
    required: true,
    message: 'Please provide the language for the contract.',
  },
];

export default function ContractLanguage({ form }: ContractLanguageProps) {
  const contract = useRecoilValue(contractAtom) as keyof typeof contracts;

  useMemo(() => {
    if (contracts[contract]) {
      form.setFieldsValue({
        language: contracts[contract].language,
      });
    }
  }, [contract, form]);

  const [cursorPosition, setCusorPosition] = useState<{ selectionStart: number; selectionEnd: number }>({
    selectionStart: 0,
    selectionEnd: 0,
  });
  const textareaRef = useRef<TextAreaRef>(null);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCusorPosition({
      selectionStart: event.target?.selectionStart,
      selectionEnd: event.target?.selectionEnd,
    });
  }, []);

  const handleFocus = useCallback((event: React.FocusEvent<any>) => {
    setCusorPosition({
      selectionStart: event.target?.selectionStart,
      selectionEnd: event.target?.selectionEnd,
    });
  }, []);

  useLayoutEffect(() => {
    if (textareaRef && textareaRef.current && textareaRef.current.resizableTextArea) {
      textareaRef.current.resizableTextArea.textArea.selectionStart = cursorPosition.selectionStart;
      textareaRef.current.resizableTextArea.textArea.selectionEnd = cursorPosition.selectionEnd;
    }
  });

  const handleTagPress = useCallback(
    (tag: typeof contractVariable[number]) => (event: any) => {
      event.preventDefault();
      const selectionStart = textareaRef.current?.resizableTextArea?.textArea?.selectionStart ?? 0;
      const selectionEnd = textareaRef.current?.resizableTextArea?.textArea?.selectionEnd ?? 0;
      const value = form.getFieldValue('language') ?? '';

      const newValue = value.substring(0, selectionStart) + tag.key + value.substring(selectionEnd);

      setCusorPosition({
        selectionStart: selectionStart + tag.key.length,
        selectionEnd: selectionEnd + tag.key.length,
      });

      form.setFields([{ name: 'language', value: newValue, touched: true }]);
      form.validateFields();
    },
    [form]
  );

  return (
    <>
      <Item label="Contract Language" extra="The plain language contract between you and the collector" required>
        <Item shouldUpdate noStyle className="mb-2">
          {({ getFieldValue }: FormInstance) => {
            return (
              <div className="mb-2">
                {contractVariable
                  .filter(({ fieldName }) => getFieldValue(fieldName))
                  .map((tag) => (
                    <Tag key={tag.key} onMouseDown={(event) => event.preventDefault()} onClick={handleTagPress(tag)}>
                      {tag.label}
                    </Tag>
                  ))}
              </div>
            );
          }}
        </Item>
        <Item rules={rules} name="language" noStyle>
          <TextArea ref={textareaRef} onChange={handleChange} onMouseUp={handleFocus} autoSize />
        </Item>
      </Item>
    </>
  );
}
