import React, { useCallback, useState } from 'react';

import { ShareAltOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import env from 'lib/env';

import styles from './Share.module.css';

export default function ShareModal({ auctionId }: any) {
  const [visible, setVisible] = useState(false);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const onClick = useCallback(() => setVisible(true), []);

  return true ? null : (
    <>
      <Button type="primary" icon={<ShareAltOutlined />} size="large" className="btn-secondary" onClick={onClick}>
        Embed
      </Button>
      <Modal
        title="Embed this auction"
        centered
        className={styles.modal}
        width={800}
        visible={visible}
        onOk={close}
        onCancel={close}
        okText="Close"
        cancelButtonProps={{ style: { display: 'none' } }}>
        <SyntaxHighlighter language="html" style={a11yDark} wrapLines>
          {`<!-- Add this lines to your html -->
<script src="${env.MONEGRAPH_AUCTIONS_URL}/loader.js"></script>
<script type="text/javascript">
  document.addEventListener("DOMContentLoaded", function(){
    var monegraph = iframeLoader("#yourElementId");

    monegraph.open({
      url: '${env.MONEGRAPH_AUCTIONS_URL}/auctions/${auctionId}',
      width: "100%",
      height: "100%",
      config: {
        theme: {
          global: {
            primaryColor: "#95107F",
            secondaryColor: "#040404",
          }
        }
      },
    });
  });  
</script>`}
        </SyntaxHighlighter>
      </Modal>
    </>
  );
}
