/* eslint-disable react/no-multi-comp */
import { useCallback } from 'react';

import { TableColumnType } from 'antd';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';

import organizationAuction from 'contracts/organization-auction';
import traditionalContract from 'contracts/traditional-auction';
import { getContract } from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import { AnalyticService, events } from 'lib/analytic';
import env from 'lib/env';
import { AuctionStatus } from 'lib/transform';
import { AuctionType } from 'transforms/auction.types';

import { transferState } from '../Auctions.state';

import AuctionActions from './Actions';

export default function useColumnDefinitions(): Array<TableColumnType<any>> {
  const wallet = useWallet();
  const executeMethod = useExecuteMethod();
  const [stateTransfer, setTransferState] = useRecoilState(transferState);

  const onSettle = useCallback(
    async (auction: any) => {
      const auctionContract = auction.isOrganizational ? organizationAuction : traditionalContract;

      const traditional = await getContract(wallet?.ethereum, auctionContract);

      const contract = await traditional.at(auction.contract);
      const args = auction.isOrganizational ? [auction.index] : [];

      try {
        await executeMethod(contract.finalize, args, {
          from: wallet.account,
        });

        AnalyticService.track(events.finalize, { auction: auction.id, type: auction.type });

        setTransferState({
          ...stateTransfer,
          visible: true,
          auction,
        });
      } catch (e: any) {
        switch (e.code) {
          // user rejected the transaction
          case 4001:
            break;

          // insufficient funds
          case -32000:
            console.error(e);
            // setError('Please deposit ETH into your metamask wallet, insufficient funds to complete transaction.');
            break;

          default:
            console.log(e);
        }
      }
    },
    [wallet.account, wallet.ethereum]
  );

  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'nft',
      key: 'nft',
      sorter: {
        compare(a, b) {
          return a.nft.name.localeCompare(b.nft.name);
        },
        multiple: 3,
      },
      render: (value: any, record: any) => (
        <a href={`${env.MONEGRAPH_AUCTIONS_URL}/auctions/${record.id}`}>{value.name}</a>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: {
        compare(a, b) {
          return a.type.localeCompare(b.type);
        },
        multiple: 2,
      },
      filters: [
        {
          text: 'Buy Now',
          value: AuctionType.BUYNOW,
        },

        {
          text: 'Traditional',
          value: AuctionType.TRADITIONAL,
        },
      ],
      filterMultiple: false,
      onFilter(value, record) {
        return record.type === value;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Upcoming',
          value: 'pending',
        },

        {
          text: 'Open',
          value: AuctionStatus.OPEN,
        },

        {
          text: 'Ended',
          value: AuctionStatus.ENDED,
        },

        {
          text: 'Finalized',
          value: AuctionStatus.FINALIZED,
        },
      ],
      onFilter(value, record) {
        return value === 'pending' ? record.dateStarted > moment().format('X') : record.status === value;
      },
      key: 'status',
    },
    {
      title: 'Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      sorter: {
        compare(a, b) {
          return a.dateCreated - b.dateCreated;
        },
        multiple: 1,
      },
      render: (value: any) => moment(value, 'X').format('MMM D[,] YYYY [at] hh:mm a'),
    },
    {
      title: 'Started',
      dataIndex: 'dateStarted',
      key: 'dateStarted',
      sorter: {
        compare(a, b) {
          return a.dateStarted - b.dateStarted;
        },
        multiple: 1,
      },
      render: (value: any) => moment(value, 'X').format('MMM D[,] YYYY [at] hh:mm a'),
    },
    {
      title: 'Ending',
      dataIndex: 'dateEnding',
      key: 'dateEnding',
      sorter: {
        compare(a, b) {
          return a.dateEnding - b.dateEnding;
        },
        multiple: 1,
      },
      render: (value: any) => (value !== '0' ? moment(value, 'X').format('MMM D[,] YYYY [at] hh:mm a') : ''),
    },
    {
      title: 'Reserve Price ETH',
      dataIndex: 'reservePriceInETH',
      key: 'reservePriceInETH',
    },
    {
      title: 'Current Bid',
      dataIndex: 'currentBidInETH',
      key: 'currentBidInETH',
      render: (value: any, record: any) => {
        return record.highestBid ? `${value.toFixed(2)} ETH, $${record.currentBidInUSD.toFixed(2)} USD` : '';
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      width: 150,
      key: 'actions',
      filters: [
        {
          text: 'Pending Finalization',
          value: 'pending',
        },
      ],
      onFilter(_, record) {
        const canFinalize =
          record.highestBid !== null &&
          record.hasEnded &&
          record.type === AuctionType.TRADITIONAL &&
          !record.dateFinalized;

        return canFinalize;
      },
      render: (record: any) => {
        const canFinalize =
          record.highestBid !== null &&
          record.hasEnded &&
          record.type === AuctionType.TRADITIONAL &&
          !record.dateFinalized;

        return <AuctionActions canFinalize={canFinalize} record={record} onSettle={onSettle} />;
      },
    },
  ];
}
