import { useCallback } from 'react';

import { Modal } from 'antd';
import { useWallet } from 'use-wallet';

import { currentNetworkName } from 'lib/wallet-connect/connectors';
import { executeMethod } from 'utils/contracts/execute-method';

import useCheckNetwork from './use-check-network';

export default function useExecuteMethod() {
  const wallet = useWallet();
  const check = useCheckNetwork();

  const execute = useCallback(
    async (method: any, args: any[], context: any, padding = 1.25) => {
      const connected = await check();

      if (!connected) {
        Modal.warning({
          title: 'Incorrect Ethereum Network',
          content: `You are currently connected to the ${wallet.networkName} network, please switch to the ${currentNetworkName} network`,
        });
      }

      return connected ? executeMethod(method, args, context, padding) : false;
    },
    [wallet.chainId, check]
  );

  return execute;
}
