import { useMemo, useRef } from 'react';

import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { ConfigProvider as ProConfigProvider } from '@ant-design/pro-provider';
import { ActionType, EditableProTable } from '@ant-design/pro-table';
import { Button, ConfigProvider, Upload } from 'antd';
import { useRecoilState } from 'recoil';

import Contract from 'components/ContractLanguageInput/Contract';
import ContractLanguage from 'components/ContractLanguageInput/ContractLanguage';

import { BulkMintProps } from './BulkMint.types';
import ClearButton from './components/ClearButton';
import DeleteRow from './components/DeleteRow';
import ErrorList from './components/ErrorList';
import SaveRow from './components/SaveRow';
import ToggleEditing from './components/ToggleEditing';
import { csvTemplate } from './constants/csv-template';
import { defaultColumns } from './constants/default-columns';
import { enUs, enUSIntl } from './constants/intl';
import useColumns from './hooks/use-columns';
import useImportCSV from './hooks/use-import-csv';
import useVisibleColumns from './hooks/use-visible-columns';
import { dataSourceAtom, editableKeysAtom } from './state';

export default function BulkMint({ isERC1155, collectionId, form, name, initialValue }: BulkMintProps) {
  const actionRef = useRef<ActionType>();
  const [dataSource, setDataSource] = useRecoilState(dataSourceAtom);
  const [editableKeys, setEditableKeys] = useRecoilState(editableKeysAtom);

  const [visibleColumns, setVisibleColumns] = useVisibleColumns();
  const importer = useImportCSV(form, name.toString(), actionRef);

  const columns = useColumns(isERC1155);

  useMemo(() => {
    setDataSource([]);
    setEditableKeys([]);

    if (initialValue && initialValue.length) {
      setDataSource(initialValue);
    }
  }, [initialValue]);

  return (
    <ConfigProvider locale={enUs}>
      <ProConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
        <EditableProTable
          bordered
          rowKey="id"
          actionRef={actionRef}
          value={dataSource}
          onChange={setDataSource}
          scroll={{ x: '100%' }}
          columnsState={{
            defaultValue: defaultColumns,
            persistenceKey: `bulkMint-${name}`,
            persistenceType: 'sessionStorage',
            onChange: (s) => setVisibleColumns(s),
          }}
          options={{ density: false, setting: true, reload: false }}
          toolbar={{
            title: 'NFTs',
          }}
          // @ts-ignore
          columns={columns}
          pagination={{
            showQuickJumper: true,
          }}
          editable={{
            editableKeys,
            type: 'multiple',
            deletePopconfirmMessage: 'Delete this line?',
            onlyOneLineEditorAlertMessage: 'Only one line can be edited at the same time',
            onlyAddOneLineAlertMessage: 'Only one line can be added at the same time',
            actionRender: (_row) => [<SaveRow row={_row} />, <DeleteRow row={_row} />],
            onValuesChange: (_, recordList) => {
              setDataSource(recordList);
            },
            onChange(keys) {
              setEditableKeys(keys);
            },
            deleteText: 'Delete',
            saveText: 'Save',
            cancelText: 'Cancel',
          }}
          recordCreatorProps={{
            // @ts-ignore
            record: () => {
              return { id: (Math.random() * 1000000).toFixed(0), editable: true };
            },
            newRecordType: 'dataSource',
            creatorButtonText: 'Add NFT',
          }}
          toolBarRender={() => [
            <Upload onChange={importer} accept={'text/csv'} itemRender={() => null} customRequest={() => null}>
              <Button icon={<UploadOutlined />} type="primary">
                Import
              </Button>
            </Upload>,
            <Button icon={<DownloadOutlined />} type="link" href={csvTemplate} download={`${collectionId}-tokens.csv`}>
              Template
            </Button>,
            <ToggleEditing />,
            <ClearButton />,
          ]}
        />

        <ErrorList />

        <Contract />
        <ContractLanguage form={form} availableTags={visibleColumns} />
      </ProConfigProvider>
    </ConfigProvider>
  );
}
