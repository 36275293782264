import { cloneDeep } from 'lodash';
import { selector } from 'recoil';

import apollo from 'lib/apollo/client';

import { FEATURED_AUCTION } from './queries';

export const featureAuctions = selector({
  key: 'wizard.auctions.featured', // unique ID (with respect to other atoms/selectors)
  get: async ({ get }) => {
    let response: any = null;

    if (!response) {
      const result = await apollo.query({
        query: FEATURED_AUCTION,
      });

      response = cloneDeep(result.data.auctions[0]);

      return response?.id;
    }

    return '';
  },
});
