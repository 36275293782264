import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';

import Discount from 'components/DiscountInput/DiscountInput';
import TokenQuantity from 'components/TokenQuantity/TokensAllowed';

import { ConditionsTypes } from './Conditions.type';

const { Item } = Form;

interface ConditionProps {
  field: FormListFieldData;
  onRemove: () => void;
}

export default function Condition({ field, onRemove }: ConditionProps) {
  return (
    <>
      <Row gutter={12}>
        <Col xs={24} md={12}>
          <Item label="Type" name={[field.name, 'type']} required>
            <Select>
              <Select.Option key={ConditionsTypes.NftsOwns} value={ConditionsTypes.NftsOwns}>
                Own Token
              </Select.Option>
            </Select>
          </Item>
        </Col>
        <Col xs={12} md={6}>
          <TokenQuantity label="Quantity" name={[field.name, 'quantity']} />
        </Col>
        <Col xs={12} md={6}>
          <Discount label="Discount" name={[field.name, 'discount']} />
        </Col>
      </Row>

      <Item dependencies={[['conditions', field.name, 'type']]} noStyle>
        {({ getFieldValue }) => {
          const type = getFieldValue(['conditions', field.name, 'type']) as ConditionsTypes;

          switch (type) {
            case ConditionsTypes.NftsOwns:
              return (
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Item
                      required
                      rules={[{ required: true, message: 'Choose a network' }]}
                      label="Network"
                      name={[field.name, 'payload', 'network']}>
                      <Select>
                        <Select.Option value="ethereum">Ethereum</Select.Option>
                        <Select.Option value="polygon">Polygon</Select.Option>
                      </Select>
                    </Item>
                  </Col>
                  <Col xs={12} md={14}>
                    <Item
                      required
                      rules={[{ required: true, message: 'Please specify a Contract Address' }]}
                      label="Contract Address"
                      name={[field.name, 'payload', 'contractAddress']}>
                      <Input />
                    </Item>
                  </Col>
                  <Col xs={24} md={4}>
                    <Item
                      required
                      rules={[{ required: true, message: 'Please specify a Token Id' }]}
                      label="Token Id"
                      name={[field.name, 'payload', 'tokenId']}>
                      <Input />
                    </Item>
                  </Col>
                </Row>
              );
            default:
              break;
          }
        }}
      </Item>
      <Divider>
        <Button type="link" danger onClick={onRemove} icon={<CloseOutlined />}>
          Delete
        </Button>
      </Divider>
    </>
  );
}
