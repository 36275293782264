import { isString, size } from 'lodash';

import FileInput from 'components/fileinput/FileInput';

import hashURLTransform from 'transforms/hash-url';

export const RequiredFileInputRule = [
  {
    transform: (value: string | File) => {
      if (typeof value === 'string') {
        const url = hashURLTransform(value);
        return url;
      }

      return value;
    },
    async validator(_: any, value: string | File) {
      const stringValue = isString(value);

      if ((stringValue && !size(value)) || (!stringValue && !(value instanceof File))) {
        throw new Error('Media is required');
      }
    },
  },
];

export const FileInputRule = [
  {
    transform: (value: string | File) => {
      if (typeof value === 'string') {
        const url = hashURLTransform(value);
        return url;
      }

      return value;
    },
    async validator(_: any, value: string | File) {
      if (typeof value == 'string' && value?.length) {
        const response = await fetch(value, {
          method: 'GET',
          mode: 'cors',
          cache: 'reload',
        });

        if (!response.ok) {
          throw new Error(`The url "${value}" did not return a success status code`);
        }
      }
    },
  },
];

export default function () {
  return <FileInput accept="image/*,video/*,audio/*" label="" />;
}
