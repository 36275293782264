import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { parseInt } from 'lodash';

const { Item } = Form;

interface TokensAllowedProps {
  label?: string;
  name: NamePath;
}

export default function TokenQuantity({ name, label = 'Tokens Allowed' }: TokensAllowedProps) {
  const rules = [
    {
      async validator(field: any, value: string) {
        const num = parseInt(value);

        if (isNaN(num) || num < 0) {
          throw new Error(`The number of tokens allowed must be higher than 0`);
        }
      },
    },
  ];
  return (
    <Item
      label={label}
      rules={rules}
      validateFirst={true}
      name={name}
      initialValue="1"
      normalize={(value) => {
        return value.replace(/[^\d]+/g, '');
      }}>
      <Input />
    </Item>
  );
}
