import { useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import Controller from 'contracts/controller';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import env from 'lib/env';

export default function DeleteButton({ record }: any): JSX.Element {
  const wallet = useWallet();
  const controller = useContract(Controller, {
    address: env.MONEGRAPH_CONTROLLER,
    provider: new web3.providers.HttpProvider(env.MONEGRAPH_RELAY_PROVIDER),
  });
  const executeMethod = useExecuteMethod();

  const deleteOrganization = useCallback(async () => {
    alert('Switch to relay method');
    await executeMethod(controller.removeOrganization, [record.id], {
      from: wallet.account,
    });
  }, [record.id, controller, wallet.account]);

  return (
    <Popconfirm
      title={`Are you sure to delete ${record.name}?`}
      onConfirm={deleteOrganization}
      okText="Yes"
      cancelText="No">
      <Button icon={<DeleteOutlined />} danger type="primary">
        Delete
      </Button>
    </Popconfirm>
  );
}
