import { selector } from 'recoil';

import apollo from 'lib/apollo/client';

import { FETCH_USERS } from './Users.queries';

export const fetchUsers = selector({
  key: 'users.fetch',
  get: async ({ get }) => {
    const request = await apollo.query({
      query: FETCH_USERS,
      variables: {},
    });

    return request.data.seats.filter((user: any) => user?.permissions?.monegraph);
  },
});
