import React, { useMemo } from 'react';

import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';

const { Item } = Form;

interface CostProps {
  required?: boolean;
  name: NamePath;
  label: string;
}

export default function Discount({ name, required, label }: CostProps) {
  const rules = useMemo(
    () => [
      {
        required: required,
        message: 'Please set the discount',
      },

      {
        transform(value: any) {
          if (!value) {
            return 0;
          }
          return parseFloat(value);
        },
        async validator(_: any, value: number) {
          if (isNaN(value)) {
            throw new Error('Invalid number');
          } else if (value < 0 && value >= 100) {
            throw new Error('Discount percent cannot be lower than 0 or greater then 100');
          }
        },
      },
    ],
    [required]
  );

  return (
    <Item label={label} name={name} rules={rules} validateFirst initialValue="0">
      <Input addonAfter="%" />
    </Item>
  );
}
