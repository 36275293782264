import { useMemo, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Input,
  Skeleton,
  Card,
  Layout as AntdLayout,
  Button,
  Typography,
  Form,
  Select,
  Divider,
  Checkbox,
  Tabs,
} from 'antd';
import { keyBy, uniqBy } from 'lodash';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import SortableListInput from 'components/sortablelistinput/SortableListInput';
import UrlInput from 'components/urlinput/UrlInput';

import useIsEmbedded from 'hooks/use-is-embedded';
import { iframeConfigState } from 'iframeconfig.state';
import { Config, GalleryFilterTypes, GalleryGroups } from 'iframeconfig.types';
import AdminLayout from 'layout/AdminLayout';
import env from 'lib/env';
import events from 'utils/iframe-emitter';

import PageViewer from '../../../../components/pageviewer/PageViewer';

import { userOrganization } from './OrganizationWizard.state';

export default function OrganizationWizard() {
  const wallet = useWallet();
  const embedded = useIsEmbedded();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { contents: organization } = useRecoilValueLoadable<any>(userOrganization(wallet.account));

  const iframeConfig = useRecoilValue(iframeConfigState);
  const [isLoading, setIsLoading] = useState(true);

  const [updateConfig, setUpdateConfig] = useState<Config>(iframeConfig);

  const onSave = () => {
    events.broadcast({
      name: 'monegraph.save',
      // Keep old style configuration to no breack older version of wp plugin
      styles: {
        primaryColor: updateConfig.theme.global.primaryColor,
        secondaryColor: updateConfig.theme.global.secondaryColor,
        backgroundColor: updateConfig.theme.global.backgroundColor,
      },
      theme: updateConfig.theme,
      gallery: updateConfig.gallery,
      organization: organization?.organization?.id,
      organizationId: organization?.organization?.id,
    });
  };

  const [form] = Form.useForm<Config>();

  const artists = useMemo(
    () =>
      uniqBy(
        organization?.auctions?.map((auction: any) => ({ value: auction.nft.artist, label: auction.nft.artist })),
        'value'
      ),
    [organization?.auctions]
  );
  const collections = useMemo(
    () => organization?.collections?.map((collection: any) => ({ value: collection.id, label: collection.name })),
    [organization?.collections]
  );

  const orderByItem = useMemo(() => {
    if (updateConfig.gallery?.groupBy === GalleryGroups.ARTIST) {
      return keyBy(artists, 'value');
    } else if (updateConfig.gallery?.groupBy === GalleryGroups.COLLECTION) {
      return keyBy(collections, 'value');
    } else {
      return {};
    }
  }, [artists, collections, updateConfig.gallery?.groupBy]);

  const filterableItem: Array<{ value: string; label: string }> = useMemo(() => {
    if (updateConfig.gallery?.filters?.type === GalleryFilterTypes.ARTIST) {
      return artists;
    } else if (updateConfig.gallery?.filters?.type === GalleryFilterTypes.COLLECTION) {
      return collections;
    } else {
      return [];
    }
  }, [artists, collections, updateConfig.gallery?.filters?.type]);

  const handleFormValuesChange = (_: any, values: Config) => {
    let newValues = values;
    if (values.gallery?.filters?.type !== updateConfig.gallery?.filters?.type) {
      newValues = {
        ...values,
        gallery: {
          ...values.gallery,
          filters: { ...values.gallery?.filters, include: undefined, exclude: undefined },
        },
      };
    }
    if (values.gallery?.groupBy !== updateConfig.gallery?.groupBy) {
      newValues = { ...values, gallery: { ...values.gallery, orderBy: undefined } };
    }

    form.setFieldsValue(newValues);
    setUpdateConfig(newValues);
  };

  const Layout = embedded ? AntdLayout : AdminLayout;

  const auctionLoader = (
    <>
      <Row justify="center">
        <Col>
          <Skeleton.Avatar size={300} shape="square" active />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12}>
          <Skeleton active />
        </Col>
        <Col xs={12}>
          <Skeleton active />
        </Col>
      </Row>
    </>
  );

  return (
    <Layout>
      <Form form={form} layout="vertical" initialValues={iframeConfig} onValuesChange={handleFormValuesChange}>
        <Card className="mb-3">
          <Row justify="center">
            <Col span={20}>
              <Row>
                <Col>
                  <Typography.Title level={5}>Theme Settings</Typography.Title>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name={['theme', 'global', 'primaryColor']} label="Primary Color">
                    <Input type="color" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['theme', 'global', 'secondaryColor']} label="Secondary Color">
                    <Input type="color" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['theme', 'global', 'backgroundColor']} label="Background Color">
                    <Input type="color" />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <UrlInput
                    label="Stylesheet URL"
                    name={['theme', 'stylesheet']}
                    extra="Use a CSS style sheet to directly override styles"
                  />
                </Col>
              </Row>
              <Row gutter={24} className="mt-3">
                <Col>
                  <Typography.Title level={5}>Gallery Settings</Typography.Title>
                </Col>
              </Row>
              <Divider orientation="left" plain>
                Grouping
              </Divider>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name={['gallery', 'groupBy']} label="Group by" extra="">
                    <Select allowClear>
                      {['COLLECTION'].map((group) => (
                        <Select.Option value={GalleryGroups[group as keyof typeof GalleryGroups]}>
                          {group}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {updateConfig.gallery?.groupBy ? (
                  <Col span={12}>
                    <Form.Item
                      name={['gallery', 'orderBy']}
                      extra="Determine the order to display groups in the gallery, if not specify group will be shown by it’s auction creation date"
                      normalize={(events) => {
                        return events.target.checked ? Object.keys(orderByItem) : undefined;
                      }}
                      getValueProps={(value) => ({ checked: !!value })}>
                      <Checkbox>Use custom order</Checkbox>
                    </Form.Item>
                  </Col>
                ) : null}
                {updateConfig.gallery?.groupBy && updateConfig.gallery?.orderBy ? (
                  <Col span={24} style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                    <Form.Item
                      name={['gallery', 'orderBy']}
                      label="Group Order"
                      help="Drag and drop to change the order"
                      normalize={(values) => values.map((value: any) => value.value)}
                      getValueProps={(values) => {
                        const allItem = Object.values(orderByItem);
                        const sortedItem = values?.map((value: any) => orderByItem[value]) ?? [];

                        return { value: allItem.length > 0 ? uniqBy([...sortedItem, ...allItem], 'value') : [] };
                      }}>
                      <SortableListInput<{ value: string; label: string }>
                        keyExtractor={(item) => item.value}
                        renderItem={({ item, dragging }) => (
                          <Card size="small" className="my-1" style={{ opacity: dragging ? 0.5 : 1 }}>
                            <Row>
                              <Col span={1}>
                                <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
                              </Col>
                              <Col>
                                <Typography.Text>{item.label}</Typography.Text>
                              </Col>
                            </Row>
                          </Card>
                        )}
                      />
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
              <Divider orientation="left" plain>
                Filtering
              </Divider>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item name={['gallery', 'filters', 'type']} label="Filter Type" extra="">
                    <Select allowClear>
                      {['COLLECTION'].map((group) => (
                        <Select.Option value={GalleryFilterTypes[group as keyof typeof GalleryFilterTypes]}>
                          {group}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label=" "
                    name={['gallery', 'filters', 'hidden']}
                    valuePropName="checked"
                    extra="Hides sidebar and title from gallery view">
                    <Checkbox>Hide Sidebar</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={['gallery', 'filters', 'title']} label="Title">
                    <Input
                      allowClear
                      disabled={!updateConfig.gallery?.filters?.type || updateConfig.gallery?.filters.hidden}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['gallery', 'filters', 'include']}
                    normalize={(value: Array<string>) => (value.length === 0 ? undefined : value)}
                    label="Include"
                    extra="List of artists or collections to include in gallery view">
                    <Select
                      mode="multiple"
                      allowClear
                      options={filterableItem}
                      disabled={!updateConfig.gallery?.filters?.type}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['gallery', 'filters', 'exclude']}
                    label="Exclude"
                    extra="List of artists or collections to exclude from gallery view">
                    <Select
                      mode="multiple"
                      allowClear
                      options={filterableItem}
                      disabled={!updateConfig.gallery?.filters?.type}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row></Row>
              <Row justify="end">
                {embedded ? (
                  <Col>
                    <Button type="primary" className="btn-primary" onClick={onSave}>
                      Save Settings
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
      <Row justify="center">
        <Col xs={22}>
          <Card>
            <Row>
              <Col xs={24}>
                {isLoading && auctionLoader}
                {organization && (
                  <PageViewer
                    url={
                      !!organization?.organization?.id
                        ? `${env.MONEGRAPH_AUCTIONS_URL}/gallery/${organization?.organization?.id}`
                        : ''
                    }
                    config={updateConfig}
                    onReady={() => setIsLoading(false)}
                  />
                )}
              </Col>

              <Col xs={24}>
                <Tabs defaultActiveKey={embedded ? 'wordpress' : 'html'}>
                  <Tabs.TabPane tab="HTML" key="html">
                    <SyntaxHighlighter language="html" style={a11yDark} showLineNumbers>
                      {`<!-- Add this lines to your html -->
<script src="${env.MONEGRAPH_AUCTIONS_URL}/loader.js"></script>
<script type="text/javascript">
  let monegraph = iframeLoader("#yourElementId");
  monegraph.open({
    url: '${env.MONEGRAPH_AUCTIONS_URL}/gallery/${organization?.organization?.id ?? 'Organization Id Here'}',
    width: "100%",
    height: "100%",
    config: {
      theme: {
        global: {
          backgroundColor: "${updateConfig.theme.global.backgroundColor}",
          primaryColor: "${updateConfig.theme.global.primaryColor}",
          secondaryColor: "${updateConfig.theme.global.secondaryColor}",
        },
        ${updateConfig.theme.stylesheet ? `stylesheet: "${updateConfig.theme.stylesheet}"` : ''}
      },
      gallery: { 
        filters: ${JSON.stringify(updateConfig.gallery?.filters)}, 
        ${updateConfig.gallery?.groupBy ? `groupBy: "${updateConfig.gallery.groupBy}",` : ''} 
        ${updateConfig.gallery?.orderBy ? `orderBy: ${JSON.stringify(updateConfig.gallery.orderBy)}` : ''}
      } 
    },
  });
</script>`}
                    </SyntaxHighlighter>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Wordpress" key="wordpress">
                    <SyntaxHighlighter language="html" style={a11yDark} showLineNumbers>
                      {`<!-- Add this shortcode to your page -->
[monegraph ${updateConfig.gallery?.filters?.type ? ` filter-type="${updateConfig.gallery?.filters?.type}"` : ''}${
                        updateConfig.gallery?.filters?.hidden
                          ? ` hidden="${updateConfig.gallery?.filters?.hidden}"`
                          : ''
                      }${
                        updateConfig.gallery?.filters?.title ? ` title="${updateConfig.gallery?.filters?.title}"` : ''
                      }${
                        updateConfig.gallery?.filters?.include
                          ? ` include="${updateConfig.gallery?.filters?.include.join()}"`
                          : ''
                      }${
                        updateConfig.gallery?.filters?.exclude
                          ? ` exclude="${updateConfig.gallery?.filters?.exclude.join()}"`
                          : ''
                      }${updateConfig.gallery?.groupBy ? ` group-by="${updateConfig.gallery?.groupBy}"` : ''}${
                        updateConfig.gallery?.orderBy ? ` order-by="${updateConfig.gallery?.orderBy.join()}"` : ''
                      } /]`}
                    </SyntaxHighlighter>
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
