import { EventEmitter } from 'events';

class AppEmitter extends EventEmitter {
  public constructor() {
    super();
    window.addEventListener(
      'message',
      (event) => {
        let name = event.data?.type;

        if (name?.indexOf('iframeloader') === 0) {
          name = name === 'iframeloader.config' ? 'configuration' : name.replace('iframeloader.', '');
          this.emit(name, event.data.config || event.data.payload);
        }
      },
      false
    );
  }

  public broadcast(message: unknown): void {
    /* eslint-disable no-restricted-globals */
    if (self !== parent) {
      parent.postMessage(message, '*');
      this.emit('remote', message);
    }
  }
}

export default new AppEmitter();
