import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';

import { formatNumber } from 'utils/numberFormatter';

import { AlreadyOwnProps } from './AlreadyOwn.types';

export function AlreadyOwn({ auction }: AlreadyOwnProps) {
  return (
    <Row align="middle" className="pt-4 solo-winner">
      <Col span={6} xs={2} md={6}>
        <Divider />
      </Col>
      <Col span={12} xs={20} md={12} className="d-flex align-center">
        <Avatar size={36} icon={<UserOutlined />} />
        <Typography.Title level={5} className="text-center m-0 pt-1">
          Auction won by @{auction.highestBid.bidder.username}
        </Typography.Title>
        <Typography.Title level={4} className="text-center m-0">
          Sold for {formatNumber(auction.currentBidInETH)} ETH ${formatNumber(auction.currentBidInUSD)}
        </Typography.Title>
        <Typography.Title level={5} type="secondary" className="text-center m-0">
          {moment(auction.highestBid?.history.timestamp, 'X').format('MMM D[,] YYYY [at] hh:mm a')}
        </Typography.Title>
      </Col>
      <Col span={6} xs={2} md={6}>
        <Divider />
      </Col>
    </Row>
  );
}
