import { useCallback } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import Controller from 'contracts/controller';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import env from 'lib/env';

export default function DeleteButton({ record }: any) {
  const wallet = useWallet();
  const controller = useContract(Controller, {
    address: env.MONEGRAPH_CONTROLLER,
    provider: new web3.providers.HttpProvider(env.MONEGRAPH_RELAY_PROVIDER),
  });
  const executeMethod = useExecuteMethod();

  const deleteUser = useCallback(async () => {
    alert('switch to relay method');
    await executeMethod(
      controller.revokeRole,
      ['0xafd412158ad8a0b56aeadccf609843677ba8e5373ecaa03bb8daac2189532d38', record.id],
      {
        from: wallet.account,
      }
    );
  }, [record.id, controller, wallet.account]);

  return (
    <Popconfirm title={`Are you sure to delete ${record.name}?`} onConfirm={deleteUser} okText="Yes" cancelText="No">
      <Button icon={<DeleteOutlined />} danger type="primary">
        Delete
      </Button>
    </Popconfirm>
  );
}
