import React, { useCallback } from 'react';

import { Form, FormInstance, Input } from 'antd';

import useLocationQuery from 'hooks/use-location-query';

const { Item } = Form;

const rules = [
  ({ getFieldValue }: any) => ({
    async validator(field: any, tokenId: string) {
      const asset = getFieldValue('asset');

      if (asset !== 'external' && !tokenId) {
        throw new Error('Token identifier is required');
      }
    },
  }),
];

export default function TokenID({ form }: { form: FormInstance }) {
  const location = useLocationQuery();
  const asset = form.getFieldValue('asset');

  const onChange = useCallback(() => {
    form.setFieldsValue({
      name: '',
      description: '',
      creative: '',
    });
  }, []);

  return (
    <Item
      label="NFT Token ID"
      required
      name="tokenId"
      extra="Token ID"
      rules={rules}
      hidden={asset !== 'custom'}
      normalize={(s) => s.trim()}>
      <Input readOnly={!!location.get('tokenId')} onChange={onChange} />
    </Item>
  );
}
