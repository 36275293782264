import { useCallback } from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { clone, pull } from 'lodash';
import { useRecoilState } from 'recoil';

import { editableKeysAtom } from '../state';

export default function SaveRow({ row }: any) {
  const [editableKeys, setEditableKeys] = useRecoilState<any[]>(editableKeysAtom);

  const onDelete = useCallback(() => {
    setEditableKeys(pull(clone(editableKeys), row.id));
  }, [editableKeys]);

  return editableKeys.indexOf(row.id) !== -1 ? (
    <Button icon={<SaveOutlined />} size="small" shape="circle" type="primary" onClick={onDelete}></Button>
  ) : null;
}
