import { useEffect } from 'react';

import { parseInt } from 'lodash';
import { Switch, useLocation } from 'react-router-dom';
import { UseWalletProvider } from 'use-wallet';

import getCompiledRoutes from 'config/routes';
import { AnalyticService } from 'lib/analytic';
import env from 'lib/env';
import connectors from 'lib/wallet-connect/connectors';
import { useIframeResize } from 'use-iframe-resize';
import { useInitApp } from 'use-init-app';

import { GlobalStyles } from './GlobalStyles';

export function AppContent() {
  useIframeResize();

  const location = useLocation();
  const isReady = useInitApp();

  useEffect(() => {
    AnalyticService.pageView();
  }, [location]);

  // TODO: RENDER LOADER HERE
  if (!isReady) return null;

  const chainId = parseInt(env.ETHERNET_CHAIN_ID);

  console.log(chainId);

  return (
    <UseWalletProvider
      autoConnect
      connectors={{
        walletconnect: {
          chainId: chainId,
          rpc: connectors,
        },
      }}>
      <GlobalStyles />

      <Switch>
        {getCompiledRoutes()}
      </Switch>
    </UseWalletProvider>
  );
}
