import React, { useCallback, useState } from 'react';

import { Button, Card, Col, Form, Row, Skeleton, Spin, Typography } from 'antd';
import { get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import Controller from 'contracts/controller';
import useContract from 'hooks/use-contract';
import Layout from 'layout/AdminLayout';
import env from 'lib/env';
import { useRelayMethod } from 'utils/contracts/relay-method';

import OrganizationForm from '../components/OrganizationForm';

import { organizationSelector } from './Organization.state';

const { useForm } = Form;

export default function Organization() {
  const [form] = useForm();
  const [disabled, setDisabled] = useState(false);
  const params = useParams<any>();
  const wallet = useWallet();
  const history = useHistory();
  const relayMethod = useRelayMethod();

  const goBack = useCallback(() => {
    history.push('/organizations');
  }, [history]);

  const organizationId = get(params, 'id');

  const controller = useContract(Controller, {
    address: env.MONEGRAPH_CONTROLLER,
    provider: new web3.providers.HttpProvider(env.MONEGRAPH_RELAY_PROVIDER),
  });

  const { contents, state } = useRecoilValueLoadable<any>(organizationSelector(organizationId));
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(
    async (values: any) => {
      setLoading(true);

      try {
        if (!organizationId) {
          await relayMethod('addOrganization((string,uint256,bool),(string,address,string,bool))', [
            {
              name: values.name,
              seats: values.seats,
              active: true,
            },
            {
              name: values.adminName,
              wallet: values.adminWallet,
              metadata: '',
              admin: true,
            },
          ]);
        } else {
          await relayMethod('modifyOrganization(uint256,(string,uint256,bool))', [
            organizationId,
            {
              seats: values.seats,
              name: values.name,
              metadata: '',
              active: true,
            },
          ]);
        }

        form.resetFields();

        setLoading(false);

        goBack();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [organizationId, form, goBack, controller, wallet.account]
  );

  const onChangeFields = useCallback(() => {
    setDisabled(form.getFieldsError().some((item) => item.errors.length > 0));
  }, [form]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Typography.Title>{contents.name || 'New Organization'}</Typography.Title>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center" className="p-2">
              <Col xs={24} md={18} lg={12}>
                <Spin spinning={loading}>
                  {state === 'loading' ? (
                    <>
                      <Skeleton active paragraph={false} />
                      <Skeleton active paragraph={false} />
                    </>
                  ) : (
                    <OrganizationForm organizationId={organizationId} initialValues={contents} form={form} />
                  )}
                  <Form form={form} onFieldsChange={onChangeFields} onFinish={onFinish}>
                    <Form.Item>
                      <Button type="primary" className="w-100 btn-primary mt-3" htmlType="submit" disabled={disabled}>
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
