import React, { useCallback, useMemo, useState } from 'react';

import { message, Col, Form, Row, Spin, Typography, Card, Button } from 'antd';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import MonegraphERC721 from 'contracts/monegraph-erc721';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import Layout from 'layout/AdminLayout';

import MintersInput, { transform } from './components/Minters';

const ADMIN_ROLE = '0x00';

export default function Minters() {
  const wallet = useWallet();
  const params = useParams<any>();
  const executeMethod = useExecuteMethod();

  const [submitting, setSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [form] = Form.useForm();

  const contract = useContract(MonegraphERC721, { address: params.contract || '' });

  const contractAddress = get(contract, 'address');

  useMemo(async () => {
    if (wallet.account && contractAddress) {
      const access = await contract.hasRole(ADMIN_ROLE, wallet.account);

      if (!access) {
        message.error(`${wallet.account} does not have admin privledges`);
      }
    }
  }, [wallet.account, contractAddress]);

  const onFinish = useCallback(
    async (values) => {
      try {
        setSubmitting(true);

        const minters = transform(values.minters);

        if (minters.length) {
          if (minters.length === 1) {
            await executeMethod(contract.grantRole, [web3.utils.soliditySha3('MINTER_ROLE'), minters[0]], {
              from: wallet.account,
            });
          } else if (minters.length > 1) {
            await executeMethod(contract.batchGrantMinters, [minters], {
              from: wallet.account,
            });
          }

          form.resetFields();

          message.success(`Submission processed`);
        }
      } catch (e) {
        console.error(e);
        message.error(`There was an error approving the addresses`);
      } finally {
        setSubmitting(false);
      }
    },
    [contract, form, wallet.account]
  );

  const onChangeFields = useCallback(() => {
    setDisabled(form.getFieldsError().some((item) => item.errors.length > 0));
  }, [form]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Typography.Title>Minters</Typography.Title>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center" className="p-2">
              <Col xs={24} md={16} lg={12}>
                <Spin spinning={submitting} delay={500}>
                  <Form
                    id="minterForm"
                    labelCol={{}}
                    wrapperCol={{ span: 22 }}
                    layout="vertical"
                    onFieldsChange={onChangeFields}
                    onFinish={onFinish}
                    form={form}
                    size="large">
                    <MintersInput />

                    <Form.Item label="">
                      <Button type="primary" className="w-100 btn-primary" htmlType="submit" disabled={disabled}>
                        Submit Changes
                      </Button>
                    </Form.Item>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
