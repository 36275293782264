import { map } from 'lodash';
import moment from 'moment';

import { transformAuction as baseTransform } from 'lib/transform';

import { AuctionsOptions, AuctionOptions, AuctionStatus } from './auction.types';

export function transformAddress(address: string) {
  return address.length ? `${address.slice(0, 4)}....${address.slice(-4)}` : '';
}

export function transformAuctions(auctions: any[], options: AuctionsOptions) {
  return map(auctions, transformAuction);
}

function auctionHardCodes(auction: any) {
  if (auction.id === '0xe5cbfb39d7bc74d20b2273cfa9efd230e30edbb2') {
    auction.seller.avatar = auction.nft.creative;
    auction.seller.name = 'Monegraph';
    auction.seller.description =
      'We provide the software and understanding to make your digital assets business successful and build the online communities to sustain growth in the years to come';
  } else if (
    auction.seller.id === '0xda8b13c8743a57e506e805649571fdc53cd687cf' &&
    auction.nft.artist === 'Honor Fraser'
  ) {
    auction.nft.artist = 'Nancy Baker Cahill';
  }

  if (auction.id === '0x151b456effe3e557268080d6f8a57e428b5b8254') {
    auction.nft.name = 'Slipstream 006';
    auction.nft.poster = 'https://mint.monegraph.com/nbc/slipstream006_atomized.resized.png';
    auction.nft.creative = 'https://mint.monegraph.com/nbc/Slipstream006_sound.mp4';
  } else if (auction.id === '0xce553a37e6f7ac736aab6963b5f151722f3957c3') {
    auction.nft.poster = 'https://mint.monegraph.com/nbc/slipstream004_atomized.resized.png';
    auction.nft.creative = 'https://mint.monegraph.com/nbc/Slipstream004_sound.mp4';
  } else if (auction.id === '0xb53444dcc54b6019e8ac4d309873ee94737f5482') {
    auction.nft.poster = 'https://mint.monegraph.com/nbc/slipstream003_atomized.resized.png';
    auction.nft.creative = 'https://mint.monegraph.com/nbc/Slipstream003_sound.mp4';
  } else if (auction.id === '0x1c1ba19ea4f6e53da040520e7c13b5e3f962ee1f') {
    auction.nft.description = `Slipstream 009
    Nancy Baker Cahill
    2021
    Born-Digital Animated Video with Sound (Variable)
    2048 x 2048 30FPS 
    Unique 
    Edition 1 of 1
    1 minute and 8 seconds\n
    ${auction.nft.description}
    `;

    auction.nft.poster = 'https://mint.monegraph.com/nbc/slipstream009_detail02.png';

    auction.nft.creative =
      'https://monegraph.mypinata.cloud/ipfs/QmTN35znbtUD634swq5qpa9aAWfgbrYokkcYSRxqwYYHVk/NBakerCahill-Slipstream-009-2021_monegraph.mp4';
  } else if (auction.id === '0x6faf55264ac3326c21b5c24035d78f744d2dc08e') {
    auction.nft.description = `Self Portrait 5
    LaJun\u00E9 McMillian
    2021
    Video, with NFT .mp4 file
    Edition 1 of 1
    1 minute loop\n
    ${auction.nft.description}
    `;

    auction.nft.poster = 'https://mint.monegraph.com/nbc/self-portrait.jpg';

    auction.nft.creative =
      'https://monegraph.mypinata.cloud/ipfs/QmabkG1cAhgPL7VY2wvErpe24cSAS5bPUxnsyhmyHy1mB5/LMcMillian-SelfPortrait5-2021-Web.mp4';
  } else if (auction.id === '0x9c21792dccfba88e5a3125847ac62160f65fc680') {
    auction.nft.creative = 'https://decrypt.s3.us-east-2.amazonaws.com/Christ+Xmas_Red.jpg';
  } else if (auction.id === '0x398017b2ad0386b2a20c1b6c491b27fcccd443e1') {
    auction.nft.creative = 'https://decrypt.s3.us-east-2.amazonaws.com/Christ+Xmas_Multi.jpg';
  } else if (auction.id === '0x64613ad6c24b8931a3f1eca2482ba2cb22b300a5') {
    auction.nft.creative = 'https://decrypt.s3.us-east-2.amazonaws.com/Christ+Xmas_green.jpg';
  } else if (auction.id === '0x156f7ce6e0a9c25b5f0c394a4fc478e1c148e84b') {
    auction.nft.creative = 'https://decrypt.s3.us-east-2.amazonaws.com/Christ+Xmas_Blue.jpg';
  } else if (auction.id === '0x9dc66f66515f969f5c5cbefe12525e9a8b8ee2f5') {
    auction.nft.creative = 'https://decrypt.s3.us-east-2.amazonaws.com/Christ+Xmas_Pink.jpg';
  }

  if (auction.id === '0xb53444dcc54b6019e8ac4d309873ee94737f5482') {
    auction.nft.description = `‘Slipstream 003' (2021)
Nancy Baker Cahill
Born-Digital Animated Video with Sound (Variable)
2048 x 2048
00:00:30, 30FPS
Unique

Slipstream fiction, trafficking in "the familiar strange or the strange familiar" via “epistemological and ontological questionings about reality,” [1] provides the conceptual framework for these mutated artworks. Evolutionarily speaking, each of us perceives our own distinct individual realities and are indelibly influenced by those shared with us. While invented truths are often deployed broadly for the purposes of oppression, disinformation campaigns and mass manipulation, this series embraces the ever-changing concept of truth itself. Each hybridized artwork in the series toys with and dwells in the murky territories of consciousness. Additive and subtractive in their construction, these transmutations embody a self-organizing logic.

'Slipstream' artworks begin as graphite drawings on paper, launching a long odyssey of production. The drawings are torn into pieces and then reconfigured in bespoke sculptural configurations. Echoing recombinant DNA, each sculptural installation represents an iterative version of the one before. Documented as 3D objects, they are altered, lit and animated using CG software. Once composited, they exist as discrete looped videos (aka, this NFT). A final step returns them to paper --forever altered-- as archival prints, in triptychs, which isolate sequential dramatic moments in their respective animations. These artworks gesture towards the organic, as simulated fictions of botanical forms. Each offers a familiar referent with no natural analogue.

1] Kelly, James Patrick, Kessel, John (2006). Feeling Very Strange, xi,xiii. Tachyon Publications. ISBN 978-1-892391-35-x`;
  }

  if (
    [
      '0xddda6ecc457beeb96890e4fae33baee8ee8afd26',
      '0xd1c4269f3342423025dc494c3c03b7788fa048ee',
      '0xc998ba7a982053e64d08c79f7d4e1872de2e3e92',
      '0x84ebd76680b1f2299ea6b7cfeeabcc561b26f06f',
      '0x122206542f48405dde3505dde70d76683e28da36',
      '0x0d0e394bf2c00bcede20afd1e38404bf58ac932f',
    ].indexOf(auction.id) !== -1
  ) {
    auction.hasEnded = true;
    auction.dateEnding = 0;
    auction.dateFinalized = new Date().getTime();
    auction.status = AuctionStatus.FINALIZED;
  }

  return auction;
}

export function transformAuction(auction: any, { ethereumValue, blockTimestamp }: AuctionOptions) {
  if (auction?.id) {
    baseTransform(auction, {
      ethereumValue,
      blockTimestamp,
      transform: auctionHardCodes,
    });

    if (parseInt(auction.dateStarted) > parseInt(moment().format('X'))) {
      auction.status = AuctionStatus.UPCOMING;
    }
  }

  return auction;
}
