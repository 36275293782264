import React, { useMemo } from 'react';

import { Form, DatePicker } from 'antd';
import { Rule } from 'antd/lib/form';
import { Moment } from 'moment';

const { Item } = Form;

interface EndTimeProps {
  name: string | string[];
  minimumDateField?: string | string[];
}

export default function EndTime({ name, minimumDateField }: EndTimeProps) {
  const rules = useMemo<Rule[]>(
    () => [
      (form) => ({
        async validator(_field, value: Moment) {
          if (minimumDateField && value) {
            const minDate = form.getFieldValue(minimumDateField) as Moment;

            if (minDate?.isAfter(value)) {
              throw Error('Closing Date should be after the start date');
            }
          }
        },
      }),
    ],
    [minimumDateField]
  );

  return (
    <Item
      name={name}
      label="Closing Date & Time"
      dependencies={minimumDateField ? [minimumDateField] : []}
      rules={rules}
      extra="Date and time when the auction should close">
      <DatePicker className="w-100" showTime />
    </Item>
  );
}
