import React, { useCallback, useMemo } from 'react';

import { Form, Select } from 'antd';
import { map } from 'lodash';
import { useSetRecoilState } from 'recoil';

import { contractAtom } from 'hooks/use-collection';

import contracts from './contract/definitions';

const { Option } = Select;
const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please select the contract template',
  },
];

export default function Contract() {
  const setContract = useSetRecoilState(contractAtom);

  const options = useMemo(() => {
    return map(contracts, (contract, key) => {
      return (
        <Option key={key} value={key}>
          {contract.name}
        </Option>
      );
    });
  }, []);

  const onChange = useCallback(
    (contract: string) => {
      setContract(contract);
    },
    [setContract]
  );

  return (
    <Item
      name="contract"
      label="Contract Template"
      rules={rules}
      initialValue=""
      extra="Template selector for plain language contract between you and the collector">
      <Select onChange={onChange}>
        <Option value="">Select Contract</Option>
        {options}
      </Select>
    </Item>
  );
}
