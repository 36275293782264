import { size, transform, each, map } from 'lodash';

import useMonitorQuery from 'hooks/use-monitor-query';

import { FETCH_COLLECTION_TREE } from '../Create.queries';

export function useCollectionTree(accountId: string | null) {
  return useMonitorQuery<any>(
    {
      query: FETCH_COLLECTION_TREE,
      variables: {
        accountId: accountId?.toLowerCase(),
      },
      defaultValidator(context: any) {
        return !!context.accountId;
      },
      async transform({ data }) {
        const tokens = data.nftholders;
        const output: any = [
          {
            title: 'Mainnet Contract',
            value: 'custom',
          },
          {
            title: 'External Asset',
            value: 'external',
          },
        ];

        if (size(tokens)) {
          const collections = transform(
            tokens,
            (result: any, token: any) => {
              if (!result[token.nft.collection.id]) {
                result[token.nft.collection.id] = {
                  ...token.nft.collection,
                  tokens: [],
                };
              }

              result[token.nft.collection.id].tokens.push({
                id: `${token.nft.collection.address}-${token.nft.tokenId}`,
                name: token.nft.name,
              });
            },
            {}
          );

          each<any>(collections, (collection) => {
            output.push({
              title: collection.name,
              value: collection.id,
              selectable: false,
              children: map<any>(collection.tokens, (token: any) => {
                return {
                  title: token.name,
                  value: token.id,
                };
              }),
            });
          });
        }

        return output;
      },
    },
    [accountId]
  );
}
