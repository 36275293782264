/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';

import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
/* eslint-disable-next-line */
import { Button, Col, message, Modal, Row, Table, Tooltip } from 'antd';

import './IconsColor.css';
import { ColumnsType } from 'antd/lib/table';
import { Link, useHistory } from 'react-router-dom';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import { ERC1155, ERC721 } from 'constants/collection';
import monegraphErc1155V2 from 'contracts/monegraph-erc1155-v2';
import monegraphErc721V2 from 'contracts/monegraph-erc721-v2';
import monegraphERC721V3 from 'contracts/monegraph-erc721-v3';
import { getContract } from 'hooks/use-contract';
import env from 'lib/env';

interface PublicMintTableProps {
  collection: any;
}

export const PublicMintTable = ({ collection }: PublicMintTableProps) => {
  const wallet = useWallet();
  const history = useHistory();
  const [hasPermission, setHasPermission] = useState(false);

  const columns = useMemo<ColumnsType<any>>(
    () => [
      {
        title: 'Mint Id',
        dataIndex: 'id',
        // eslint-disable-next-line react/no-multi-comp
        render(mintId: string) {
          return (
            <Tooltip title="View">
              <Link to={`public-mint/${mintId}`}>
                <Button type="link">{mintId}</Button>
              </Link>
            </Tooltip>
          );
        },
      },

      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Whitelisted',
        dataIndex: 'allowlist',
      },

      {
        title: 'Public',
        dataIndex: ['general', 'enabled'],
        // eslint-disable-next-line react/no-multi-comp
        render(isPublic: boolean) {
          return isPublic ? <CheckCircleFilled className="greenIcon" /> : <CloseCircleFilled className="redIcon" />;
        },
      },

      {
        title: 'Tokens',
        dataIndex: ['tokens', 'length'],
      },

      {
        title: 'Actions',
        dataIndex: 'id',
        // eslint-disable-next-line react/no-multi-comp
        render(id: any, values: any) {
          return (
            <>
              <Tooltip title="Edit">
                <Link to={`public-mint/${id}`}>
                  <Button type="primary" style={{ marginLeft: 5 }} shape="circle" icon={<EditOutlined />} />
                </Link>
              </Tooltip>

              <Tooltip title="Edit">
                <Button
                  onClick={() => onDelete(id)}
                  type="primary"
                  danger
                  style={{ marginLeft: 5 }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </>
          );
        },
      },
    ],
    []
  );

  const contract = useMemo(() => {
    if (collection?.type === ERC1155 && collection?.version === '2') {
      return monegraphErc1155V2;
    }
    if (collection?.type === ERC721 && collection?.version === '2') {
      return monegraphErc721V2;
    }
    if (collection?.type === ERC721 && collection?.version === '3') {
      return monegraphERC721V3;
    }
  }, [collection]);

  useMemo(async () => {
    const contractInstance = await getContract(wallet.ethereum, contract, collection.address);

    setHasPermission(
      await contractInstance.hasRole(web3.utils.soliditySha3('MINTER_ROLE'), env.MONEGRAPH_MINT_AUTHORIZATION_WALLET)
    );
  }, [collection?.address, wallet.account]);

  const onDelete = useCallback(
    (id) => {
      Modal.confirm({
        title: `Delete Public Mint ${id}`,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete this public mint?',
        okText: 'Delete',
        cancelText: 'cancel',
        async onOk() {
          const contractInstance = await getContract(wallet.ethereum, contract, collection.address);

          await contractInstance.updatePublicMint(id, 'delete', {
            from: wallet.account,
          });
        },
      });
    },
    [collection?.address, wallet.account]
  );

  const grantPremission = useCallback(async () => {
    if (hasPermission) {
      history.push('public-mint');
    } else {
      const contractInstance = await getContract(wallet.ethereum, contract, collection.address);

      Modal.confirm({
        title: 'Auction Platform',
        icon: <ExclamationCircleOutlined />,
        content: 'Public mints have not been enabled for this collection, do you want to enable public mints?',
        okText: 'Enable Public Mints',
        cancelText: 'Cancel',
        onOk: async () => {
          try {
            await contractInstance.grantRole(
              web3.utils.soliditySha3('MINTER_ROLE'),
              env.MONEGRAPH_MINT_AUTHORIZATION_WALLET,
              {
                from: wallet.account,
              }
            );

            setHasPermission(true);

            history.push('public-mint');
          } catch (e) {
            message.error('Something went wrong when granting permission');
          }
        },
      });
    }
  }, [hasPermission, collection.address, contract, history, wallet.account, wallet.ethereum]);

  return (
    <Col span={24}>
      <Row justify="end" className="pt-1">
        <Col>
          <Button type="primary" onClick={grantPremission}>
            {hasPermission ? 'New Public Mint' : 'Enable Public Mints'}{' '}
          </Button>
        </Col>
      </Row>
      <Row justify="center" className="pt-3">
        <Col span={24}>
          <Table dataSource={collection?.publicMints || []} columns={columns} loading={false} scroll={{ x: true }} />
        </Col>
      </Row>
    </Col>
  );
};
