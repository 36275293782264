import { DatePicker } from 'antd';
import moment from 'moment';

export default function CustomInput({ value, onChange }: any) {
  return (
    <DatePicker
      className="w-100"
      showTime={{
        minuteStep: 15,
      }}
      format="YYYY-MM-DD HH:mm"
      value={moment.isMoment(value) ? value : undefined}
      onChange={onChange}
      disabled={typeof value === 'boolean'}
    />
  );
}
