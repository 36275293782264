import { Button } from 'antd';

import { UserButtonProps } from './UserButton.types';

export function UserButton({ username, onClick }: UserButtonProps) {
  return (
    <Button type="primary" className="btn-secondary user-button" size="large" onClick={onClick}>
      @{username}
    </Button>
  );
}
