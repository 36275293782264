// Mapping between CSV file columns to form field names
export const csvColumnMap = {
  tokenId: 'Token Id',
  title: 'Title',
  description: 'Description',
  creative: 'Preview',
  artist: 'Artist',
  year: 'Year',
  cost: 'Price',
  royaltyAddress: 'Royalty Address',
  royalty: 'Royalty',
  archive: 'Archive',
  archiveSHA: 'Archive SHA',
  externalUrl: 'External Url',
  animationUrl: 'Animation Url',
  json: 'JSON',
};

export const columnAliases: Record<string, string> = {
  preview: 'creative',
  price: 'cost',
};
