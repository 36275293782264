import React from 'react';

import { Alert } from 'antd';

export default function TransactionError({ error }: any) {
  return error ? (
    <Alert
      message="Transaction Error"
      description="This is an error message about copywriting."
      type="error"
      showIcon
      className="mt-2"
    />
  ) : null;
}
