import { gql } from 'apollo-boost';

export const FETCH_AUCTION = gql`
  query fetchAuction($id: ID, $account: String) @api(name: "ethereum") {
    auctions(first: 1, where: { id: $id }) {
      id
      type
      status
      dateStarted
      dateCreated
      dateFinalized
      dateEnding
      reservePriceInETH
      version
      quantity
      highestBid {
        bidder {
          id
        }
        amount
        history {
          timestamp
        }
      }
      seller {
        id
      }
      history {
        id
        type
        user {
          id
        }
        timestamp
        transactionHash
        index
        bid {
          amount
        }
      }
      bids {
        id
        bidder {
          id
        }
        datePlaced
        history {
          id
          type
          timestamp
          transactionHash
          bid {
            amount
          }
        }
      }
      nft {
        id
        name
        artist
        description
        creative
        quantity
        tokenId
        collection {
          id
        }
        holders(where: { account: $account }) {
          id
        }
      }
    }

    _meta {
      block {
        hash
        number
      }
    }
  }
`;
