import { Menu, MenuItemProps as AntdMenuItemProps } from 'antd';
import { Link } from 'react-router-dom';

interface MenuItemProps extends AntdMenuItemProps {
  label: string;
  path: string;
  permission?: boolean;
}

export default function MenuItem({ label, path, permission, ...props }: MenuItemProps) {
  return permission === undefined || permission ? (
    <Menu.Item key={path} {...props}>
      <Link to={path}>{label}</Link>
    </Menu.Item>
  ) : null;
}
