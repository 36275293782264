import { useEffect } from 'react';

import { cloneDeep } from 'lodash';

import ajv from 'lib/ajv';
import apollo from 'lib/apollo/client';

import { useQueryResultsFromObject } from './use-query.state';
import { QueryOptions } from './use-query.types';

export default function useMonitorQuery<T = any>(
  {
    query,
    variables = {},
    options = {},
    validate = {},
    transform = (response) => response.data,
    validationKey,
    defaultValidator = null,
  }: QueryOptions<T>,
  dependencies: unknown[] = []
): [T, string, boolean] {
  const [result, hash, setResults] = useQueryResultsFromObject<T>({
    query,
    variables,
    validate,
    options,
  });

  dependencies.push(hash);

  useEffect(
    () => {
      let subscription: ZenObservable.Subscription | null = null;
      let validator = validationKey ? ajv.getSchema(validationKey) : defaultValidator;

      if (
        !validator ||
        validator({
          ...variables,
          ...validate,
        })
      ) {
        subscription = apollo
          .watchQuery({
            fetchPolicy: 'network-only',
            pollInterval: 2500,
            ...options,
            variables,
            query,
          })
          .subscribe({
            async next(response) {
              let transformed = await transform(cloneDeep(response));

              setResults(transformed);

              return response;
            },
          });
      }

      return () => {
        subscription?.unsubscribe();
      };
    },

    dependencies
  );

  return [result, hash, !result];
}
