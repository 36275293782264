import { useEffect, useRef } from 'react';

import { Modal, Form, FormInstance } from 'antd';

import { SeatForm } from '../../components/SeatForm';

const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: boolean }) => {
  const prevVisibleRef = useRef<boolean>();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (visible !== prevVisible) {
      form.resetFields();
    }
  }, [visible]);
};

export default function FormModal({ visible, onOk, onCancel, onFinish, initialValues }: any) {
  const [form] = Form.useForm();

  useResetFormOnCloseModal({ form, visible });

  const handleOk = () => {
    form.submit();
  };

  return (
    <Modal visible={visible} title="Seat" onOk={handleOk} onCancel={onCancel} centered>
      <SeatForm form={form} initialValues={initialValues} onFinish={onFinish} />
    </Modal>
  );
}
