import FileType from 'file-type/browser';
import { each, isArray, isPlainObject, isString, omit } from 'lodash';
import mime from 'mime-types';

import { PinataMetadata, pinFileToIpfs, pinJSONToIPFS } from 'lib/ipfs';
import { extractVariableValue, replaceContractVariables } from 'utils/cntractLanguage';

import createMetadata from './metadata';
import { AttributesEntity, Metadata } from './metadata.types';

async function getMimeType(url: string): Promise<string> {
  const type = mime.lookup(url);

  if (!type) {
    const response = await fetch(url.replace(/ipfs:\/\//i, 'https://ipfs.io/ipfs/'));
    if (response.body) {
      const fileType = await FileType.fromStream(response.body);
      return fileType?.mime ? fileType?.mime.split('/')[0] : '';
    }
  } else {
    return type.split('/')[0];
  }

  return type as string;
}

const handleFileInput = async (value: string | File, metadata: PinataMetadata) => {
  if (isString(value) && value.length && value.indexOf('https://monegraph.mypinata.cloud/ipfs/') !== 0) {
    const request = await fetch(value, {
      method: 'GET',
      mode: 'cors',
      cache: 'reload',
    });
    const mimeType = await getMimeType(value);

    if (['audio', 'video', 'image'].indexOf(mimeType) === -1) {
      throw new Error('Invalid or unknown mime type found');
    }

    const url = new URL(value);

    value = new File([await request.blob()], url.pathname.split('/').pop()!);
  }

  if (value instanceof File) {
    const response = await pinFileToIpfs(value as File, { metadata });
    return `https://monegraph.mypinata.cloud/ipfs/${response.IpfsHash}/${value.name}`;
  }

  return value;
};

function addDynamicJSON(metadata: Metadata, json: any): Metadata {
  if (json) {
    if (isString(json)) {
      try {
        json = JSON.parse(json);
      } catch (e) {
        return metadata;
      }
    }

    if (isPlainObject(json)) {
      if (isArray(json.attributes)) {
        metadata.attributes = [...(metadata.attributes as AttributesEntity[]), ...json.attributes];
      }

      if (isArray(json.properties)) {
        metadata.properties = {
          ...metadata.properties,
          ...json.properties,
        };
      }

      each(omit(json, ['properties', 'attributes']), (value, key) => {
        if (!metadata[key as keyof Metadata]) {
          metadata[key as keyof Metadata] = value;
        }
      });

      metadata.json = json;
    }
  }

  return metadata;
}

export async function pinNftToIPFS(nft: any, wallet: string) {
  const uploadMetadata = { name: nft.title, keyvalues: { wallet: wallet, type: 'mint' } };

  const [creative, archive, animationUrl] = await Promise.all([
    handleFileInput(nft.creative, uploadMetadata),
    handleFileInput(nft.archive, uploadMetadata),
    handleFileInput(nft.animationUrl, uploadMetadata),
  ]);

  const values = { ...nft, creative, archive, animationUrl };
  values.contractVariables = {
    ...extractVariableValue(values),
    $TOKEN: 'eth',
    $LICENSE: 'CA.05.19.21., Patent No. US 10,380,702',
  };

  values.language = replaceContractVariables(values);

  const languageHash = await pinJSONToIPFS(
    {
      language: values.language,
    },
    {
      pinataMetadata: {
        name: `${wallet}.contract.json`,
      },
    }
  );

  values.languageIPFS = `https://ipfs.io/ipfs/${languageHash}`;

  const metadata = addDynamicJSON(createMetadata(values), values.json);

  const hash = await pinJSONToIPFS(metadata as any, {
    pinataMetadata: { name: `${wallet}.metadata.json` },
  });

  return { values, hash };
}
