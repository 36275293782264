import { stringify } from 'csv/dist/esm/sync';

import { csvColumnMap } from './csv-columns';

const template = stringify(
  [
    {
      [csvColumnMap.tokenId]: '',
      [csvColumnMap.title]: '',
      [csvColumnMap.description]: '',
      [csvColumnMap.creative]: 'Image/View preview url',
      [csvColumnMap.artist]: '',
      [csvColumnMap.year]: 2020,
      [csvColumnMap.cost]: 1.0,
      [csvColumnMap.royaltyAddress]: '',
      [csvColumnMap.royalty]: 5,
      [csvColumnMap.archive]: 'URL to higher resolution media',
      [csvColumnMap.archiveSHA]: '',
      [csvColumnMap.externalUrl]: '',
      [csvColumnMap.animationUrl]: '',
    },
  ],
  {
    header: true,
  }
);

let csvContent = 'data:text/csv;charset=utf-8,' + template;
export const csvTemplate = encodeURI(csvContent);
