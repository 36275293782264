export interface AuctionsOptions {
  ethereumValue: number;
  blockTimestamp: number;
}

export type AuctionOptions = AuctionsOptions;

export enum AuctionStatus {
  UPCOMING = 'Upcoming',
  OPEN = 'Open',
  ENDED = 'Ended',
  CANCELED = 'Canceled',
  FINALIZED = 'Finalized',
}

export enum AuctionType {
  BUYNOW = 'BuyNow',
  TRADITIONAL = 'Traditional',
}
