import React from 'react';

import { Form } from 'antd';

import FileInput from 'components/fileinput/FileInput';

import hashURLTransform from './transforms/hash-url';

const { Item } = Form;

export default function AnimationUrl({ form }: any) {
  const rules = [
    {
      transform: hashURLTransform,
      async validator(_: any, value: string | File) {
        if (typeof value == 'string' && value?.length) {
          const response = await fetch(value, {
            method: 'GET',
            mode: 'cors',
            cache: 'reload',
          });

          if (!response.ok) {
            throw new Error(`The url "${value}" did not return a success status code`);
          }
        }
      },
    },
  ];

  return (
    <Item
      label="Animation Url"
      rules={rules}
      name="animationUrl"
      validateTrigger="onBlur"
      extra="A URL to a multi-media attachment for the item. The file extensions GLTF, GLB, WEBM, MP4, M4V, OGV, and OGG are supported, along with the audio-only extensions MP3, WAV, and OGA. Animation_url also supports HTML pages, allowing you to build rich experiences using JavaScript canvas, WebGL, and more. Access to browser extensions is not supported">
      <FileInput accept="image/*,video/*,audio/*" />
    </Item>
  );
}
