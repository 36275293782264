import { Form, Input } from 'antd';
import { each } from 'lodash';

import apolloClient from 'lib/apollo/client';
import { FETCH_TOKEN_ID_WITH_PUBLIC_MINT } from 'queries/collection';

import { TokenIdProps } from './TokenId.types';

const { Item } = Form;

export default function TokenId({ collectionId }: TokenIdProps) {
  const rules = [
    {
      async validator(field: any, tokenId: string | null) {
        if (tokenId) {
          const { data } = await apolloClient.query({
            query: FETCH_TOKEN_ID_WITH_PUBLIC_MINT,
            variables: {
              collectionId,
              tokenId,
            },
          });

          if (data.collection.tokens.length > 0) {
            throw new Error('The provided Token Id already exists, please try another one');
          }

          each(data.collection.publicMints, (publicMint) => {
            if (publicMint.tokens.length > 0) {
              throw new Error(
                `The provided Token Id already exists in public mint "${publicMint.name}", please try another one or remove from the public mint`
              );
            }
          });
        }
      },
    },
  ];

  return (
    <Item
      name="tokenId"
      label="Token Identifier"
      rules={rules}
      validateTrigger="onBlur"
      extra="A token identifier will be automatically generated, if you wish to define a token identifier enter it above">
      <Input placeholder="Auto generate token identifier" />
    </Item>
  );
}
