import { FormInstance } from 'antd';

export interface DeviceSpecificFormProp {
  form: FormInstance;
}

export interface AuctionFormProps {
  form: FormInstance;
  isMobile?: boolean;
  nextStep?: () => void;
}

export const AUCTIONTYPES = {
  BUYNOW: '0',
  TRADITIONAL: '1',
};
