import React, { useMemo, useState } from 'react';

import { Empty, Layout, Row } from 'antd';
import { cloneDeep, get, map } from 'lodash';
import moment from 'moment';
import { useRecoilValueLoadable } from 'recoil';

import useRecoilEffect from 'hooks/use-recoil-effect';
import PageFooter from 'layout/Footer';
import PageHeader from 'layout/Header';
import apollo from 'lib/apollo/client';
import { transformAuction } from 'transforms/auction';

import AuctionGrid from './components/AuctionGrid';
import FeaturedAuction from './components/FeaturedAuction';
import { FEATURED_AUCTION } from './queries';
import { priceInEthereum, auctionsSelector } from './state';

const { Content } = Layout;

export default function HomePage() {
  const [page, setPage] = useState<any>({
    loading: true,
    auction: void 0,
  });
  const { contents: auctions, state: auctionsValueState } = useRecoilValueLoadable<any>(auctionsSelector);
  const { contents: ethereumValue, state: ethereumValueState } = useRecoilValueLoadable<any>(priceInEthereum);

  const { loading, auction } = page;

  useRecoilEffect(
    ({ set }) => () => {
      const subscription = apollo
        .watchQuery({
          query: FEATURED_AUCTION,
          fetchPolicy: 'cache-and-network',
          pollInterval: 5000,
        })
        .subscribe({
          next: function ({ data }: any) {
            const nextAuction = transformAuction(cloneDeep(get(data, 'auctions[0]')), {
              ethereumValue: ethereumValue,
              blockTimestamp: parseInt(moment().format('X')),
            });

            if (
              ethereumValueState === 'hasValue' &&
              nextAuction &&
              (!auction ||
                auction.id !== nextAuction.id ||
                auction.version !== nextAuction.version ||
                nextAuction.currentBidInUSD !== auction.currentBidInUSD)
            ) {
              setPage({
                loading: false,
                auction: nextAuction,
              });
            } else if (!nextAuction) {
              setPage({
                loading: false,
                auction: void 0,
              });
            }
          },
          error: (e: any) => console.error(e),
        });

      return subscription;
    },
    (subscription: ZenObservable.Subscription) => {
      return subscription.unsubscribe();
    },
    [auction, ethereumValueState]
  );

  const components = useMemo(() => {
    let response = null;

    if (!loading) {
      response = auction ? (
        <>
          <FeaturedAuction auction={auction} />
          <AuctionGrid auctions={auctions} />
        </>
      ) : (
        <Row justify="center" className="py-3">
          <Empty description="There currently does not seem to be any featured auctions" />
        </Row>
      );
    }

    return response;
  }, [loading, auction?.id, auctionsValueState, ...map(auctions, 'id')]);

  // @ts-ignore
  return (
    <Layout>
      <PageHeader />
      <Content>{components}</Content>
      <PageFooter />
    </Layout>
  );
}
