

import { Card, Col, Grid, Row, Typography, Form } from 'antd';

import Layout from 'layout/AdminLayout';

import FormMobile from './components/FormMobile';
import FormPC from './components/FormPc';

const { useBreakpoint } = Grid;

export default function Minting() {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints.md === false;

  const [form] = Form.useForm();

  return (
    <Layout>
      <Row className="mb-3">
        <Col xs={24}>
          <Card>
            <Typography.Title>Mint NFT</Typography.Title>
          </Card>
        </Col>
      </Row>
      {isMobile ? <FormMobile form={form} /> : <FormPC form={form} />}
    </Layout>
  );
}
