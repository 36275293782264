/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';

import { get } from 'lodash';
import { useRecoilValueLoadable } from 'recoil';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useWallet } from 'use-wallet';

import { login } from 'state/accounts';

const permissionsAtom = atom<{ loading: Boolean; permissions: any; account: any }>({
  key: 'permissions',
  default: {
    loading: false,
    account: null,
    permissions: null,
  },
});

export default function usePermissions() {
  const wallet = useWallet();
  const { contents: account } = useRecoilValueLoadable(login(wallet.account));
  const [permissions, setPermissions] = useRecoilState(permissionsAtom);

  useMemo(async () => {
    if (!permissions.permissions || !wallet.account) {
      setPermissions({
        loading: !!wallet.account,
        account: null,
        permissions: null,
      });
    }

    if (wallet.account) {
      setPermissions({
        loading: false,
        account,
        permissions: {
          monegraph: {
            minter: true,
            admin: account?.id === '0xf82d31541fe4f96dfee2a2c306f70086d91d67c9',
          },
          minting: {
            creator: get(account, 'permissions.createCollections', false),
          },
          auctions: {
            creator: get(account, 'permissions.createAuctions', false),
          },
          organization: {
            admin: get(account, 'permissions.organizationAdmin', false),
          },
        },
      });
    }
  }, [account, wallet.account]);

  return permissions;
}
