import { useMemo } from 'react';

import { Tabs } from 'antd';
import { useHistory, useParams, useRouteMatch, generatePath } from 'react-router-dom';

const { TabPane } = Tabs;

interface TabNavigations {
  tabName: string;
  tabPath: string;
  tabBody: React.ReactNode;
}

interface TabsProps {
  basePath: string;
  routeEnding: string;
  tabs: TabNavigations[];
}

export const TabNavigation = ({ routeEnding, tabs, basePath }: TabsProps) => {
  const history = useHistory();
  const params = useParams<{ tab: string }>();
  const match = useRouteMatch();
  const currentTab = useMemo(() => {
    const current = tabs.find((tab) => tab.tabPath === params['tab']);

    return current ?? tabs[0];
  }, [params, tabs]);

  return (
    <Tabs
      style={{ width: '100%' }}
      type="card"
      activeKey={currentTab.tabPath}
      onChange={(key) => {
        history.push(generatePath(basePath, { ...match.params, tab: key }));
      }}>
      {tabs.map((element) => {
        return (
          <TabPane tab={element.tabName} key={element.tabPath}>
            {element.tabBody}
          </TabPane>
        );
      })}
    </Tabs>
  );
};
