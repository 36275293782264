import { Card, Col, Row } from 'antd';

import MintForm from './Form';
import styles from './FormPc.module.css';
import Preview from './Preview';

export default function FormPC({ form }: any) {
  return (
    <Row justify="center">
      <Col span={16}>
        <Card>
          <Row justify="center" className="p-2">
            <Col xs={24} lg={18}>
              <MintForm  form={form} isMobile={false} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={8} >
        <Card className={styles.stickyReview} >
          <Preview  form={form}/>
        </Card>
      </Col>
    </Row>
  );
}
