import React, { ChangeEvent, useCallback, useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';
import { useSetRecoilState } from 'recoil';

import { useQueryResultsFromHash } from 'hooks/use-query.state';

import { previewState } from '../../state/preview';

const { Item } = Form;
const { TextArea } = Input;

export default function Description({ hash, form }: { hash: string; form: FormInstance }) {
  const collection = useQueryResultsFromHash(hash);
  const updatePreview = useSetRecoilState(previewState);
  const description = collection?.description;

  const onChange = useCallback((event: ChangeEvent<any>) => {
    updatePreview((preview) => {
      return { ...preview, description: event.target.value };
    });
  }, []);

  useEffect(() => {
    if (description) {
      form.setFieldsValue({
        description,
      });

      updatePreview((preview) => {
        return {
          ...preview,
          description,
        };
      });
    }
  }, [description]);

  return (
    <Item label="Description" required name="description" extra="Description extracted from the nft token">
      <TextArea readOnly={!!description} autoSize onChange={onChange} />
    </Item>
  );
}
