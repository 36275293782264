import proEnUs from '@ant-design/pro-provider/lib/locale/en_US';
import { createIntl } from '@ant-design/pro-table';
import enUsImport from 'antd/lib/locale/en_US';

const enLocale = {
  tableForm: {
    inputPlaceholder: ' ',
    selectPlaceholder: ' ',
  },
};

export const enUs = enUsImport;

export const enUSIntl = createIntl('en_US', { ...enUsImport, ...proEnUs, ...enLocale });
