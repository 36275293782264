import { useCallback, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import useLocationQuery from 'hooks/use-location-query';

import { drafts } from '../../state/auction';

export default function SaveProgress({ form }: any) {
  const locationQuery = useLocationQuery();
  const [saved, setDrafts] = useRecoilState<any>(drafts);
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const draft = locationQuery.get('draft');

  const [modalForm] = Form.useForm();

  const saveProgress = useCallback(() => {
    const name = draft || modalForm.getFieldValue('name');

    if (name) {
      if (saved[name]) {
        modalForm.setFields([{ name: 'name', value: '', errors: [`Draft name already exists`] }]);
      } else {
        const values = form.getFieldsValue();
        setVisible(false);

        setDrafts({
          ...saved,
          [name]: values,
        });

        history.replace(history.location.pathname + `?draft=${name}`);
      }
    }
  }, [form, modalForm]);

  const checkSave = useCallback(() => {
    if (draft) {
      Modal.confirm({
        title: 'Delete Draft?',
        icon: <ExclamationCircleOutlined />,
        content: `Are you sure you want to delete the draft named "${draft}"?`,
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk() {
          setDrafts(omit(saved, draft));
          history.replace(history.location.pathname);
        },
      });
    } else {
      setVisible(true);
    }
  }, [draft]);

  return (
    <>
      <Form.Item label="">
        <Button type="primary" className="w-100 btn-secondary" danger={!!draft} onClick={checkSave}>
          {draft ? 'Delete Draft' : 'Save Progress'}
        </Button>
      </Form.Item>

      <Modal visible={visible} title="Save progress?" onCancel={() => setVisible(false)} onOk={saveProgress}>
        <Form form={modalForm}>
          <Form.Item label="Draft Name" name="name" extra="The name of this draft">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
