import { useCallback, useEffect, useMemo } from 'react';

import { Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { useWallet } from 'use-wallet';

import MetaMaskLogo from '../../assets/metamask.svg';
import WalletConnectLogo from '../../assets/walletconnect.svg';

import styles from './Wallet.module.css';

const { Meta } = Card;

const Wallet = () => {
  const wallet = useWallet();
  const history = useHistory();

  const active = useMemo(() => wallet.status === 'connected', [wallet.status]);
  useEffect(() => {
    if (active) {
      if (wallet.connector === 'walletconnect') {
        wallet.ethereum.on = null;
      }

      history.goBack();
    }
  }, [active, wallet.ethereum, wallet.connector]);

  const connectMetamask = useCallback(() => (active ? null : wallet.connect('injected')), [active, wallet]);
  const walletconnect = useCallback(() => (active ? null : wallet.connect('walletconnect')), [active, wallet]);

  const metamask = useMemo(() => {
    // @ts-ignore
    return window.web3 ? (
      <Card
        className={styles.walletCard}
        style={{ width: 300 }}
        cover={<img height="180" alt="example" src={MetaMaskLogo} />}
        actions={[
          <Button type="text" size="large" className={styles.walletButton} onClick={connectMetamask}>
            Connect
          </Button>,
          <Button
            type="text"
            size="large"
            className={styles.walletButton}
            href="https://metamask.io/download.html"
            target="_blank"
            rel="noreferrer">
            Create Wallet
          </Button>,
        ]}>
        <Meta title="MetaMask" description="Connect or Create a wallet with MetaMask" />
      </Card>
    ) : null;
  }, [connectMetamask]);

  return (
    <div className={styles.walletContainer}>
      <div className={styles.walletInnerContainer}>
        {metamask}
        <Card
          className={styles.walletCard}
          cover={<img height="180" alt="example" src={WalletConnectLogo} />}
          actions={[
            <Button type="text" size="large" className={styles.walletButton} onClick={walletconnect}>
              Connect
            </Button>,
            <Button
              type="text"
              size="large"
              className={styles.walletButton}
              href="https://registry.walletconnect.org/"
              target="_blank"
              rel="noreferrer">
              Create Wallet
            </Button>,
          ]}>
          <Meta title="WalletConnect" description="Connect wallet with WalletConnect or Create Wallet" />
        </Card>
      </div>
    </div>
  );
};

export default Wallet;
