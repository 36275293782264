import React from 'react';

import { Row, Col, Typography, Layout } from 'antd';

import styles from './Footer.module.css';

export default function Footer() {
  return (
    <Layout.Footer className={styles.footer}>
      <Row align="middle" justify="center">
        <Col>
          <Typography.Text type="secondary" className="text-center">
            Monegraph Inc {new Date().getFullYear()}
          </Typography.Text>
        </Col>
      </Row>
    </Layout.Footer>
  );
}
