import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, Row, Spin, Typography, Empty } from 'antd';
import { find, get, parseInt } from 'lodash';
import { useParams } from 'react-router-dom';
import { useWallet } from 'use-wallet';

import useRecoilEffect from 'hooks/use-recoil-effect';
import Layout from 'layout/AdminLayout';
import apollo from 'lib/apollo/client';
import { PinataMetadata, pinFileToIpfs, pinJSONToIPFS } from 'lib/ipfs';
import { useRelayMethod } from 'utils/contracts/relay-method';

import { FETCH_ORGANIZATION } from '../create/Organization.queries';

import Seat from './components/Seat';
import SeatFormModal from './components/SeatFormModal';

const handleFileInput = async (value: string | File, metadata: PinataMetadata) => {
  if (!value) return '';

  if (value instanceof File) {
    const response = await pinFileToIpfs(value, { metadata });
    return `https://monegraph.mypinata.cloud/ipfs/${response.IpfsHash}/${value.name}`;
  }

  return value;
};

export default function ManageSeats() {
  const params = useParams<any>();
  const wallet = useWallet();
  const organizationId = get(params, 'id');
  const [visible, setVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState<any>(null);

  const relayMethod = useRelayMethod();

  useRecoilEffect(
    ({ set }) => () => {
      const subscription = apollo
        .watchQuery({
          query: FETCH_ORGANIZATION,
          fetchPolicy: 'cache-and-network',
          pollInterval: 5000,
          variables: {
            id: organizationId,
          },
        })
        .subscribe({
          next: function ({ data }: any) {
            if (data) {
              const organization = get(data, 'organizations[0]', null);

              if (organization) {
                const accounts = organization.accounts.map((account: any) => {
                  return {
                    seatWallet: account.id,
                    seatName: account.name,
                    isAdmin: account.permissions.organizationAdmin,
                    description: account.description,
                    avatar: account.avatar,
                  };
                });
                setOrganization({
                  ...organization,
                  accounts,
                });
              }
            }

            if (loading) {
              setLoading(false);
            }
          },
          error: (e: any) => console.error(e),
        });

      return subscription;
    },
    (subscription: ZenObservable.Subscription) => {
      return subscription.unsubscribe();
    },
    [setOrganization, wallet.account, loading]
  );

  const onEditSeat = (address: string) => {
    setVisible(true);
    setEditingIndex(address);
  };

  const onRemove = useCallback(
    async (address: string) => {
      await relayMethod('removeSeat(address)', [address]);
    },
    [wallet.account, relayMethod]
  );

  const onModalClose = () => {
    setVisible(false);
    setEditingIndex(undefined);
  };

  const onFinish = useCallback(
    async (values: any) => {
      const avatar = await handleFileInput(values.avatar, { name: values.seatName, keyvalues: { type: 'avatar' } });

      const metadataHash = await pinJSONToIPFS(
        {
          bio: values.description ?? '',
          avatar,
        },
        {
          pinataMetadata: {
            name: `${values.seatWallet}.${organizationId}.description.json`,
          },
        }
      );

      const seat = {
        name: values.seatName,
        wallet: values.seatWallet,
        admin: values.isAdmin,
        metadata: metadataHash,
      };

      if (editingIndex) {
        await relayMethod('modifySeat((string,address,string,bool))', [seat]);
      } else {
        await relayMethod('addSeat((string,address,string,bool))', [seat]);
      }

      setVisible(false);
      setEditingIndex(undefined);
    },
    [wallet.account, setVisible, setEditingIndex, editingIndex, organizationId, relayMethod]
  );

  const seats = useMemo(() => get(organization, 'accounts', []), [organization?.accounts]);

  return (
    <>
      <Layout>
        <Row className="mb-3">
          <Col span={24}>
            <Card>
              <Typography.Title>Manage Seats</Typography.Title>
            </Card>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={23}>
            <Card>
              <Row justify="center" className="p-2">
                <Col xs={24} md={18} lg={16}>
                  <Spin spinning={loading}>
                    <List className="py-3" itemLayout="vertical">
                      {seats.length ? (
                        seats.map((seat: any, index: number) => (
                          <Seat
                            key={seat.seatWallet}
                            seat={seat}
                            onEdit={() => onEditSeat(seat.seatWallet)}
                            onRemove={() => onRemove(seat.seatWallet)}
                          />
                        ))
                      ) : (
                        <Empty className="py-3" />
                      )}
                    </List>

                    <Button
                      type="dashed"
                      block
                      icon={<PlusOutlined />}
                      disabled={parseInt(organization?.accounts?.length) + 1 > organization?.seats}
                      onClick={() => setVisible(true)}>
                      Add seat
                    </Button>
                  </Spin>
                  <SeatFormModal
                    visible={visible}
                    onFinish={onFinish}
                    onCancel={onModalClose}
                    initialValues={find(seats, { seatWallet: editingIndex })}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Layout>
    </>
  );
}
