import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Form, message, Spin, Button } from 'antd';
import BN from 'bignumber.js';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import { ERC1155, ERC721 } from 'constants/collection';
import monegraphErc1155 from 'contracts/monegraph-erc1155';
import monegraphErc1155V2 from 'contracts/monegraph-erc1155-v2';
import monegraphERC721 from 'contracts/monegraph-erc721';
import monegraphERC721V2 from 'contracts/monegraph-erc721-v2';
import monegraphERC721V3 from 'contracts/monegraph-erc721-v3';
import { isMintingAtom, useCollection } from 'hooks/use-collection';
import { getContract } from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import { pinFileToIpfs, PinataMetadata, pinJSONToIPFS } from 'lib/ipfs';
import { priceInEthereum } from 'pages/auctions/home/state';
import createMetadata from 'utils/mint/metadata';

import { extractVariableValue, replaceContractVariables } from '../contractLanguage';

import Traits from './Traits';
import AnimationUrl from './mint-form/AnimationUrl';
import Archive from './mint-form/Archive';
import ArchiveSHA from './mint-form/Archive-SHA';
import Artist from './mint-form/Artist';
import Contract from './mint-form/Contract';
import ContractLanguage from './mint-form/ContractLanguage';
import Creative from './mint-form/Creative';
import Description from './mint-form/Description';
import Editions from './mint-form/Editions';
import ExternalUrl from './mint-form/ExternalUrl';
import RecipientWallet from './mint-form/RecipientWallet';
import Royalty from './mint-form/Royalty';
import RoyaltyAddress from './mint-form/RoyaltyAddress';
import Submit from './mint-form/Submit';
import Title from './mint-form/Title';
import TokenId from './mint-form/TokenId';
import Year from './mint-form/Year';

const { List } = Form;

const handleFileInput = async (value: string | File, metadata: PinataMetadata) => {
  if (value instanceof File) {
    const response = await pinFileToIpfs(value, { metadata });
    return `https://monegraph.mypinata.cloud/ipfs/${response.IpfsHash}/${value.name}`;
  }

  return value;
};

function getContext(collection: any, values: any): any {
  let method;
  let payable;
  let args;
  let contract;

  switch (collection.type) {
    case ERC1155:
      method = 'mint(address,uint256,string,bytes,(string,string,string,string,string))';
      payable = true;
      contract = monegraphErc1155;
      args = [
        values.recipientWallet,
        values.quantity,
        `https://monegraph.mypinata.cloud/ipfs/${values.hash}`,
        '0x',
        {
          language: values.language,
          artist: values.artist,
          year: values.year,
          royalty: values.royalty,
          title: values.title,
        },
      ];

      if (collection.version === '2') {
        contract = monegraphErc1155V2;
        method = 'mint((uint256,address,uint256,string,(string,string,string,string,string)))';
        payable = false;
        args = [
          {
            tokenId: values.tokenId,
            to: values.recipientWallet,
            amount: values.quantity,
            uri: `https://monegraph.mypinata.cloud/ipfs/${values.hash}`,
            attributes: {
              language: values.language,
              artist: values.artist,
              year: values.year,
              royalty: values.royalty,
              title: values.title,
            },
          },
        ];
      }
      break;

    case ERC721:
      method = 'mint(address,string,(string,string,string,string,string))';
      contract = monegraphERC721;
      payable = false;
      args = [
        values.recipientWallet,
        `https://monegraph.mypinata.cloud/ipfs/${values.hash}`,
        {
          language: values.language,
          artist: values.artist,
          year: values.year,
          royalty: values.royalty,
          title: values.title,
        },
      ];

      if (collection.version === '2') {
        contract = monegraphERC721V2;
        payable = true;
      } else if (collection.version === '3') {
        contract = monegraphERC721V3;
        method = 'mint((uint256,address,string,(string,string,string,string,string)))';
        args = [
          {
            tokenId: values.tokenId,
            to: values.recipientWallet,
            uri: `https://monegraph.mypinata.cloud/ipfs/${values.hash}`,
            attributes: {
              language: values.language,
              artist: values.artist,
              year: values.year,
              royalty: values.royalty,
              title: values.title,
            },
          },
        ];
      }
      break;
  }

  return {
    payable,
    contract,
    method,
    args,
  };
}

export default function MintForm({ isMobile, showPreview, form }: any) {
  const { contents: ethereumValue } = useRecoilValueLoadable<any>(priceInEthereum);
  const params = useParams<any>();
  const history = useHistory();
  const wallet = useWallet();
  const active = wallet.status === 'connected';
  const executeMethod = useExecuteMethod();

  const [collection] = useCollection(wallet?.account, params.contract);

  const tokenConfigurable =
    (collection?.type === ERC1155 && collection?.version === '2') ||
    (collection?.type === ERC721 && collection?.version === '3');

  const [disabled, setDisabled] = useState(false);
  const [minting, setIsMinting] = useRecoilState(isMintingAtom);

  useMemo(async () => {
    if (collection?.id && collection.minters.length === 0) {
      message.error(`${wallet.account} does not have minting privledges, please contact an admin to grant privledges`);
    }
  }, [collection?.minters]);

  const onFinish = useCallback(
    async (values) => {
      try {
        setIsMinting(true);

        if (!ethereumValue) {
          throw new Error('Unable to determine ethereum price');
        }

        const uploadMetadata = { name: values.title, keyvalues: { wallet: values.recipientWallet, type: 'mint' } };
        const [creative, archive, animationUrl] = await Promise.all([
          handleFileInput(values.creative, uploadMetadata),
          handleFileInput(values.archive, uploadMetadata),
          handleFileInput(values.animationUrl, uploadMetadata),
        ]);

        values.creative = creative;
        values.archive = archive;
        values.animationUrl = animationUrl;
        values.contractVariables = {
          ...extractVariableValue(values),
          $TOKEN: 'eth',
          $LICENSE: 'CA.05.19.21., Patent No. US 10,380,702',
        };

        values.language = replaceContractVariables(values);

        values.tokenId = values.tokenId || new Date().getTime();

        const languageHash = await pinJSONToIPFS(
          {
            language: values.language,
          },
          {
            pinataMetadata: {
              name: `${values.recipientWallet}.contract.json`,
            },
          }
        );

        values.languageIPFS = `https://ipfs.io/ipfs/${languageHash}`;

        const metadata = createMetadata(values);

        values.hash = await pinJSONToIPFS(metadata as any, {
          pinataMetadata: { name: `${values.recipientWallet}.metadata.json` },
        });

        const context = getContext(collection, values);

        if (!context.args) {
          throw new Error('Unsupported Collection');
        }

        const contract = await getContract(wallet.ethereum, context.contract, collection.address);

        await executeMethod(contract.methods[context.method], context.args, {
          from: wallet.account,
          value: context.payable ? new BN(web3.utils.toWei((100 / ethereumValue).toString())) : null,
        });

        form.resetFields();

        setIsMinting(false);

        message.success(`Token minted successfully`);
        history.push(`/collections/${params.contract}/tokens`);
      } catch (e) {
        setIsMinting(false);
        console.error(e);
        message.error(`There was an error minting your token`);
      }
    },
    [collection?.id, ethereumValue, params.contract, form, wallet.account]
  );

  const onChangeFields = useCallback(() => {
    setDisabled(active && form.getFieldsError().some((item: any) => item.errors.length > 0));
  }, [form, active]);

  const validateBeforSwitch = () => {
    form
      .validateFields()
      .then((_: any) => showPreview())
      .catch((_: any) => {});
  };

  return (
    <Spin spinning={minting}>
      <Form
        id="mintForm"
        labelCol={{}}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFieldsChange={onChangeFields}
        onFinish={onFinish}
        form={form}
        size="large">
        <RecipientWallet />

        {tokenConfigurable ? <TokenId collectionId={params.contract} /> : null}

        <Creative form={form} />

        <Title />

        <Description />

        <Editions type={collection?.type} />

        <Artist />

        <Year />

        <Royalty />

        <RoyaltyAddress />

        <Archive form={form} />
        <ArchiveSHA />

        <ExternalUrl form={form} />

        <AnimationUrl form={form} />

        <Contract />

        <ContractLanguage form={form} />

        <List name="traits" initialValue={[]}>
          {(fields, { add, remove }, { errors }) => (
            <>
              <Traits fields={fields} remove={remove} form={form} />

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Trait
                </Button>
              </Form.Item>

              <Form.ErrorList errors={errors} />
            </>
          )}
        </List>

        {isMobile ? (
          <Button type="primary" className="w-100 btn-primary" disabled={disabled} onClick={validateBeforSwitch}>
            Show Preview
          </Button>
        ) : (
          <Submit disabled={disabled} active={active} />
        )}
      </Form>
    </Spin>
  );
}
