import { formatEther } from '@ethersproject/units';
import BN from 'bignumber.js';
import { orderBy } from 'lodash';
import web3 from 'web3';

import { shortAddress } from './address';
import { AuctionType, AuctionOptions, AuctionStatus } from './auction.types';

export function transformAuction(auction: any, { ethereumValue, blockTimestamp, transform }: AuctionOptions) {
  if (auction?.id) {
    const idParts = auction.id.split('/');

    auction.reservePriceInWei = auction.reservePriceInETH;

    auction.reservePriceInETH = parseFloat(formatEther(auction.reservePriceInETH));
    auction.reservePriceInUSD = ethereumValue * parseFloat(auction.reservePriceInETH);

    auction.seller.username = shortAddress(auction.seller.id);
    auction.hasEnded = auction.dateEnding > 0 && auction.dateEnding < blockTimestamp;
    auction.minimumBid = new BN(auction.reservePriceInWei);
    auction.currentBidInETH = auction.reservePriceInETH;
    auction.currentBidInUSD = auction.reservePriceInUSD;
    auction.isOrganizational = idParts.length > 1;
    auction.contract = idParts.shift();
    auction.index = idParts.shift();

    if (auction.highestBid?.amount) {
      auction.currentBidInWei = auction.highestBid.amount;

      auction.highestBid.amountInEth = parseFloat(formatEther(auction.highestBid.amount));
      auction.currentBidInETH = auction.highestBid.amountInEth;
      auction.currentBidInUSD = ethereumValue * auction.currentBidInETH;

      if (auction.type === AuctionType.TRADITIONAL) {
        auction.minimumBid = BN.minimum(
          new BN(auction.currentBidInWei).multipliedBy(new BN('1.1')),
          new BN(auction.currentBidInWei).plus(web3.utils.toWei('0.1', 'ether'))
        )?.dp(0);
      }

      auction.highestBid.bidder.username = shortAddress(auction.highestBid.bidder.id);
    }

    if (auction.history) {
      auction.history = auction.history.map((history: any) => {
        if (history.bid) {
          history.bid.amount = parseFloat(formatEther(history.bid.amount));
          history.bid.amountInUSD = ethereumValue * history.bid.amount;
        }

        history.user.username = shortAddress(history.user.id);

        return history;
      });

      auction.history = orderBy(auction.history, 'index', 'desc');
    }

    switch (auction.status) {
      case 'Open':
        if (auction.hasEnded) {
          auction.status = AuctionStatus.ENDED;
          auction.version += 1;
        }
        break;

      default:
    }

    if (transform) {
      transform(auction);
    }
  }

  return auction;
}
