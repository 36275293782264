import { Form, Input } from 'antd';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please provide a title',
  },
];

export default function Title() {

  return (
    <Item name="title" label="Title" rules={rules} extra="The artwork’s title. eg: Starry Night">
      <Input />
    </Item>
  );
}
