import React, { useMemo } from 'react';

import { Form, Input, Typography } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';

import styles from './Percentage.module.css';

const { Item } = Form;
const { Text } = Typography;

const rules = [
  {
    required: true,
    message: 'Please set the beneficiary percentage',
  },
  {
    async validator(field: any, value: string) {
      const num = parseFloat(value);

      if (isNaN(num) || num < 0 || num > 100) {
        throw new Error(`Beneficiary of ${num}% is not between 0% and 100%`);
      }
    },
  },
];

export default function Percentage({
  field,
  monegraphPercentage,
}: {
  field: FormListFieldData;
  monegraphPercentage: number;
}) {
  const isMonegraph = field.key === 0;
  const input = useMemo(() => {
    return isMonegraph ? (
      <Text strong className={styles.text}>
        {monegraphPercentage}
      </Text>
    ) : (
      <Input className={styles.input} />
    );
  }, [isMonegraph]);

  return (
    <Item
      label="Percentage"
      rules={rules}
      name={[field.name, 'percentage']}
      extra={isMonegraph ? null : 'The percentage the benficiary will receive on auction finalization'}
      validateFirst={true}
      normalize={(value) => {
        return value.replace(/[^\d\.]+/g, '');
      }}>
      {input}
    </Item>
  );
}
