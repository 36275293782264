import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row, Typography, Space, Divider, Avatar, Layout } from 'antd';
import makeBlockie from 'ethereum-blockies-base64';
import { ethers } from 'ethers';
import { cloneDeep, get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import AuctionCreative from 'components/auction/Creative';
import { AuctionButton } from 'components/auctionbutton/AuctionButton';
import { UserButton } from 'components/userbutton/UserButton';

import useBlockTimestamp from 'hooks/use-block-timestamp';
import useIsEmbedded from 'hooks/use-is-embedded';
import useRecoilEffect from 'hooks/use-recoil-effect';
import { iframeConfigState } from 'iframeconfig.state';
import PageFooter from 'layout/Footer';
import PageHeader from 'layout/Header';
import apollo from 'lib/apollo/client';
import { priceInEthereum } from 'pages/auctions/home/state';
import { transformAuction } from 'transforms/auction';
import events from 'utils/iframe-emitter';

import styles from './Auction.module.css';
import { connectionSentAtom } from './Auction.state';
import History from './components/History';
import Share from './components/Share';
import Winners from './components/Winners';
import { FETCH_AUCTION } from './queries';

export function AuctionPage() {
  const params = useParams<{ contract: string; auctionId?: string }>();
  const auctionId = params.auctionId ? [params.contract, params.auctionId].join('/') : params.contract;
  const isEmbedded = useIsEmbedded();
  const config = useRecoilValue(iframeConfigState);
  const [connectionSent, setConnectionSent] = useRecoilState(connectionSentAtom);
  const [auction, setAuctionData] = useState<any>();
  const { contents: ethereumValue, state } = useRecoilValueLoadable<any>(priceInEthereum);
  const getBlockTimestamp = useBlockTimestamp();
  const wallet = useWallet();

  const listener = useCallback(
    async (event) => {
      if (event?.account === wallet.account) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const message = event?.message;
        const isMetaMask = wallet?.connector !== 'walletconnect';
        const signer = provider.getSigner();
        let signature;

        if (signer) {
          if (!isMetaMask) {
            const hash = ethers.utils.hashMessage(ethers.utils.toUtf8Bytes(message));
            signature = await signer.signMessage(ethers.utils.arrayify(hash));
          } else {
            signature = await signer.signMessage(message);
          }
          if (signature) {
            events.broadcast({
              name: 'iframeloader.signedMessage',
              payload: { account: wallet.account, signature: signature, message },
            });
          }
        }
      }
    },
    [wallet.account]
  );

  useEffect(() => {
    events.on('signMessage', listener);

    return () => {
      events.off('signMessage', listener);
    };
  }, [listener, wallet]);

  useEffect(() => {
    if (auction?.id && wallet?.account) {
      if (connectionSent !== wallet.account) {
        events.broadcast({
          name: 'iframeloader.connectWallet',
          payload: {
            account: wallet.account,
          },
        });

        setConnectionSent(wallet?.account);
      } else {
        events.broadcast({
          name: 'iframeloader.nftOwnership',
          payload: {
            account: wallet.account,
            owner: !!auction.nft.holders.length,
          },
        });
      }
    }
  }, [auction?.id, wallet.account, connectionSent, setConnectionSent]);

  useRecoilEffect(
    ({ set }) => () => {
      const subscription = apollo
        .watchQuery({
          query: FETCH_AUCTION,
          fetchPolicy: 'cache-and-network',
          pollInterval: 5000,
          variables: {
            id: auctionId,
            account: wallet.account,
          },
        })
        .subscribe({
          next: async function ({ data }: any) {
            if (data) {
              const blockTimestamp = await getBlockTimestamp(data._meta.block.number);

              const nextAuction = transformAuction(cloneDeep(get(data, 'auctions[0]')), {
                ethereumValue: ethereumValue ?? 0,
                blockTimestamp,
              });

              if (
                (!auction && nextAuction !== null) ||
                auction?.version !== nextAuction?.version ||
                auction?.hasEnded !== nextAuction?.hasEnded ||
                nextAuction.currentBidInUSD !== auction.currentBidInUSD ||
                auction.status !== nextAuction.status
              ) {
                setAuctionData(nextAuction);
              }
            }
          },
          error: (e: any) => console.error(e),
        });

      return subscription;
    },
    (subscription: ZenObservable.Subscription) => {
      return subscription.unsubscribe();
    },
    [auctionId, auction?.version, ethereumValue, state]
  );

  return auction !== undefined ? (
    <Layout className={`auction-details auction-details-${auctionId}`}>
      <PageHeader />

      {/* Image container */}
      <Layout>
        <Row justify="center" className={styles.contentRow}>
          <Col span={22} xs={22} sm={18}>
            <Row justify="center" className="h-100 container-sm" align="middle">
              <Col>
                <AuctionCreative
                  nft={auction?.nft}
                  muted={false}
                  autoplay={config.auction?.video?.autoplay}
                  showControls={config.auction?.video?.controls}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>

      <Layout className="container">
        {/* Share & User container */}
        <Layout>
          <Row className={styles.shareContainer} align="middle" justify="center">
            <Col flex={1} xs={8} sm={12}>
              {/* @ts-ignore */}
              <UserButton username={auction?.seller.username} />
            </Col>
            <Col flex={1} xs={16} sm={12}>
              <Row justify="end">
                <Col>
                  <Share auctionId={auction.id} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>

        {/* Details */}
        <Layout>
          <Row className={styles.detailsRow} gutter={16}>
            <Col span={24} md={12}>
              <Typography.Title className="auction-details-title">{auction?.nft.name}</Typography.Title>

              <Row className="pt-3 pb-3">
                <Col span={24} lg={16}>
                  <Typography.Title level={5} className="auction-detalis-artist">
                    Artist
                  </Typography.Title>
                  <Typography.Title level={3} className="m-0 auction-detalis-artist-name">
                    {auction.nft.artist || auction.seller.name}
                  </Typography.Title>
                </Col>
              </Row>

              <Row>
                <Col span={24} lg={16}>
                  <Typography.Title level={5} className="auction-detalis-description-label">
                    Description
                  </Typography.Title>
                  <Space direction="vertical" className="auction-detalis-description">
                    {auction?.nft.description?.split('\n').map((paragraph: string, index: number) => (
                      <Typography.Text key={`${index}`}>{paragraph}</Typography.Text>
                    ))}
                  </Space>
                </Col>
              </Row>

              <Row className="pt-3 pb-3">
                <Col span={24} lg={16}>
                  <Typography.Title level={5} className="auction-detalis-edition-label">
                    Editions
                  </Typography.Title>
                  <Typography.Title level={3} className="m-0 auction-detalis-edition">
                    {auction.quantity} of {auction.nft.quantity}
                  </Typography.Title>
                  {auction.nft.quantity > 1 ? (
                    <Typography.Text className="auction-detalis-total">
                      Total Supply {auction.nft.quantity}
                    </Typography.Text>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col span={24} md={12}>
              <AuctionButton auction={auction} />
              <Winners auction={auction} />
              <History auction={auction} />
            </Col>
          </Row>
        </Layout>

        {!isEmbedded || !config.auction?.hideSeller ? (
          <Layout>
            <Row className={styles.detailsRow}>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Typography.Title level={3} className="m-0">
                      Seller
                    </Typography.Title>
                    <Divider className="my-1 border-secondary" />
                  </Col>
                </Row>

                <Row align="middle" gutter={16}>
                  <Col span={24} md={12} className="pt-3">
                    <Row align="middle">
                      <Col className="pr-3 auction-detalis-avatar">
                        <Avatar src={makeBlockie(`${auction.seller.id}`)} size={115} />
                      </Col>
                      <Col className="auction-detalis-seller">
                        <Typography.Title className="m-0">{auction.seller.name}</Typography.Title>
                        <Typography.Title className="m-0" level={4}>
                          {`@${auction?.seller?.username}`}
                        </Typography.Title>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24} md={12} className="pt-3 auction-detalis-description">
                    <Typography.Title level={4}>{auction?.seller?.description}</Typography.Title>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Layout>
        ) : null}
      </Layout>
      <PageFooter />
    </Layout>
  ) : null;
}
