import { gql } from 'apollo-boost';

export const FETCH_ORGANIZATIONS = gql`
  query GetOrganizations @api(name: "polygon") {
    organizations(where: { active: true }) {
      id
      name
      seats
      accounts {
        id
      }
    }
  }
`;

export const FETCH_ACCOUNT_DATA = gql`
  query GetAccountData($wallets: [String]) @api(name: "ethereum") {
    accounts {
      id
      auctions {
        id
      }
      collections {
        id
      }
    }
  }
`;
