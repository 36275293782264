import { gql } from 'apollo-boost';

import ajv from 'lib/ajv';

export const FETCH_COLLECTION_KEY = 'collections.getCollection';

ajv.compile({
  $id: FETCH_COLLECTION_KEY,
  $schema: 'http://json-schema.org/draft-07/schema#',
  required: ['accountId', 'collectionId'],
  properties: {
    accountId: {
      type: 'string',
    },
    collectionId: {
      type: 'string',
    },
  },
  type: 'object',
});

export const FETCH_COLLECTION = gql`
  query GetCollection($accountId: ID, $collectionId: ID) @api(name: "ethereum") {
    collection(id: $collectionId) {
      id
      name
      symbol
      type
      address
      version
      minters(where: { id: $accountId }) {
        id
      }
    }
  }
`;

export const FETCH_TOKEN_ID_WITH_PUBLIC_MINT = gql`
  query GetTokenId($collectionId: String, $tokenId: String) @api(name: "ethereum") {
    collection(id: $collectionId) {
      id
      tokens(where: { tokenId: $tokenId }) {
        id
        tokenId
      }
      publicMints {
        name
        tokens(where: { tokenId: $tokenId }) {
          id
          tokenId
        }
      }
    }
  }
`;

export const FETCH_TOKEN_ID = gql`
  query GetTokenId($collectionId: String, $tokenId: String) @api(name: "ethereum") {
    collection(id: $collectionId) {
      id
      tokens(where: { tokenId: $tokenId }) {
        id
        tokenId
      }
    }
  }
`;

export const FETCH_TOKEN_IDS = gql`
  query GetTokenId($collectionId: String, $tokenIds: [String]) @api(name: "ethereum") {
    collection(id: $collectionId) {
      id
      tokens(where: { tokenId_in: $tokenIds }) {
        id
        tokenId
      }
    }

    publicMints(where: { collection: $collectionId }) {
      id
      tokens(where: { tokenId_in: $tokenIds }) {
        id
        token {
          id
        }
        tokenId
      }
    }
  }
`;
