import { useCallback, useMemo } from 'react';

import { Modal, Form, Typography, InputNumber, Space, Input, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';

import { ERC1155 } from 'constants/collection';
import monegraphERC1155 from 'contracts/monegraph-erc1155';
import monegraphERC721 from 'contracts/monegraph-erc721';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import { useQueryResultsFromHash } from 'hooks/use-query.state';

import { burnState } from '../Tokens.state';

const { Item } = Form;

export default function BurnForm({ hash }: { hash: string }) {
  const [form] = Form.useForm();
  const wallet = useWallet();
  const params = useParams<any>();
  const result = useQueryResultsFromHash(hash);
  const executeMethod = useExecuteMethod();
  const collection = result ? result.collection : null;

  const isERC1155 = collection?.type === ERC1155;
  const collectionContract = collection?.type ? (isERC1155 ? monegraphERC1155 : monegraphERC721) : null;

  const contract = useContract(collectionContract, {
    address: params.contract,
  });

  const [burnStatus, setBurnState] = useRecoilState(burnState);

  const burn = useCallback(
    async (values: any) => {
      const id = burnStatus.id;
      const args = isERC1155 ? [wallet.account, id, values.quantity] : [id];

      setBurnState({
        ...burnStatus,
        transacting: true,
      });

      await executeMethod(contract.burn, args, {
        from: wallet.account,
      });

      form.resetFields();

      setBurnState({
        visible: false,
        id: null,
        max: null,
        transacting: false,
      });
    },
    [isERC1155, wallet?.account, contract?.address, burnStatus]
  );

  const handleCancel = () => {
    form.resetFields();

    setBurnState({
      ...burnStatus,
      visible: false,
    });
  };

  const editions = useMemo(() => {
    return isERC1155 ? (
      <Item label="Editions" name="quantity" extra="The number of editions to be burned" validateFirst={true}>
        <InputNumber min={1} max={burnStatus.max} />
      </Item>
    ) : null;
  }, [isERC1155, burnStatus.max]);

  return (
    <Modal
      visible={burnStatus.visible}
      onOk={form.submit}
      onCancel={handleCancel}
      title="Do you want to burn this token?">
      <Spin spinning={burnStatus.transacting}>
        <Form form={form} onFinish={burn} initialValues={{ quantity: 1 }}>
          <Item hidden required name="id">
            <Input value={burnStatus.id} />
          </Item>

          <Space direction="vertical">
            <Typography.Text>Once burned you will no longer have ownership or control over this token?</Typography.Text>
            {editions}
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
}
