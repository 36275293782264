import { Form, Input } from 'antd';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please provide an artist name',
  },
];

export default function Artist() {
  return (
    <Item name="artist" label="Artist" rules={rules} extra="The name of the person(s) or group that created the work">
      <Input />
    </Item>
  );
}
