import { Form, Input } from 'antd';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';
import { useWallet } from 'use-wallet';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please set the recipient address',
  },
  {
    async validator(field: any, walletAddress: string) {
      addressCheck.toChecksumAddress(walletAddress);
    },
    message: 'Please enter a valid wallet address',
  },
];

export default function RecipientWallet() {
  const wallet = useWallet();
  return (
    <Item
      name="recipientWallet"
      label="Recipient Wallet"
      rules={rules}
      initialValue={wallet.account!}
      extra="Please enter the wallet in wich the token will be minted to">
      <Input />
    </Item>
  );
}
