import React, { useEffect, useRef, useState } from 'react';

import { Config } from 'iframeconfig.types';

declare var iframeLoader: any;

interface AuctionViewerProps {
  url: string;
  config: Config;
  onReady: () => void;
}

export default function PageViewer({ url, config, onReady }: AuctionViewerProps) {
  const monegraph = useRef<any>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (url) {
      monegraph.current = iframeLoader('#monegraph');
      monegraph.current?.open({
        url,
        width: '100%',
        height: '100%',
        config,
      });
    }

    monegraph.current?.on('ready', () => {
      setIsReady(true);
      onReady();
    });

    return () => monegraph.current?.close();
  }, [url]);

  useEffect(() => {
    if (isReady) {
      monegraph.current.postConfig({
        ...config,
      });
    }
  }, [config, isReady]);

  return <div id="monegraph"></div>;
}
