import { useCallback, useEffect } from 'react';

import { noop } from 'lodash';
import { useRecoilCallback } from 'recoil';

type AnyFunction = (...args: any) => any;

export default function useRecoilEffect(
  _setup: (...args: any) => AnyFunction,
  _cleanup: AnyFunction | null,
  deps: unknown[]
) {
  const setup = useRecoilCallback(_setup, deps);
  const cleanup = useCallback(_cleanup || noop, deps);

  useEffect(() => {
    const response = setup();

    return () => cleanup(response);
  }, deps);
}
