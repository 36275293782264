import { useCallback, useMemo } from 'react';

import ajv from 'lib/ajv';
import apollo from 'lib/apollo/client';

import { useQueryResultsFromObject } from './use-query.state';
import { QueryOptions } from './use-query.types';

export default function useQuery<T = any>(
  {
    query,
    variables = {},
    options = {},
    validate = {},
    transform = (response) => response.data,
    defaultValidator = null,
    validationKey,
  }: QueryOptions<T>,
  dependencies: unknown[] = []
): [T, string] {
  const [result, hash, setResults] = useQueryResultsFromObject<T>({
    query,
    variables,
    validate,
    options,
  });

  const fetcher = useCallback(async () => {
    let validator = validationKey ? ajv.getSchema(validationKey) : defaultValidator;

    if (
      !validator ||
      validator({
        ...variables,
        ...validate,
      })
    ) {
      const response = await apollo.query({
        fetchPolicy: 'network-only',
        ...options,
        variables,
        query,
      });

      const transformed = await transform(response);

      setResults(transformed);
    }
  }, [validationKey, variables, validate, query]);

  useMemo(() => {
    fetcher();
  }, dependencies);

  return [result, hash];
}
