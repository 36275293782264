/* eslint-disable react/no-multi-comp */
import { HighlightOutlined } from '@ant-design/icons';
import { TableColumnType, Button } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import env from 'lib/env';

const columns: Array<TableColumnType<any>> = [
  {
    title: 'Name',
    dataIndex: 'nft',
    key: 'nft',
    render: (value: any, record: any) => (
      <a href={`${env.MONEGRAPH_AUCTIONS_URL}/auctions/${record.id}`}>{value.name}</a>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Created',
    dataIndex: 'dateCreated',
    key: 'nft',
    render: (value: any) => moment(value, 'X').format('MMM D[,] YYYY [at] hh:mm a'),
  },
  {
    title: 'Started',
    dataIndex: 'dateStarted',
    key: 'nft',
    render: (value: any) => moment(value, 'X').format('MMM D[,] YYYY [at] hh:mm a'),
  },
  {
    title: 'Ending',
    dataIndex: 'dateEnding',
    key: 'nft',
    render: (value: any) => (value !== '0' ? moment(value, 'X').format('MMM D[,] YYYY [at] hh:mm a') : ''),
  },
  {
    title: 'Reserve Price ETH',
    dataIndex: 'reservePriceInETH',
    key: 'reservePriceInETH',
  },
  {
    title: 'Current Bid',
    dataIndex: 'currentBidInETH',
    key: 'currentBidInETH',
    render: (value: any, record: any) => {
      return record.highestBid ? `${value.toFixed(2)} ETH, $${record.currentBidInUSD.toFixed(2)} USD` : '';
    },
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'actions',
    render: (id: any) => (
      <Link to={`/auction/${id}/wizard`}>
        <Button icon={<HighlightOutlined />}></Button>
      </Link>
    ),
  },
];

export default columns;
