import React from 'react';

import { Form, Input } from 'antd';

import hashURLTransform from './transforms/hash-url';

const { Item } = Form;

export default function ExternalUrl({ form }: any) {
  const rules = [
    {
      transform: hashURLTransform,
      async validator(_: any, value: string) {
        if (value?.length) {
          try {
            //@ts-ignore
            const response = await fetch(value, {
              method: 'GET',
              mode: 'cors',
              cache: 'reload',
            });

            if (!response.ok) {
              throw new Error(`The url "${value}" did not return a success status code`);
            }
          } catch (e) {}

          form.setFieldsValue({ externalUrl: value });
        }
      },
    },
  ];

  return (
    <Item
      label="External Url"
      rules={rules}
      name="externalUrl"
      validateTrigger="onBlur"
      extra="A link to view the work on your website usable by 3rd party sites">
      <Input />
    </Item>
  );
}
