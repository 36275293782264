import React from 'react';

import { Form, Input, FormItemProps } from 'antd';

const { Item } = Form;

export const UrlInputRule = [
  {
    async validator(_: any, value: string) {
      if (value?.length) {
        try {
          const response = await fetch(value, {
            method: 'GET',
            mode: 'cors',
            cache: 'reload',
          });

          if (!response.ok) {
            throw new Error(`The url "${value}" did not return a success status code`);
          }
        } catch (e) {
          throw new Error(`The url "${value}" did not return a success status code`);
        }
      }
    },
  },
];

export default function UrlInput(props: FormItemProps) {
  return (
    <Item rules={UrlInputRule} {...props}>
      <Input type="url" />
    </Item>
  );
}
