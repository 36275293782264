import React from 'react';

import { Form, DatePicker } from 'antd';

const { Item } = Form;

interface StartTimeProps {
  name: string | string[];
}

export default function StartTime({ name }: StartTimeProps) {
  return (
    <Item
      label="Start Time"
      name={name}
      rules={[{ required: true, message: 'Start Time is required' }]}
      extra="Date at which mints will start">
      <DatePicker className="w-100" showTime />
    </Item>
  );
}
