import { useCallback } from 'react';

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { clone, pull, reject } from 'lodash';
import { useRecoilState } from 'recoil';

import { dataSourceAtom, editableKeysAtom } from '../state';

export default function DeleteRow({ row }: any) {
  const [dataSource, setDataSource] = useRecoilState(dataSourceAtom);
  const [editableKeys, setEditableKeys] = useRecoilState<any[]>(editableKeysAtom);

  const onDelete = useCallback(() => {
    Modal.confirm({
      title: 'Delete Row',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this row?',
      okText: 'Delete',
      cancelText: 'cancel',
      onOk() {
        setDataSource(reject(clone(dataSource), { id: row.id }));
        setEditableKeys(pull(clone(editableKeys), row.id));
      },
    });
  }, [dataSource, editableKeys, row.id]);

  return (
    <Button icon={<DeleteOutlined />} danger size="small" shape="circle" type="primary" onClick={onDelete}></Button>
  );
}
