import { useMemo, useState } from 'react';

import { useWallet } from 'use-wallet';

import { useContractOptions } from './use-contract.types';

export async function getContract(provider: any, contract: any, address?: string) {
  await contract.setProvider(provider);

  return address ? contract.at(address) : contract;
}

export default function useContract(contract: any, options: useContractOptions = {}): any {
  const wallet = useWallet();
  const [deployed, setDeployed] = useState<any>();

  useMemo(async () => {
    if (contract && wallet.ethereum && deployed?.address !== options.address) {
      await contract.setProvider(options.provider || wallet.ethereum);

      if (options.deployed) {
        setDeployed(await contract.deployed());
      } else if (options.address) {
        setDeployed(await contract.at(options.address));
      }
    }
  }, [!!contract, options.address, options.provider, deployed?.address, wallet.status]);

  return deployed;
}
