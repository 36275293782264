import { useCallback, useEffect, useMemo } from 'react';

import { Modal, Form, Typography, Space, Spin, Select, Divider, List, Avatar, Tag } from 'antd';
import makeBlockie from 'ethereum-blockies-base64';
import { capitalize, cloneDeep, size, toArray } from 'lodash';
import { useRecoilState, useResetRecoilState } from 'recoil';
import web3 from 'web3';

import useTransferToken from 'hooks/use-transfer-token';
import { shortAddress } from 'lib/transform/transforms/address';

import { transferState } from '../Auctions.state';

const { Option } = Select;

const { Item } = Form;

export default function TransferForm() {
  const [form] = Form.useForm();
  const { transfer } = useTransferToken();

  const [state, setTransferState] = useRecoilState(transferState);
  const resetState = useResetRecoilState(transferState);

  const initialValues = useMemo(() => {
    return state.auction?.id
      ? {
          recipients: [`${state.auction.highestBid.bidder.id}-${0}`],
        }
      : {};
  }, [state.auction?.id]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const onCancel = useCallback(() => {
    form.resetFields();

    resetState();
  }, [form, resetState]);

  const [address, token] = useMemo(() => (state.auction?.nft?.id ? state.auction.nft.id.split('-') : []), [
    state.auction?.nft?.id,
  ]);

  const bids = useMemo(() => {
    return state.auction?.bids?.length ? [...state.auction.bids] : [];
  }, [state.auction?.bids]);

  console.log(bids);

  const process = useCallback(
    async (values) => {
      const hasProgress = !!state.progress.length;
      const progression = hasProgress
        ? state.progress
        : toArray(values.recipients).map((recipient: string) => {
            return {
              wallet: recipient.split('-').shift(),
              status: 'default',
              message: '',
            };
          });

      if (progression.length) {
        setTransferState({
          ...state,
          progress: progression,
        });

        for (let i = 0; i < progression.length; i++) {
          if (['error', 'default'].indexOf(progression[i].status) !== -1) {
            setTransferState((last) => {
              const next = cloneDeep(last);

              next.progress[i].status = 'processing';
              next.progress[i].message = '';

              return next;
            });

            try {
              if (bids.some((bid) => bid.bidder.id === progression[i].wallet)) {
                await transfer(address, token, progression[i].wallet, 1);

                setTransferState((last) => {
                  const next = cloneDeep(last);

                  next.progress[i].status = 'success';

                  return next;
                });
              } else {
                throw new Error(`Invalid recipient: ${progression[i].wallet} not found in bids`);
              }
            } catch (e: any) {
              setTransferState((last) => {
                const next = cloneDeep(last);

                next.progress[i].status = 'error';
                next.progress[i].message = e.message;

                return next;
              });
            }
          }
        }
      } else {
        onCancel();
      }
    },
    [state, address, token, onCancel, bids]
  );

  const list = useMemo(() => {
    return size(state.progress) > 0 ? (
      <>
        <Divider />
        <List
          dataSource={state.progress}
          renderItem={(item: any, idx: number) => (
            <List.Item key={idx}>
              <List.Item.Meta
                avatar={<Avatar size={42} src={makeBlockie(`${item.wallet}`)} />}
                title={item.wallet}
                description={item.message}
              />
              <Tag color={item.status}>{capitalize(item.status)}</Tag>
            </List.Item>
          )}
        />
      </>
    ) : null;
  }, [state.progress]);

  const select = useMemo(() => {
    return size(state.progress) > 0 || !state.auction ? null : (
      <Item name="recipients" label="Recipients" required>
        <Select
          disabled={size(state.progress) > 0}
          mode={state.auction.quantity > 1 ? 'multiple' : void 0}
          allowClear
          placeholder="Select Recipients">
          {bids.map((bid: any, idx: number) => {
            console.log(idx + 1, bid.bidder.id, bid.amount);
            return (
              <Option key={idx} value={`${bid.bidder.id}-${idx}`}>
                {idx + 1} - {shortAddress(bid.bidder.id)} - {web3.utils.fromWei(bid.amount, 'ether')}
              </Option>
            );
          })}
        </Select>
      </Item>
    );
  }, [state.progress, state.auction]);

  return state.auction && bids.length ? (
    <Modal
      visible={state.visible}
      okText="Process"
      cancelText="Close"
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      title="Do you want to complete the token transfer?">
      <Spin spinning={state.transacting}>
        <Form form={form} onFinish={process}>
          <Space direction="vertical">
            <Typography.Text>NFT: {state.auction.nft.name}</Typography.Text>
            <Typography.Text>ADDRESS: {address}</Typography.Text>
            <Typography.Text>TOKEN: {token}</Typography.Text>
            <Typography.Text>QUANTITY: {state.auction.quantity}</Typography.Text>
          </Space>
          {list}
          <Divider />
          {select}
        </Form>
      </Spin>
    </Modal>
  ) : null;
}
