import React, { ComponentType } from 'react';

import { Redirect, useLocation } from 'react-router-dom';
import { useWallet } from 'use-wallet';

import usePermissions from 'pages/admin/hooks/use-permissions';

export default function withAuth(Component: ComponentType<{ permissions: any }>) {
  return function () {
    const wallet = useWallet();
    const location = useLocation();
    const permissions = usePermissions();

    return wallet.status !== 'connected' ? (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            nextPath: location.pathname.toLowerCase() !== '/login' ? location.pathname + location.search : '/',
          },
        }}
      />
    ) : (
      <Component permissions={permissions} />
    );
  };
}
