import { keyBy, each, map, size } from 'lodash';

import useMonitorQuery from 'hooks/use-monitor-query';

import { FETCH_ACCOUNT_DATA, FETCH_ORGANIZATIONS } from './Organizations.queries';

export function useOrganizationList(): any {
  const [accounts] = useMonitorQuery(
    {
      query: FETCH_ACCOUNT_DATA,
      transform({ data }) {
        return keyBy(data?.accounts, 'id');
      },
    },
    []
  );

  return useMonitorQuery(
    {
      query: FETCH_ORGANIZATIONS,
      defaultValidator(context: any) {
        return size(context.accounts) > 0;
      },
      validate: {
        accounts,
      },
      transform({ data }) {
        return data
          ? map(data.organizations, (organization: any) => {
              organization.auctions = 0;
              organization.collections = 0;

              each(organization.accounts, (account) => {
                if (accounts[account.id]) {
                  organization.auctions += accounts[account.id].auctions.length;
                  organization.collections += accounts[account.id].collections.length;
                }
              });

              organization.accounts = organization.accounts.length;

              return organization;
            })
          : null;
      },
    },
    [accounts]
  );
}
