import hashing from 'object-hash';
import { atom, selectorFamily, useRecoilTransaction_UNSTABLE, useRecoilValue } from 'recoil';

import { useQueryResultsQuery } from './use-query.types';

const resultsAtom = atom<Record<string, any>>({
  key: 'query.monitor.results',
  default: {},
});

const queryResults = selectorFamily<Record<string, any>, { hash: string; def?: Record<string, any> }>({
  key: 'query.monitor.selector',
  get({ hash, def }) {
    return ({ get }) => {
      const results = get(resultsAtom);

      return results[hash] || def;
    };
  },
});

export function useQueryResultsFromHash(hash: string, def?: Record<string, unknown>) {
  return useRecoilValue(queryResults({ hash, def }));
}

export function useQueryResultsFromObject<T>(query: useQueryResultsQuery): [T, string, (result: T) => void] {
  const hash = hashing(query, { ignoreUnknown: true });
  const result = useRecoilValue(queryResults({ hash }));

  const setter = useRecoilTransaction_UNSTABLE(({ get, set }) => (hash: string, result: T) => {
    const current = get(resultsAtom);

    set(resultsAtom, {
      ...current,
      [hash]: result,
    });
  });

  return [result as T, hash, setter.bind(null, hash)];
}
