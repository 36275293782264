import { atom } from 'recoil';

export const refreshAuction = atom({
  key: 'auction.refresh',
  default: 0,
});

export const connectionSentAtom = atom<string>({
  key: 'auction.connectionSent',
  default: '',
});

export default {
  atoms: {
    refreshAuction,
  },
};
