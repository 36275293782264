import React from 'react';

import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { Space, Button } from 'antd';
import { useHistory } from 'react-router';

interface CollectionsActionsProps {
  address: string;
}

export function CollectionsActions({ address }: CollectionsActionsProps) {
  const history = useHistory();

  return (
    <Space>
      <Button icon={<PlusOutlined />} onClick={() => history.push(`/collections/${address}/mint`)}>
        Mint
      </Button>
      <Button icon={<UserAddOutlined />} onClick={() => history.push(`/collections/${address}/minter`)} />
    </Space>
  );
}
