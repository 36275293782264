import { useMemo } from 'react';

import { Col, Row, Typography } from 'antd';
import { get } from 'lodash';
import { useRecoilValue } from 'recoil';

import { iframeConfigState } from 'iframeconfig.state';
import { GalleryFilterTypes } from 'iframeconfig.types';

import styles from './Title.module.css';

export default function Title() {
  const config = useRecoilValue(iframeConfigState);
  const type = get(config, 'gallery.filters.type', false);
  const title = config?.gallery?.filters?.title;

  const text = useMemo(() => {
    let text = title;

    if (!title) {
      switch (type) {
        case GalleryFilterTypes.COLLECTION:
          text = 'Collections';
          break;

        case GalleryFilterTypes.ARTIST:
          text = 'Artists';
          break;
      }
    } else {
      text = title;
    }

    return text;
  }, [type, title]);

  return type ? (
    <Row>
      <Col>
        {get(config, 'gallery.filters.hidden', false) !== true ? (
          <Typography.Title level={2} className={styles.title}>
            <strong>{text}</strong>
          </Typography.Title>
        ) : null}
      </Col>
    </Row>
  ) : null;
}
