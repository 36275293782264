import { useMemo } from 'react';

import { ProColumns } from '@ant-design/pro-table';
import { ProFieldValueType } from '@ant-design/pro-utils';
import { Button, Image } from 'antd';
import { isString, truncate } from 'lodash';

import { AddressInputRules } from 'components/AddressInput/AddressInput';

import { ArchiveSHARule } from '../components/ArchiveSHARule';
import FileInput, { FileInputRule, RequiredFileInputRule } from '../components/FileTableInput';
import { EditionsRules, PercentageRules, PriceRules, YearRules } from '../components/NumberInputRules';
import { TokenIdRules } from '../components/TokenIdRules';

export default function useColumns(isERC1155: boolean) {
  return useMemo(() => {
    const baseColumns: ProColumns<any, ProFieldValueType>[] = [
      {
        title: 'Token Id',
        dataIndex: 'tokenId',
        width: 100,
        hideInSetting: true,
        tooltip:
          'A token identifier will be automatically generated, if you wish to define a token identifier enter it below',
        formItemProps: {
          rules: TokenIdRules,
          normalize: (value) => {
            return isString(value) ? value.replace(/[^\d]+/g, '') : value;
          },
        },
      },

      {
        title: 'Preview',
        dataIndex: 'creative',
        hideInSetting: true,
        width: 125,
        tooltip:
          'The link / IPFS Hash to preview media for the artwork. This appears in a collector’s wallet or on 3rd party sites.',
        renderFormItem: FileInput,
        // eslint-disable-next-line react/no-multi-comp
        render(url: any) {
          return url ? <Image width={50} src={url instanceof File ? URL.createObjectURL(url) : url} /> : null;
        },
        formItemProps: {
          rules: RequiredFileInputRule,
        },
      },

      {
        title: 'Title',
        dataIndex: 'title',
        hideInSetting: true,
        width: 175,
        formItemProps: { rules: [{ required: true, message: 'Title is required' }] },
        render(value: any) {
          return truncate(value, {
            length: 50,
          });
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        hideInSetting: true,
        valueType: 'textarea',
        width: 200,
        formItemProps: { rules: [] },
        render(value: any) {
          return truncate(value, {
            length: 50,
          });
        },
      },

      {
        title: 'Artist',
        dataIndex: 'artist',
        hideInSetting: true,
        tooltip: 'The name of the person(s) or group that created the work',
        width: 150,
        formItemProps: { rules: [{ required: true, message: 'Artist is required' }] },
        render(value: any) {
          return truncate(value, {
            length: 25,
          });
        },
      },
      {
        title: 'Year',
        dataIndex: 'year',
        hideInSetting: true,
        tooltip: 'The year the work was made',
        width: 75,
        formItemProps: {
          rules: YearRules,
          normalize: (value) => {
            return isString(value) ? value?.replace(/[^\d]+/g, '') : value;
          },
        },
      },
      {
        title: 'Price',
        dataIndex: 'cost',
        hideInSetting: true,
        width: 150,
        fieldProps: {
          suffix: 'ETH',
        },
        formItemProps: {
          rules: PriceRules,
          validateFirst: true,
          normalize: (value) => {
            return isString(value) ? value.replace(/[^0-9.,]/, '') : value;
          },
        },
      },
      {
        title: 'Royalty',
        key: 'royalty',
        hideInSetting: true,
        children: [
          {
            title: 'Address',
            dataIndex: 'royaltyAddress',
            hideInSetting: true,
            width: 175,
            tooltip: 'The stated resale royalty percentage owed to you upon resale',
            formItemProps: {
              rules: [{ required: true, message: 'Royalty Address is required' }, ...AddressInputRules],
              required: true,
            },
            render(address: any) {
              const strLen = 15;
              if (!isString(address) || address.length <= strLen) return address;

              const separator = '...';

              const charsToShow = strLen - 3;
              const frontChars = Math.ceil(charsToShow / 2);
              const backChars = Math.floor(charsToShow / 2);

              return address.substr(0, frontChars) + separator + address.substr(address.length - backChars);
            },
          },
          {
            title: 'Percentage',
            dataIndex: 'royalty',
            hideInSetting: true,
            width: 100,
            tooltip: 'The ethereum wallet address that will receive the resale royalty',
            formItemProps: {
              rules: PercentageRules,
              normalize: (value) => {
                return value.replace(/[^0-9.,]/, '');
              },
            },
          },
        ],
      },
      {
        title: 'File',
        dataIndex: 'archive',
        width: 100,
        tooltip:
          'A link to a file or archive that contains higher resolution media or any additional material beyond the preview media above',
        renderFormItem: FileInput,
        formItemProps: {
          rules: FileInputRule,
        },
      },
      {
        title: 'SHA256 checksum',
        dataIndex: 'archiveSHA',
        width: 100,
        tooltip: 'An sha256 hash of the artwork',
        formItemProps: {
          rules: ArchiveSHARule,
        },
      },
      {
        title: 'External Url',
        dataIndex: 'externalUrl',
        tooltip: 'A link to view the work on your website usable by 3rd party sites',
        width: 100,
        formItemProps: { rules: RequiredFileInputRule },
      },
      {
        title: 'Animation Url',
        dataIndex: 'animationUrl',
        width: 100,
        tooltip:
          'A URL to a multi-media attachment for the item. The file extensions GLTF, GLB, WEBM, MP4, M4V, OGV, and OGG are supported, along with the audio-only extensions MP3, WAV, and OGA. Animation_url also supports HTML pages, allowing you to build rich experiences using JavaScript canvas, WebGL, and more. Access to browser extensions is not supported',
        renderFormItem: FileInput,
        formItemProps: {
          rules: FileInputRule,
        },
      },

      {
        title: 'JSON',
        dataIndex: 'json',
        width: 100,
        hideInSetting: true,
        hideInTable: true,
      },

      {
        title: '',
        key: 'id',
        valueType: 'option',
        hideInSetting: true,
        render: (text, record, _, action) => [
          <Button
            key="edit"
            type="link"
            size="small"
            disabled={!record.editable}
            onClick={() => {
              action?.startEditable?.(record.id);
            }}>
            Edit
          </Button>,
        ],
      },
    ];

    const conditionalColumns: ProColumns<any, ProFieldValueType>[] = isERC1155
      ? [
          {
            title: 'Editions',
            dataIndex: 'quantity',
            valueType: 'digit',
            hideInSetting: true,
            width: 75,
            index: 5,
            formItemProps: { rules: EditionsRules },
          },
        ]
      : [];

    return [...baseColumns, ...conditionalColumns];
  }, [isERC1155]);
}
