import React, { useMemo } from 'react';

import { Layout, Row } from 'antd';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { iframeConfigState } from 'iframeconfig.state';
import PageFooter from 'layout/Footer';
import PageHeader from 'layout/Header';

import { useMonitorGallery } from './Gallery.state';
import Auctions from './components/Auctions';
import Fitlers from './components/Filters';
import Title from './components/Title';

const { Content } = Layout;

export default function Gallery() {
  const config = useRecoilValue(iframeConfigState);
  const params = useParams();
  const organizationId = useMemo(() => get(params, 'id') || config.organization, []);

  const [, hash] = useMonitorGallery(organizationId);

  return true ? (
    <Layout className={`gallery-view galler-view-${organizationId}`}>
      <PageHeader />
      <Content className="p-3 my-3 container-xl">
        <Title />
        <Row>
          <Fitlers hash={hash} />
          <Auctions hash={hash} />
        </Row>
      </Content>
      <PageFooter />
    </Layout>
  ) : null;
}
