import { Row, Col, Typography, Card, Table, Spin } from 'antd';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import Layout from 'layout/AdminLayout';

import { fetchAuctions } from './AllAuctions.state';
import columnDefinition from './components/ColumnDefinitions';

export default function AllAuctions() {
  const wallet = useWallet();
  const { state: auctionsState, contents: auctions } = useRecoilValueLoadable(fetchAuctions(wallet.account));

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Row align="middle" justify="space-between">
              <Col>
                <Typography.Title>Auctions</Typography.Title>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center">
              <Col span={24}>
                <Spin spinning={auctionsState === 'loading'}>
                  <Table
                    columns={columnDefinition}
                    dataSource={auctionsState === 'hasValue' ? auctions : []}
                    scroll={{ x: true }}
                  />
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
