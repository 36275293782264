/* eslint-disable react/no-multi-comp */
import { useCallback, useMemo } from 'react';

import { DollarCircleOutlined, ExclamationCircleOutlined, FireOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Tooltip, Typography, Modal } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';

import organizationAuctionBeacon from 'contracts/organization-auction-beacon';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import { verified as verifiedState } from 'hooks/verify-organization/state';
import env from 'lib/env';

import { burnState } from '../Tokens.state';

const { Paragraph } = Typography;

export const TokensTable = ({collection, contract}: any) => {
    const wallet = useWallet();
    const history = useHistory();
    const verified = useRecoilValue(verifiedState);
    const executeMethod = useExecuteMethod();
    const setBurnState = useSetRecoilState(burnState);

  const beacon = useContract(organizationAuctionBeacon, {
    address: env.MONEGRAPH_ORGAUCTIONS_FACTORY,
  });

  const onBurn = useCallback(async (id: string, max: number) => {
    setBurnState({
      id,
      max,
      visible: true,
      transacting: false,
    });
  }, []);

  const activate = useCallback(async () => {
    await executeMethod(beacon.deployContract, [verified.organizationId], {
      from: wallet.account,
    });
  }, [beacon?.address, verified.organizationId, wallet.account]);

  const createAuction = useCallback(
    (tokenId: number) => {
      if (contract) {
        history.push(`/auction/create/${contract.auctions}/?address=${collection?.address}&tokenId=${tokenId}`);
      } else {
        Modal.confirm({
          title: 'Auction Platform',
          icon: <ExclamationCircleOutlined />,
          content: 'Click below to activate the auction platform for your organization',
          okText: 'Activate',
          cancelText: 'Later',
          onOk: activate,
        });
      }
    },
    [history, collection?.address, contract, activate]
  );
  const columns = useMemo(
    () => [
      {
        title: 'Token Id',
        dataIndex: 'tokenId',
      },

      {
        title: 'Title',
        dataIndex: 'name',
      },

      {
        title: 'Description',
        dataIndex: 'description',
        render(description: string) {
          return (
            <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
              {description}
            </Paragraph>
          );
        },
      },

      {
        title: 'Artist',
        dataIndex: 'artist',
      },

      {
        title: 'Editions',
        dataIndex: ['holders', '0', 'quantity'],
        key: 'quantity',
        render(quantity: number) {
          return new Intl.NumberFormat('en-US').format(quantity);
        },
      },

      {
        title: 'actions',
        dataIndex: 'uri',
        render(_: any, values: any) {
          return (
            <>
              <Tooltip title="Burn">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<FireOutlined />}
                  onClick={() => onBurn(values.tokenId, values.holders[0].quantity)}
                />
              </Tooltip>
              <Tooltip title="Create Auction">
                <Button
                  type="primary"
                  title="Create Auction"
                  icon={<DollarCircleOutlined />}
                  onClick={createAuction.bind(null, values.tokenId)}
                  style={{ marginLeft: 5 }}
                />
              </Tooltip>
            </>
          );
        },
      },
    ],
    [onBurn, createAuction]
  );
  return (
    <Col span={24}>
      <Row justify="end" className="pt-1">
        <Col>
          <Link to="mint">
            <Button type="primary">Mint</Button>
          </Link>
        </Col>
      </Row>
      <Row justify="center" className="pt-1">
        <Col span={24}>
          <Table
            dataSource={collection?.tokens || []}
            columns={columns}
            loading={!collection?.id}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    </Col>
  );
};

export {};
