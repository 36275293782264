import { useCallback } from 'react';

import { useWallet } from 'use-wallet';

import env from 'lib/env';

const chainId = parseInt(env.ETHERNET_CHAIN_ID as string);

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function useCheckNetwork() {
  const wallet = useWallet();

  const checkNetwork = useCallback(async () => {
    let connected = false;

    try {
      if (chainId != wallet.chainId) {
        if (wallet.connector !== 'walletconnect') {
          await wallet.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${Number(chainId).toString(16)}` }],
          });

          await timeout(300);

          connected = true;
        }
      } else {
        connected = true;
      }
    } catch (error) {
      connected = false;
    }

    return connected;
  }, [wallet.connector, wallet.chainId, wallet.ethereum]);

  return checkNetwork;
}
