import React, { useCallback, useMemo, useState } from 'react';

import FileType from 'file-type/browser';
import mime from 'mime-types';
import ReactPlayer from 'react-player';
import { selectorFamily, useRecoilValueLoadable } from 'recoil';

import AuctionPlayer from 'components/auctionplayer/AuctionPlayer';

import styles from './Creative.module.css';
import { AuctionCreativeProps } from './Creative.types';
import { getCreativeWrapper } from './creative/Wrapper';

const creativeMime = selectorFamily({
  key: 'creativeMimeType',
  get: (url: string) => async () => {
    const type = mime.lookup(url);

    if (!type) {
      const response = await fetch(url.replace(/ipfs:\/\//i, 'https://ipfs.io/ipfs/'));
      if (response.body) {
        const fileType = await FileType.fromStream(response.body);
        return fileType?.mime;
      }
    } else {
      return type;
    }
    return null;
  },
});

export default function AuctionCreative({
  nft,
  muted = true,
  cover,
  autoplay = true,
  showControls = true,
  videoDisabled = false,
}: AuctionCreativeProps) {
  const { contents, state } = useRecoilValueLoadable<any>(creativeMime(nft.creative));
  const [playing, setPlaying] = useState(autoplay);
  const [fileType] = state === 'hasValue' && contents ? contents?.split('/') : [];

  let component = null;

  const onClick = useCallback(
    (event: any) => {
      if (fileType === 'video' && !autoplay) {
        event.stopPropagation();
        event.preventDefault();
        setPlaying(!playing);
      }
    },
    [playing, fileType]
  );

  const wrapper = useMemo(() => {
    return getCreativeWrapper({ playing, onClick });
  }, [onClick, playing]);

  if (state === 'loading') {
    return null;
  }

  if (state === 'hasValue' && contents) {
    const hasOverride = videoDisabled && nft.poster;

    if (fileType === 'image' || hasOverride) {
      component = <AuctionPlayer name={nft.name} creative={hasOverride ? nft.poster : nft.creative} cover={cover} />;
    } else {
      component = (
        <div className={styles.playerWrapper} onClick={onClick}>
          <ReactPlayer
            url={nft.creative}
            loop={true}
            playing={playing}
            muted={muted}
            playsinline
            wrapper={wrapper}
            light={!autoplay && !playing && nft.poster ? nft.poster : false}
            controls={showControls}
            width="100%"
            height="100%"
          />
        </div>
      );
    }
  } else {
    component = component = (
      <div className={styles.playerWrapper} onClick={onClick}>
        <ReactPlayer
          url={nft.creative}
          loop={true}
          playing={playing}
          muted={muted}
          wrapper={wrapper}
          playsinline
          light={!autoplay && nft.poster ? nft.poster : false}
          controls={showControls}
          width="100%"
          height="100%"
        />
      </div>
    );
  }

  return component;
}
