import { atom, selectorFamily } from 'recoil';

import apollo from 'lib/apollo/client';

import { WALLET_ACCOUNT } from './accounts.queries';

export const user = atom({
  key: 'accounts.user',
  default: null,
});

export const login = selectorFamily({
  key: 'accounts.login',
  get: (address: string | null) => async () => {
    if (address) {
      const accountId = address.toLowerCase();

      const request = await apollo.query({
        query: WALLET_ACCOUNT,
        variables: {
          accountId,
        },
      });

      let user = {
        id: accountId,
        name: '',
        avatar: null,
        organization: {
          id: null,
          name: '',
        },
        permissions: {
          monegraph: false,
          organizationAdmin: false,
          createCollections: false,
          createAuctions: false,
        },
      };

      if (request.data.seat) {
        user = {
          ...request.data.seat,
        };
      }

      return user;
    }

    return null;
  },
});

export default {
  atoms: {
    user,
  },
  selectors: {
    login,
  },
};
