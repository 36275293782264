import { selectorFamily } from 'recoil';

import apollo from 'lib/apollo/client';

import { FETCH_USER_DATA, WALLET_ACCOUNT } from './OrganizationWizard.query';

export const userOrganization = selectorFamily({
  key: 'organization.wizard',
  get: (address: string | null) => async () => {
    if (address) {
      let response: any = null;
      const accountId = address.toLowerCase();

      if (!response) {
        const request = await apollo.query({
          query: WALLET_ACCOUNT,
          variables: {
            accountId,
          },
        });

        let user = {
          id: accountId,
          name: '',
          organization: undefined,
          auctions: [],
          collections: [],
        };

        if (request.data.seat) {
          const dataRequest = await apollo.query({
            query: FETCH_USER_DATA,
            variables: {
              accountId,
            },
          });

          user = {
            ...request.data.seat,
            auctions: dataRequest.data.account.auctions,
            collections: dataRequest.data.account.collections,
          };
        }

        return user;
      }
    }

    return null;
  },
});

export default {
  atoms: {},
  selectors: {
    userOrganization,
  },
};
