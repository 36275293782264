import { Form, Input } from 'antd';

const { Item } = Form;

export default function Id() {
  return (
    <Item label="Id" required name="id" hidden>
      <Input />
    </Item>
  );
}
