import { useLayoutEffect } from 'react';

import useIsEmbedded from 'hooks/use-is-embedded';
import events from 'utils/iframe-emitter';

export function useIframeResize() {
  const isEmbedded = useIsEmbedded();
  useLayoutEffect(() => {
    if (isEmbedded) {
      events.broadcast('iframeloader.ready');

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          events.broadcast({
            name: 'iframeloader.resize',
            dimensions: { ...entry.contentRect, height: entry.target.scrollHeight },
          });
        }
      });

      resizeObserver.observe(document.getElementById('root')!);
    }
  }, [isEmbedded]);

  return null;
}
