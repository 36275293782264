import { cloneDeep } from 'lodash';
import moment from 'moment';
import { atom, selector } from 'recoil';

import apollo from 'lib/apollo/client';
import { transformAuction } from 'transforms/auction';

import { AUCTIONS, FEATURED_AUCTION } from './queries';

export const refreshAuction = atom({
  key: 'home.featured.refresh',
  default: 0,
});

export const priceInEthereum = selector({
  key: 'ethereum.price.selector',
  get: async () => {
    const priceRequest = await fetch('https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=ethereum', {
      method: 'GET',
      mode: 'cors',
      credentials: 'omit',
    });

    const data = await priceRequest.json();

    return data.ethereum.usd;
  },
});

export const featureAuctions = selector({
  key: 'home.auctions.featured', // unique ID (with respect to other atoms/selectors)
  get: async ({ get }) => {
    get(refreshAuction);

    const ethereumValue = await get(priceInEthereum);

    let response: any = null;

    if (!response) {
      const request = await apollo.query({
        query: FEATURED_AUCTION,
      });
      response = cloneDeep(request.data.auctions[0]);
    }

    response = transformAuction(response, {
      ethereumValue,
      blockTimestamp: parseInt(moment().format('X')),
    });

    return response ? response : null;
  },
});

export const auctionsSelector = selector({
  key: 'home.auctions', // unique ID (with respect to other atoms/selectors)
  get: async ({ get }) => {
    const ethereumValue = await get(priceInEthereum);

    const response = await apollo.query({
      query: AUCTIONS,
    });

    return response.data.auctions.map((auction: any) =>
      transformAuction(cloneDeep(auction), { ethereumValue, blockTimestamp: parseInt(moment().format('X')) })
    );
  },
});

export default {
  atoms: { refreshAuction },
  selectors: { priceInEthereum, featureAuctions, auctionsSelector },
};
