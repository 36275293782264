import React from 'react';

import { Form, InputNumber } from 'antd';

const { Item } = Form;

const rules = [
  {
    required: true,
  },
  {
    pattern: /^\d+$/,
    message: 'Invalid number of seats',
  },
];

export default function SeatNumber({ onChange }: any) {
  return (
    <Item label="Seats" name="seats" extra="Total number of Organization seats" required rules={rules}>
      <InputNumber onChange={onChange} type="number" style={{ width: '100%' }} />
    </Item>
  );
}
