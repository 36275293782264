import { gql } from 'apollo-boost';

import ajv from 'lib/ajv';

export const FETCH_TOKENS_BY_COLLECTIONS_KEY = 'collections.tokens.GetCollectionTokens';

ajv.compile({
  $id: FETCH_TOKENS_BY_COLLECTIONS_KEY,
  $schema: 'http://json-schema.org/draft-07/schema#',
  required: ['accountId', 'collectionId'],
  properties: {
    accountId: {
      type: 'string',
    },
    collectionId: {
      type: 'string',
    },
  },
  type: 'object',
});

export const FETCH_TOKENS_BY_COLLECTIONS = gql`
  query GetCollectionTokens($accountId: ID, $collectionId: ID, $organization: ID) @api(name: "ethereum") {
    organizationContract(id: $organization) {
      id
      auctions
    }

    collection(id: $collectionId) {
      id
      name
      symbol
      type
      address
      version
      tokens {
        id
        name
        artist
        description
        creative
        tokenId
        holders(where: { account: $accountId }) {
          id
          quantity
        }
      }
      publicMints{
        id
        name
        tokens {
          id
        }
        allowlist{
          enabled
        }
        general{
          enabled
        }
      }
    }
  }
`;
