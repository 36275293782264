import { gql } from 'apollo-boost';

import ajv from 'lib/ajv';

export const FETCH_PUBLIC_MINT_BY_ID_KEY = 'publicMint.GetPublicMint';

ajv.compile({
  $id: FETCH_PUBLIC_MINT_BY_ID_KEY,
  $schema: 'http://json-schema.org/draft-07/schema#',
  required: ['publicMintId'],
  properties: {
    publicMintId: {
      type: 'string',
    },
  },
  type: 'object',
});

export const FETCH_PUBLIC_MINT_BY_ID = gql`
  query GetPublicMint($publicMintId: ID) @api(name: "ethereum") {
    publicMint(id: $publicMintId) {
      id
      name
      beneficiaries{
        id
        wallet{
          id
        }
        percentage
      }
      recipients {
        id
        token{
          id
        }
      }
      allowlist {
        enabled
        start
        end
        quantity
        discount
        wallets {
          id
        }
      }
      general {
        enabled
        start
        end
        quantity
        discount
      }
      conditions {
        id
        type
        quantity
        discount
        payload
      }
      tokens {
        id
        tokenId
        quantity
        metadata
        attributes {
          id
          token {
            id
          }
          language
          artist
          year
          royalty
          title
        }
        cost
      }
    }
  }
`;
