import { gql } from 'apollo-boost';

export const FEATURED_AUCTION = gql`
  query GetFeaturedAuction @api(name: "ethereum") {
    auctions(first: 1, orderBy: dateStarted, orderDirection: desc) {
      id
      type
      status
      dateStarted
      dateCreated
      dateFinalized
      dateEnding
      reservePriceInETH
      version
    }
  }
`;
