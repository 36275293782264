import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { Form, message, Button, Spin } from 'antd';
import { omit, set, toNumber } from 'lodash';
import moment, { isMoment } from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import Beneficiaries from 'components/BeneficiariesInput/BeneficiariesInput';

import organizationAuction from 'contracts/organization-auction';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import useLocationQuery from 'hooks/use-location-query';
import useVerifyOrganization from 'hooks/use-verify-organization';
import { pinJSONToIPFS } from 'lib/ipfs';
import usePermissions from 'pages/admin/hooks/use-permissions';
import { AuctionType } from 'transforms/auction.types';

import { useTokenMetadata } from '../hooks/use-token-metadata';
import { drafts } from '../state/auction';
import { previewState } from '../state/preview';

import { AuctionFormProps, AUCTIONTYPES } from './Form.type';
import Asset from './create-form/Asset';
import AuctionTypeField from './create-form/AuctionType';
import ContractAddress from './create-form/ContractAddress';
import Creative from './create-form/Creative';
import Description from './create-form/Description';
import Editions from './create-form/Editions';
import EndTime from './create-form/EndTime';
import Name from './create-form/Name';
import ReservePrice from './create-form/ReservePrice';
import SaveProgress from './create-form/SaveProgress';
import StartTime from './create-form/StartTime';
import TokenID from './create-form/TokenID';

export default function AuctionForm({ form, isMobile, nextStep }: AuctionFormProps) {
  const history = useHistory();
  const wallet = useWallet();
  const [verified, context] = useVerifyOrganization();
  const { permissions } = usePermissions();
  const active = useMemo(() => wallet.status === 'connected', [wallet.status]);
  const locationQuery = useLocationQuery();
  const [disabled, setDisabled] = useState(active && !locationQuery.get('draft'));
  const [creating, setCreating] = useState(false);
  const [, updatePreview] = useRecoilState(previewState);
  const [saved, setDrafts] = useRecoilState(drafts);
  const [auctionType, setAuctionType] = useState<AuctionType>(AuctionType.TRADITIONAL);
  const values = form.getFieldsValue();
  const params = useParams<{ contract: string }>();
  const executeMethod = useExecuteMethod();

  const [, queryHash] = useTokenMetadata(wallet.account, values?.contract, values?.tokenId);

  const contract = useContract(organizationAuction, {
    address: params.contract,
  });

  const handler = useCallback(
    async (event) => {
      // @ts-ignore
      const transaction = await wallet.ethereum?.request({
        method: 'eth_getTransactionByHash',
        params: [event.transactionHash],
      });

      if (transaction.from.toLowerCase() === wallet.account?.toLowerCase()) {
        history.push(`/auction/${event.returnValues[0].toLowerCase()}/wizard`);
      }
    },
    [history, wallet.account]
  );

  useEffect(() => {
    if (contract?.address) {
      const newBeacons = contract.AuctionCreated();
      newBeacons.on('data', handler);
      return () => newBeacons.removeListener('data', handler);
    }
  }, [contract?.address, auctionType, handler]);

  const onFinish = useCallback(async () => {
    const values = form.getFieldsValue();
    const draft = locationQuery.get('draft');
    const isTraditional = auctionType === AuctionType.TRADITIONAL;

    values.address = wallet.account;
    values.quantity = values.quantity || 1;

    if (active) {
      try {
        setCreating(true);

        if (values.asset === 'external') {
          values.contract = '0x0000000000000000000000000000000000000000';
          values.tokenId = new Date().getTime().toString();
        }

        if (permissions?.auctions?.creator) {
          values.metadata = {
            address: values.contract,
            tokenId: values.tokenId,
            name: values.name,
            description: values.description,
            image: values.creative,
          };

          const metadataHash = await pinJSONToIPFS(values.metadata, {
            pinataMetadata: {
              name: `auction.${contract.address}.metadata`,
              keyvalues: {
                type: 'auction',
                organization: verified.organizationId,
                created: new Date().toISOString(),
                contact: contract.address,
                nftContract: values.contract,
                tokenId: values.tokenId,
                seller: wallet.account,
              },
            },
          });

          const signature = await context.getSignature({
            tokenAddress: values.contract,
            tokenId: values.tokenId,
            type: 'auction',
          });

          const beneficiaries = values.beneficiaries.map((beneficiary: any) => {
            return {
              ...beneficiary,
              percentage: beneficiary.percentage * 100,
            };
          });

          await executeMethod(
            contract.createAuction,
            [
              values.contract,
              values.tokenId,
              {
                metadata: metadataHash,
                initialBidAmount: web3.utils.toWei(values.reserve, 'ether'),
                startTime: isTraditional ? values.start.format('X') : 0,
                endTime: values.closeAfter24h === true ? 0 : values.closing.format('X'),
                duration: 86400,
                extensionPeriod: 900,
                quantity: values.quantity,
                percentIncrease: 10,
                maximumIncrease: web3.utils.toWei('0.1', 'ether'),
                auctionType: isTraditional ? AUCTIONTYPES.TRADITIONAL : AUCTIONTYPES.BUYNOW,
                beneficiaries,
              },
              signature,
            ],
            {
              from: wallet.account,
            }
          );

          if (draft) {
            setDrafts(omit(saved, draft));
          }

          message.success('Auction successfully created');

          form.resetFields();
        } else {
          message.error('This wallet does not have permission to create auctions');
        }
      } catch (e) {
        console.error(e);
        message.error('There was an error trying to create your auction.');
      } finally {
        setCreating(false);
      }
    }
  }, [
    auctionType,
    form,
    active,
    wallet.account,
    permissions,
    contract?.address,
    locationQuery,
    verified.organizationId,
  ]);

  const onChangeFields = useCallback(async () => {
    const reserve = form.getFieldValue('reserve');
    setAuctionType(form.getFieldValue('auctionType'));

    setDisabled(
      form.getFieldsError().some((item) => {
        return item.name[0] !== 'beneficiaries' && item.errors.length > 0;
      })
    );

    const closing = form.getFieldValue('closing');
    const starting = form.getFieldValue('start');
    const closingUTC = isMoment(closing) ? moment.utc(closing).format('MMMM Do YYYY, h:mm a') : '';
    const previewData = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      creative: form.getFieldValue('creative'),
      closing: closing && closing === true ? '0' : closingUTC,
      starting: starting ? moment.utc(starting).format('MMMM Do YYYY, h:mm a') : '',
      reservePrice: toNumber(reserve) > 0 ? reserve : '0',
    };

    updatePreview(previewData);

    const draftName = locationQuery.get('draft') as string;

    if (draftName) {
      const values = form.getFieldsValue();

      setDrafts({
        ...saved,
        [draftName]: values,
      });
    }
  }, [form, updatePreview, locationQuery, saved]);

  const validateBeforeSwitch = useCallback(async () => {
    try {
      await form.validateFields();
      nextStep?.();
    } catch (e) {}
  }, [form]);

  const initialValues = useMemo(() => {
    const draftName = locationQuery.get('draft') as string;
    const hasDraft = draftName && saved[draftName];
    const draft = hasDraft
      ? {
          ...saved[draftName],
        }
      : null;

    if (hasDraft) {
      if (draft?.start) {
        set(draft, 'start', moment(draft?.start));
      }

      if (draft?.closing) {
        if (!draft.closeAfter24h) {
          set(draft, 'closing', moment(draft?.closing));
        } else {
          set(draft, 'closing', true);
        }
      }
    }

    return draft
      ? {
          ...(draft as object),
        }
      : {
          start: moment(),
          contract: locationQuery.get('address'),
          tokenId: locationQuery.get('tokenId'),
        };
  }, [locationQuery]);

  return (
    <Spin spinning={creating}>
      <Form
        id="createAuction"
        form={form}
        initialValues={initialValues}
        layout="vertical"
        size="large"
        onFieldsChange={onChangeFields}
        onFinish={onFinish}>
        <AuctionTypeField />
        <Asset form={form} />
        <ContractAddress form={form} />
        <TokenID form={form} />
        <Name hash={queryHash} form={form} />
        <Description hash={queryHash} form={form} />
        <Creative hash={queryHash} form={form} />
        <Editions hash={queryHash} form={form} />
        <ReservePrice form={form} />
        <StartTime auctionType={auctionType} />
        <EndTime auctionType={auctionType} form={form} />
        <Beneficiaries percentage={5} />
        <Form.Item label="">
          {isMobile ? (
            <Button type="primary" className="w-100 btn-primary" disabled={disabled} onClick={validateBeforeSwitch}>
              Show Preview
            </Button>
          ) : (
            <Button type="primary" className="w-100 btn-primary" htmlType="submit" disabled={disabled}>
              Deploy Auction
            </Button>
          )}
        </Form.Item>
        <SaveProgress form={form} />
      </Form>
    </Spin>
  );
}
