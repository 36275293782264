import { ReactNode } from 'react';

import { Menu } from 'antd';

const { SubMenu } = Menu;

interface SubMenuItemProps {
  title: string;
  icon: ReactNode;
  permission?: boolean;
  children: ReactNode;
}

export default function SubMenuItem({ permission, ...props }: SubMenuItemProps) {
  if (permission === undefined || permission) {
    return <SubMenu {...props} />;
  }

  return null;
}
