import React from 'react';

import { Form, Input } from 'antd';

const { Item } = Form;

export default function ArchiveSHA({ form }: any) {
  const rules = [
    {
      async validator(_: any, value: string) {
        if (value?.length) {
          if (value.length < 64) {
            throw new Error('Invalid sha256 hash, hash should be 64 characters in length');
          }
        }
      },
    },
  ];

  return (
    <Item
      label="Archive Link SHA256 checksum"
      rules={rules}
      name="archiveSHA"
      validateTrigger="onBlur"
      validateFirst={true}
      extra="An sha256 hash of the artwork">
      <Input />
    </Item>
  );
}
