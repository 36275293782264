import React from 'react';

import { Form, Checkbox, FormInstance } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Moment } from 'moment';

import { AuctionType } from 'transforms/auction.types';

import DateTimeInput from './EndTimeInput';

const { Item } = Form;

interface EndTimeProps {
  form: FormInstance<any>;
  auctionType: AuctionType;
}

export default function EndTime({ form, auctionType }: EndTimeProps) {
  const rules = [
    ({ getFieldValue }: any) => ({
      message: 'Please set the closing date',
      validator: (_: any, value: Boolean | Moment) =>
        !getFieldValue('closeAfter24h') && !getFieldValue('closing') ? Promise.reject() : Promise.resolve(),
    }),
  ];

  const onCheckboxPres = (event: CheckboxChangeEvent) => {
    form.setFieldsValue({
      closeAfter24h: event.target.checked,
      closing: event.target.checked ? event.target.checked : undefined,
    });
  };

  return (
    <>
      <Item
        label="Closing Date & Time"
        name="closing"
        extra="Date and time when the auction should close"
        required
        rules={rules}
        dependencies={['closeAfter24h', 'auctionType']}>
        <DateTimeInput format="YYYY-MM-DD HH:mm" />
      </Item>
      {auctionType === AuctionType.TRADITIONAL ? (
        <Item name="closeAfter24h" valuePropName="checked" initialValue={false}>
          <Checkbox onChange={onCheckboxPres}>End 24 hours after first bid</Checkbox>
        </Item>
      ) : (
        <Item name="closeAfter24h" valuePropName="checked" initialValue={false}>
          <Checkbox onChange={onCheckboxPres}>End after all items have been sold</Checkbox>
        </Item>
      )}
    </>
  );
}
