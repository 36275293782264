import React, { useMemo } from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import Address from './Address';
import Percentage from './Percentage';

export default function Beneficiary({ field, remove, monegraphPercentage }: any) {
  const minus = useMemo(() => {
    return field.key === 0 ? null : <MinusCircleOutlined onClick={() => remove(field.name)} />;
  }, [field.key, field.name, remove]);

  return (
    <Row key={field.key} gutter={16} wrap={false}>
      <Col span={16}>
        <Address field={field} />
      </Col>
      <Col span={6}>
        <Percentage field={field} monegraphPercentage={monegraphPercentage} />
      </Col>
      <Col span={2} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        {minus}
      </Col>
    </Row>
  );
}
