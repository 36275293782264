import { useEffect, useMemo, useState } from 'react';

import { Col, Divider, Row, Typography } from 'antd';
import moment from 'moment';

import { AuctionStatus } from 'transforms/auction.types';
import { getHoursMinutesSeconds } from 'utils/time';

import styles from './Countdown.module.css';
import CountdownTimer from './countdown/Timer';

export default function Countdown({ auction }: any) {
  const timer = auction.status === AuctionStatus.UPCOMING ? auction.dateStarted : auction.dateEnding;
  const [time, setTime] = useState(getHoursMinutesSeconds(timer));

  const left = time[4];

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getHoursMinutesSeconds(timer));
    }, 1000);

    return () => clearInterval(interval as NodeJS.Timeout);
  }, [timer, auction.status, left]);

  return useMemo(() => {
    let result = null;

    if (auction.status === AuctionStatus.UPCOMING) {
      result = (
        <>
          <Col span={1} xs={0} md={1} className="auction-date-ending-divider">
            <Divider type="vertical" className="h-100" />
          </Col>
          <CountdownTimer time={time} status={auction.status} />
        </>
      );
    } else if (auction.dateEnding != '0') {
      if (auction.status === AuctionStatus.OPEN && left > 30) {
        result = (
          <>
            <Col span={1} xs={0} md={1} className="auction-date-ending-divider">
              <Divider type="vertical" className="h-100" />
            </Col>
            <CountdownTimer time={time} status={auction.status} />
          </>
        );
      } else if ([AuctionStatus.FINALIZED, AuctionStatus.ENDED].indexOf(auction.status) !== -1) {
        result = (
          <Col xs={24} md={15} className="auction-date-ending">
            <Row>
              <Typography.Title level={5} className="pt-1 auction-date-ending-label">
                Auction ended
              </Typography.Title>
            </Row>
            <Row>
              <Typography.Title level={5} className="pt-1 auction-date-ending-label">
                {moment(auction.dateEnding, 'X').format('MMM Do YYYY, h:mm:ss a')}
              </Typography.Title>
            </Row>
          </Col>
        );
      } else if (left < 30) {
        result = (
          <Col xs={24} md={16} className={`${styles.closingText} auction-closing`}>
            <Typography.Title level={3} className={`pt-1 auction-date-closing-label ${styles.closing}`}>
              Auction closing
            </Typography.Title>
          </Col>
        );
      }
    }

    return result;
  }, [auction.status, auction.dateEnding, left]);
}
