import { ColumnsState } from '@ant-design/pro-table';

export const defaultColumns: Record<string, ColumnsState> = {
  tokenId: { show: true, disable: true },
  title: { show: true, disable: true },
  description: { show: true, disable: true },
  creative: { show: true, disable: true },
  artist: { show: true, disable: true },
  year: { show: true, disable: true },
  cost: { show: true, disable: true },
  royaltyAddress: { show: true, disable: true },
  royalty: { show: true, disable: true },
  archive: { show: false, disable: false },
  archiveSHA: { show: false, disable: false },
  externalUrl: { show: false, disable: false },
  animationUrl: { show: false, disable: false },
};
