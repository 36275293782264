import React from 'react';

import { Typography } from 'antd';

import AdminLayout from 'layout/AdminLayout';

export default function HomePage() {
  return (
    <AdminLayout>
      <Typography.Text type="secondary">Monegraph Dashboard</Typography.Text>
    </AdminLayout>
  );
}
