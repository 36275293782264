import React from 'react';

import { Form, Input } from 'antd';
import { parseInt } from 'lodash';

import styles from './Royalty.module.css';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please set the royalty percentage',
  },
  {
    async validator(field: any, value: string) {
      const num = parseInt(value);

      if (isNaN(num) || num < 0 || num > 100) {
        throw new Error(`Royalty of ${num}% is not between 0% and 100%`);
      }
    },
  },
];

export default function Royalty() {
  return (
    <Item
      label="Royalty Percentage"
      rules={rules}
      name="royalty"
      extra="The stated resale royalty percentage owed to you upon resale"
      validateFirst={true}
      normalize={(value) => {
        return value.replace(/[^\d]+/g, '');
      }}>
      <Input className={styles.input} />
    </Item>
  );
}
