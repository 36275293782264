import { useCallback, useRef } from 'react';

import { get } from 'lodash';
import Web3 from 'web3';

import env from 'lib/env';
import connectors from 'lib/wallet-connect/connectors';

const web3 = new Web3(new Web3.providers.HttpProvider(get(connectors, env.ETHERNET_CHAIN_ID, '')));

export default function useBlockTimestamp() {
  const last = useRef({
    timestamp: 0,
    block: 0,
  });

  return useCallback(
    async (block: number) => {
      if (last.current.block < block) {
        const record = await web3.eth.getBlock(block);

        last.current.timestamp = parseInt(record.timestamp.toString());
        last.current.block = block;
      }

      return last.current.timestamp;
    },
    [last.current.block]
  );
}
