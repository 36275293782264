import { Col, Divider, Form, Row, Skeleton, Typography } from 'antd';

import AuctionCreative from 'components/auction/Creative';

import styles from './Preview.module.css';

export default function Preview({ form }: any) {
  return (
    <Form form={form}>
      <Form.Item dependencies={['creative', 'title', 'description']}>
        {({ getFieldValue }) => (
          <>
            <Row justify="center">
              <Col span={24} className={styles.creativeContainer}>
                {getFieldValue('creative') ? (
                  <AuctionCreative
                    nft={{ name: getFieldValue('title'), creative: URL.createObjectURL(getFieldValue('creative')) }}
                  />
                ) : (
                  <Skeleton.Image className={styles.imageSkeleton} />
                )}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                {getFieldValue('title') ? (
                  <Typography.Text className={styles.textSkeleton}>Title: {getFieldValue('title')}</Typography.Text>
                ) : (
                  <Skeleton active={false} title={{ width: 75 }} paragraph={false} />
                )}
              </Col>
              <Col span={24}>
                {getFieldValue('description') ? (
                  <Typography.Text className={styles.textSkeleton}>
                    Description: {getFieldValue('description')}
                  </Typography.Text>
                ) : (
                  <Skeleton active={false} title={false} paragraph={{ rows: 3 }} />
                )}
              </Col>
            </Row>
          </>
        )}
      </Form.Item>
    </Form>
  );
}
