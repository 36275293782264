import { gql } from 'apollo-boost';

export const FETCH_USERS = gql`
  query GetUsers($sellerId: ID) @api(name: "polygon") {
    seats {
      id
      name
      permissions {
        monegraph
      }
    }
  }
`;
