import React from 'react';

import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Button } from 'antd';

import LogoShort from 'assets/monegraph-letter.png';

import styles from './Header.module.css';
import { HeaderProps } from './Header.types';

export default function AdminHeader({ isMobile, onShowDrawer }: HeaderProps) {
  return (
    <Layout.Header className={styles.header}>
      <Row className="h-100">
        {isMobile ? (
          <>
            <Col flex={0} className={styles.logoContainer}>
              <img className={styles.logo} src={LogoShort} alt="M" />
            </Col>
            <Col>
              <Button icon={<MenuUnfoldOutlined />} onClick={onShowDrawer} type="text" />
            </Col>
          </>
        ) : (
          <Col flex={1}></Col>
        )}
      </Row>
    </Layout.Header>
  );
}
