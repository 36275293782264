export function shaFromIPFS(link: string) {
  const uri = new URL(link);

  return uri.pathname.replace('//', '').split('/')[0];
}

export default function hashURLTransform(value: string | File) {
  let uri: URL | null = null;

  if (typeof value == 'string' && value?.length) {
    try {
      uri = new URL(value);

      if (uri.protocol === 'ipfs:') {
        uri = new URL(`https://monegraph.mypinata.cloud/ipfs/${shaFromIPFS(value)}`);
      }
    } catch (e) {
      uri = new URL(`https://monegraph.mypinata.cloud/ipfs/${value}`);
    }
  } else {
    return value;
  }

  return uri ? uri.toString() : '';
}
