import { useCallback, useMemo, useState } from 'react';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

export function AvatarInput({ onChange, onBlur, value, id, label, accept }: any) {
  const [loading] = useState(false);

  const beforeUpload = useCallback(
    (file) => {
      onChange?.(file);
      onBlur?.();
      return false;
    },
    [onChange, onBlur]
  );

  const imageUrl = useMemo(() => {
    return value instanceof File ? URL.createObjectURL(value) : undefined;
  }, [value]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload accept={accept} beforeUpload={beforeUpload} listType="picture-card" showUploadList={false}>
      {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
    </Upload>
  );
}
