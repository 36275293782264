// key represets magic word to be replaced in the contract language
export const contractVariable = [
  { key: '$ARTIST', label: 'Artist', fieldName: 'artist' },
  { key: '$SHAHASH', label: 'SHA HASH', fieldName: 'archiveSHA' },
  { key: '$URL', label: 'Url', fieldName: 'creative' },
  { key: '$TITLE', label: 'Title', fieldName: 'title' },
  { key: '$YEAR', label: 'Year', fieldName: 'year' },
  { key: '$ROYALTYRATE', label: 'Royalty Percentage', fieldName: 'royalty' },
  { key: '$ADDRESS', label: 'Royalty Address', fieldName: 'royaltyAddress' },
  { key: '$EDITIONS', label: 'Editions', fieldName: 'quantity' },
];

export function extractVariableValue(formValue: any) {
  return contractVariable.reduce((prev, { key, fieldName }) => ({ ...prev, [key]: formValue[fieldName] }), {});
}

export function replaceContractVariables(values: any) {
  if (values.language) {
    return Object.entries(values.contractVariables)
      .filter(([_, value]) => value) //Don't replce keys without value
      .reduce((prev, [key, value]) => {
        return prev.replace(new RegExp(`\\${key}\\b`, 'g'), value);
      }, values.language);
  }

  return values.language;
}
