import { gql } from 'apollo-boost';

export const FETCH_COLLECTIONS = gql`
  query GetCollections @api(name: "ethereum") {
    collections {
      id
      name
      symbol
      address
    }
  }
`;
