import React, { useCallback, useMemo, useState } from 'react';

import { Form, Col, Divider, Input, Row, Typography, Alert } from 'antd';
import { get } from 'lodash';
import web3 from 'web3';

import BuyButton from 'components/buybutton/BuyButton';

import { AuctionStatus, AuctionType } from 'transforms/auction.types';
import { formatNumber } from 'utils/numberFormatter';

import styles from './AuctionButton.module.css';
import { AuctionButtonProps } from './AuctionButton.types';
import Countdown from './components/Countdown';

const { Item } = Form;

export function AuctionButton({ auction }: AuctionButtonProps) {
  const minimumBid = useMemo(() => {
    return web3.utils.fromWei(auction.minimumBid.toString(), 'ether');
  }, [auction.minimumBid]);

  const [bid, setBid] = useState(auction.minimumBid);
  const [error, setError] = useState<string>('');

  const onChange = useCallback(
    (event) => {
      let next = (event.target.value || '').replace(/[^0-9.]/g, '');

      if (next.length) {
        next = web3.utils.toWei(next, 'ether');
      }

      if (auction.minimumBid.gt(next)) {
        setError(`Minimum bid amount is ${web3.utils.fromWei(auction.minimumBid.toString(), 'ether')} ETH`);
      } else {
        setError('');
      }

      setBid(next);
    },
    [setBid, auction.minimumBid]
  );

  const BidInput = useMemo(() => {
    return auction.type !== AuctionType.TRADITIONAL ||
      get(auction, 'status', AuctionStatus.OPEN) !== AuctionStatus.OPEN ? null : (
      <Row className="px-3 auction-button">
        <Col span={24}>
          <Item help={error ? error : void 0} validateStatus={error ? 'error' : void 0}>
            <Input placeholder={minimumBid} addonAfter="ETH" onChange={onChange} />
          </Item>
        </Col>
      </Row>
    );
  }, [auction, auction?.type, auction?.status, minimumBid, error, onChange]);

  const errorMessage = useMemo(() => {
    return auction.type !== AuctionType.TRADITIONAL && error ? (
      <Alert className={`${styles.errorMessageAlert} auction-error-message`} message={error} type="error" />
    ) : null;
  }, [auction?.type, error]);

  return (
    <Row className={`${styles.container} auction-button-container`}>
      <Col span={24}>
        <Row className="px-3 pt-3 auction-button-inner">
          <Col span={8}>
            <Typography.Title level={5} className="m-0 pt-1 auction-button-price-label">
              {auction.type === AuctionType.TRADITIONAL
                ? auction?.bids?.length
                  ? 'Current Bid'
                  : 'Starting Bid'
                : 'Price'}
            </Typography.Title>
            <Typography.Title level={3} className="m-0 auction-button-price">
              {formatNumber(auction.currentBidInETH)} ETH
            </Typography.Title>
            <Typography.Title level={5} className="m-0 auction-button-price-usd" type="secondary">
              ${formatNumber(auction.currentBidInUSD)} USD
            </Typography.Title>
          </Col>
          <Countdown auction={auction} />
        </Row>
        <Divider />
        {BidInput}
        {errorMessage}
        <Row className="px-3 auction-buy-button-container">
          <Col span={24}>
            <BuyButton auction={auction} bid={bid} setError={setError} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
