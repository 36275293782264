import React from 'react';

import { Form, Input } from 'antd';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';

const { Item } = Form;
const { TextArea } = Input;

export function transform(value: string = '') {
  return value
    .trim()
    .split('\n')
    .map((str) => str.trim())
    .filter((str) => str.length !== 0);
}

const rules = [
  {
    transform(value: string = '') {
      return transform(value);
    },

    async validator(field: any, addresses: string[] = []) {
      addresses.forEach((address: string) => {
        addressCheck.toChecksumAddress(address);
      });
    },
  },
];

export default function Minters() {
  return (
    <Item
      label="Approve Minters"
      rules={rules}
      name="minters"
      extra="Add registered minters to the collection. Enter one address per line">
      <TextArea autoSize />
    </Item>
  );
}
