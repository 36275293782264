import { Key } from 'antd/lib/table/interface';
import { atom } from 'recoil';

export const dataSourceAtom = atom<any[]>({
  key: 'bulkMint.datasouce',
  default: [],
});

export const editableKeysAtom = atom<Key[]>({
  key: 'bulkMint.editableKeys',
  default: [],
});

export const errorsAtom = atom<Record<string, any>>({
  key: 'bulkMint.errors',
  default: {
    global: [],
  },
});
