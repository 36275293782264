import { TableColumnType } from 'antd';

import DeleteButton from './DeleteButton';

const columns: Array<TableColumnType<any>> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Wallet',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    key: 'organization',
    render: (organization: any) => organization?.name,
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'actions',
    render: (id: any, record: any) => <DeleteButton record={record} />,
  },
];

export default columns;
