import { useEffect, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import useIsEmbedded from 'hooks/use-is-embedded';
import { iframeConfigState } from 'iframeconfig.state';
import events from 'utils/iframe-emitter';

export function useInitApp() {
  const isEmbedded = useIsEmbedded();
  const [isReady, setIsReady] = useState(!isEmbedded);
  const setConfig = useSetRecoilState(iframeConfigState);

  useEffect(() => {
    if (isEmbedded) {
      events.broadcast('iframeloader.ready');

      events.on('configuration', (values) => {
        if (values.primaryColor) {
          values = {
            theme: {
              global: {
                ...values,
              },
            },
          };
        }

        if (values?.gallery?.video === false) {
          values.gallery.video = {
            disabled: true,
          };
        }

        setConfig((oldValues) => ({ ...oldValues, ...values }));

        if (!isReady) setIsReady(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmbedded]);

  return isReady;
}
