import { gql } from 'apollo-boost';

export const FETCH_ORGANIZATION = gql`
  query GetOrganization($id: String) @api(name: "polygon") {
    organizations(where: { id: $id }) {
      id
      seats
      name
      accounts {
        id
        avatar
        name
        description
        permissions {
          organizationAdmin
        }
      }
    }
  }
`;
