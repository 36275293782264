import { Form, FormInstance, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import OrganizationAdmin from './organizationForm/OrganizationAdmin';
import SeatsNumber from './organizationForm/SeatsNumber';

const { Item } = Form;

interface OrganizationFormProps {
  organizationId: String | null;
  form?: FormInstance<any>;
  onFinish?: (values: any) => void;
  initialValues: any;
}

export default function OrganizationForm({ organizationId, form, initialValues, onFinish }: OrganizationFormProps) {
  const [internalForm] = useForm(form);
  return (
    <Form
      size="large"
      layout="vertical"
      id="organizationForm"
      name="organizationForm"
      form={internalForm}
      initialValues={initialValues}
      onFinish={onFinish}>
      <Item label="Name" name="name" extra="Organization name" required>
        <Input />
      </Item>

      <SeatsNumber />
      {organizationId ? null : <OrganizationAdmin />}
    </Form>
  );
}
