export enum ConditionsTypes { 
    NftsOwns = "nft.owns"
}

export interface Condition {
  type: ConditionsTypes;
  quantity: number;
  cost: number;
  payload: OwnTokenPayload;
}


export interface OwnTokenPayload {
    network: string
    contractAddress: string
    tokenId: string
}