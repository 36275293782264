import React, { useMemo } from 'react';

import { Form, Input, Typography } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';

import styles from './Address.module.css';

const { Item } = Form;
const { Text } = Typography;

const rules = [
  {
    required: true,
    message: 'Please set the beneficiary address',
  },
  {
    async validator(field: any, address: string) {
      addressCheck.toChecksumAddress(address);
    },
  },
];

export default function Address({ field }: { field: FormListFieldData }) {
  const isMonegraph = field.key === 0;

  const input = useMemo(() => {
    return isMonegraph ? (
      <Text strong className={styles.text}>
        Monegraph
      </Text>
    ) : (
      <Input className={styles.input} />
    );
  }, [isMonegraph]);

  return (
    <Item
      label="Wallet Address"
      rules={rules}
      name={[field.name, 'wallet']}
      extra={isMonegraph ? null : 'The ethereum wallet address that will receive eth on auction finalization'}
      validateTrigger="onBlur">
      {input}
    </Item>
  );
}
