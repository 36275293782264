import React from 'react';

import { Col, Divider, Layout, Row, Typography } from 'antd';
import { size } from 'lodash';

import AuctionCard from 'components/auctioncard/AuctionCard';

import styles from './AuctionGrid.module.css';

export default function AuctionGrid({ auctions }: any) {
  return size(auctions) > 0 ? (
    <Layout className="p-2 container-xxl">
      <Row>
        <Col span={24}>
          <Typography.Title level={3} className="m-0">
            Available
          </Typography.Title>
          <Divider className="my-1 border-secondary" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={styles.gridContainer}>
            {auctions.map((auction: any) => (
              <AuctionCard key={auction.id} auction={auction} />
            ))}
          </div>
        </Col>
      </Row>
    </Layout>
  ) : null;
}
