import React from 'react';

import { Helmet } from 'react-helmet';
import { useRecoilValue } from 'recoil';
import { createGlobalStyle } from 'styled-components';

import useIsEmbedded from 'hooks/use-is-embedded';
import { iframeConfigState } from 'iframeconfig.state';

const GlobalStyle = createGlobalStyle`
  :root {
      --global-color-primary: ${(props: any) => props.theme.global.primaryColor};
      --global-color-secondary: ${(props: any) => props.theme.global.secondaryColor};
      --global-color-bg: ${(props: any) => props.theme.global.backgroundColor};
  }

  ${(props) =>
    props.isEmbedded
      ? `
  html {
    overflow: hidden;
  }
  `
      : ''}
`;

export function GlobalStyles() {
  const config = useRecoilValue(iframeConfigState);
  const isEmbedded = useIsEmbedded();
  const styleSheet = config.theme.stylesheet;

  return (
    <>
      <GlobalStyle {...config} isEmbedded={isEmbedded} />
      <Helmet>{styleSheet ? <link rel="stylesheet" href={styleSheet} /> : null}</Helmet>
    </>
  );
}
