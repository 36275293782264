import { useCallback, useState } from 'react';

import { PlusOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Table, Empty, Row, Col, Upload, message, Spin } from 'antd';
import { parse } from 'csv/dist/esm/sync';

import AddressInput from 'components/AddressInput/AddressInput';

const { List } = Form;
const { Column } = Table;

interface WalletsProps {
  name: string | string[];
}

export default function Wallets({ name }: WalletsProps) {
  const [loading, setLoading] = useState(false);
  const sleep = useCallback(
    (seconds: number) =>
      new Promise((resolve) => {
        setTimeout(resolve, seconds * 1000);
      }),
    []
  );

  const importer = useCallback(
    async (file: any, add: any, fields: any) => {
      var reader = new FileReader();

      setLoading(true);

      reader.onload = async () => {
        if (typeof reader.result === 'string') {
          try {
            const output = parse(reader.result, {
              columns(header) {
                return header.map((column: string) => column.toLowerCase());
              },
            });

            for (let i = 0; i < output.length; i++) {
              const item = output[i];

              if (item.wallet) {
                add(item.wallet.trim());
              }

              if (i % 20 === 0) {
                await sleep(0.1);
              }
            }
          } catch (e: any) {
            message.error(e.message);
          } finally {
            setLoading(false);
          }
        }
      };

      reader.readAsText(file.file.originFileObj);
    },
    [setLoading]
  );

  return (
    <Spin spinning={loading} size="large">
      <List
        name={name}
        rules={[
          {
            async validator(_field: any, wallets: Array<any>) {
              if (!wallets || wallets.length <= 0) {
                throw Error('At least one wallet is required');
              }
            },
          },
        ]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            <Table
              dataSource={fields}
              size="small"
              pagination={{ position: ['bottomCenter'] }}
              locale={{
                emptyText: <Empty description="No Wallets" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
              }}
              footer={() => {
                return [
                  <Button key="add" type="link" onClick={() => add()} icon={<PlusOutlined />}>
                    Add Wallet
                  </Button>,
                  <Upload
                    onChange={(file) => importer(file, add, fields)}
                    key="upload"
                    accept={'text/csv'}
                    itemRender={() => null}
                    customRequest={() => null}>
                    <Button icon={<UploadOutlined />} type="primary">
                      Import
                    </Button>
                  </Upload>,
                ];
              }}>
              <Column
                title="Wallet Address"
                render={function (field, _record, index) {
                  return (
                    <Row gutter={[16, 0]}>
                      <Col flex={1}>
                        <AddressInput name={field.name} style={{ margin: 0 }} />
                      </Col>
                      <Col>
                        <Button
                          type="dashed"
                          shape="circle"
                          danger
                          onClick={() => remove(index)}
                          icon={<DeleteOutlined />}
                        />
                      </Col>
                    </Row>
                  );
                }}
              />
            </Table>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </List>
    </Spin>
  );
}
