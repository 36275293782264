import { useCallback } from 'react';

import { ClearOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useRecoilState } from 'recoil';

import { dataSourceAtom, editableKeysAtom } from '../state';

export default function ClearButton() {
  const [dataSource, setDataSource] = useRecoilState(dataSourceAtom);
  const [editableKeys, setEditableKeys] = useRecoilState<any[]>(editableKeysAtom);

  const onClick = useCallback(() => {
    Modal.confirm({
      title: 'Clear NFTS',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete all rows?',
      okText: 'Clear',
      cancelText: 'cancel',
      onOk() {
        setDataSource([]);
        setEditableKeys([]);
      },
    });
  }, [dataSource, editableKeys]);

  return dataSource.length ? (
    <Button icon={<ClearOutlined />} size="small" shape="circle" type="primary" onClick={onClick}></Button>
  ) : null;
}
