import { List, Button, Typography, Avatar, Row, Col } from 'antd';
import { get } from 'lodash';

export default function Seat({ onEdit, onRemove, seat }: any) {
  return (
    <List.Item
      actions={[
        <Button type="link" size="small" onClick={onEdit}>
          Edit
        </Button>,
        <Button type="link" size="small" danger onClick={onRemove}>
          Remove
        </Button>,
      ]}>
      <Row gutter={5} align="middle">
        <Col flex={0}>
          <Avatar src={get(seat, 'avatar')} size={60} />
        </Col>
        <Col flex={1}>
          <Typography.Title ellipsis level={5}>
            {`${get(seat, 'seatName')} `}

            {get(seat, 'isAdmin') ? (
              <Typography.Text type="secondary" strong>
                (Admin)
              </Typography.Text>
            ) : null}
          </Typography.Title>
          <Typography.Paragraph ellipsis>{get(seat, 'seatWallet')}</Typography.Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Text>{get(seat, 'description')}</Typography.Text>
        </Col>
      </Row>
    </List.Item>
  );
}
