import { gql } from 'apollo-boost';

export const FETCH_USER_DATA = gql`
  query GetUserData($accountId: ID) @api(name: "ethereum") {
    account(id: $accountId) {
      collections {
        id
        name
        symbol
        address
      }

      auctions {
        nft {
          artist
          name
        }
      }
    }
  }
`;

export const WALLET_ACCOUNT = gql`
  query GetAccount($accountId: String) @api(name: "polygon") {
    seat(id: $accountId) {
      id
      name
      organization {
        id
        name
      }
    }
  }
`;
