import { useCallback } from 'react';

import { useWallet } from 'use-wallet';

import MonegraphERC1155 from 'contracts/monegraph-erc1155';
import MonegraphERC721 from 'contracts/monegraph-erc721';
import { getContract } from 'hooks/use-contract';
import { AnalyticService, events } from 'lib/analytic';

import useExecuteMethod from './use-execute-method';

export default function useTransferToken() {
  const wallet = useWallet();
  const executeMethod = useExecuteMethod();

  const canTransfer = useCallback(
    async (address: string, token: string) => {
      let possible = false;

      try {
        const erc721 = await getContract(wallet?.ethereum, MonegraphERC721, address);
        const erc1155 = await getContract(wallet?.ethereum, MonegraphERC1155, address);

        const [is721, is1155] = await Promise.all([
          erc721.supportsInterface('0x80ac58cd'),
          erc1155.supportsInterface('0xd9b67a26'),
        ]);

        if (is721) {
          const owner = await erc721.ownerOf(token);

          if (owner.toLowerCase() === wallet.account?.toLowerCase()) {
            possible = true;
          }
        } else if (is1155) {
          const count = await erc1155.balanceOf(wallet.account, token);

          if (count.toNumber() > 0) {
            possible = true;
          }
        }
      } catch (e) {}

      return possible;
    },
    [wallet.account]
  );

  const transfer = useCallback(
    async (address: string, token: string, to: string, quantity: number = 1) => {
      const erc721 = await getContract(wallet?.ethereum, MonegraphERC721, address);
      const erc1155 = await getContract(wallet?.ethereum, MonegraphERC1155, address);

      const [is721, is1155] = await Promise.all([
        erc721.supportsInterface('0x80ac58cd'),
        erc1155.supportsInterface('0xd9b67a26'),
      ]);

      if (is721) {
        const owner = await erc721.ownerOf(token);

        if (owner.toLowerCase() === wallet.account?.toLowerCase()) {
          await executeMethod(erc721.safeTransferFrom, [wallet.account, to, token], {
            from: wallet.account,
          });
        } else {
          throw new Error(`${wallet.account} does not current own the specified NFT`);
        }
      } else if (is1155) {
        const count = await erc1155.balanceOf(wallet.account, token);

        if (count.toNumber() > 0) {
          await executeMethod(erc1155.safeTransferFrom, [wallet.account, to, token, quantity, '0x'], {
            from: wallet.account,
          });
        } else {
          throw new Error(`${wallet.account} does not current own the specified NFT`);
        }
      } else {
        throw new Error('Contract does not support erc721 or erc1155 standards');
      }

      AnalyticService.track(events.transfer, { address, token, to });
    },
    [wallet.account]
  );

  return {
    transfer,
    canTransfer,
  };
}
