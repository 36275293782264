import { useMemo, useState } from 'react';

import { Row, Col, Input, Skeleton, Card, Layout as AntdLayout, Button, Form, Checkbox, Typography, Tabs } from 'antd';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import UrlInput from 'components/urlinput/UrlInput';

import useIsEmbedded from 'hooks/use-is-embedded';
import { iframeConfigState } from 'iframeconfig.state';
import { Config } from 'iframeconfig.types';
import AdminLayout from 'layout/AdminLayout';
import env from 'lib/env';
import { login } from 'state/accounts';
import events from 'utils/iframe-emitter';

import PageViewer from '../../../components/pageviewer/PageViewer';

import { featureAuctions } from './state';

export default function AuctionWizard() {
  const params = useParams();
  const auctionIdParam = get(params, 'id');
  const wallet = useWallet();
  const embedded = useIsEmbedded();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { contents: user, state: userState } = useRecoilValueLoadable(login(wallet.account));
  const { state, contents: featureAuctionId } = useRecoilValueLoadable<any>(featureAuctions);

  const iframeConfig = useRecoilValue(iframeConfigState);
  const [isLoading, setIsLoading] = useState(true);

  const [updateConfig, setUpdateConfig] = useState<Config>(iframeConfig);

  const [form] = Form.useForm<Config>();

  const auctionId = useMemo(() => {
    if (auctionIdParam) {
      return auctionIdParam;
    }

    if (state === 'hasValue') {
      return featureAuctionId;
    }
  }, [auctionIdParam, state, featureAuctionId]);

  const onSave = () => {
    events.broadcast({
      name: 'monegraph.save',
      styles: {
        primaryColor: updateConfig.theme.global.primaryColor,
        secondaryColor: updateConfig.theme.global.secondaryColor,
        backgroundColor: updateConfig.theme.global.backgroundColor,
      },
      organizationId: '',
    });
  };

  const Layout = embedded ? AntdLayout : AdminLayout;

  const auctionLoader = (
    <>
      <Row justify="center">
        <Col>
          <Skeleton.Avatar size={300} shape="square" active />
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={12}>
          <Skeleton active />
        </Col>
        <Col xs={12}>
          <Skeleton active />
        </Col>
      </Row>
    </>
  );

  const handleFormValuesChange = (_: any, values: Config) => {
    let newValues = values;
    if (values.gallery?.filters?.type !== updateConfig.gallery?.filters?.type) {
      newValues = {
        ...values,
        gallery: {
          ...values.gallery,
          filters: { ...values.gallery?.filters, include: undefined, exclude: undefined },
        },
      };
    }
    if (values.gallery?.groupBy !== updateConfig.gallery?.groupBy) {
      newValues = { ...values, gallery: { ...values.gallery, orderBy: undefined } };
    }

    form.setFieldsValue(newValues);
    setUpdateConfig(newValues);
  };

  return (
    <Layout>
      <Form form={form} layout="vertical" initialValues={iframeConfig} onValuesChange={handleFormValuesChange}>
        <Card className="mb-3">
          <Row justify="center">
            <Col span={20}>
              <Row gutter={24}>
                <Col>
                  <Typography.Title level={5}>Theme Settings</Typography.Title>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name={['theme', 'global', 'primaryColor']} label="Primary Color">
                    <Input type="color" name="primaryColor" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['theme', 'global', 'secondaryColor']} label="Secondary Color">
                    <Input type="color" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['theme', 'global', 'backgroundColor']} label="Background Color">
                    <Input type="color" />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <UrlInput
                    label="Stylesheet URL"
                    name={['theme', 'stylesheet']}
                    extra="Use a CSS style sheet to directly override styles"
                  />
                </Col>
              </Row>
              {embedded ? (
                <Row justify="end">
                  <Col>
                    <Button type="primary" className="btn-primary" onClick={onSave}>
                      Save Settings
                    </Button>
                  </Col>
                </Row>
              ) : null}
              <Row gutter={24} className="mt-3">
                <Col>
                  <Typography.Title level={5}>Auction settings</Typography.Title>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name={['auction', 'hideSeller']}
                    extra="Hide seller name, avatar and bio from the bottom of the auction page"
                    valuePropName="checked">
                    <Checkbox>Hide Seller</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
      <Row justify="center">
        <Col xs={22}>
          <Card>
            <Row>
              <Col xs={24}>
                {isLoading && auctionLoader}
                {auctionId && (
                  <PageViewer
                    url={auctionId ? `${env.MONEGRAPH_AUCTIONS_URL}/auctions/${auctionId}` : ''}
                    config={{
                      theme: {
                        global: {
                          primaryColor: updateConfig.theme.global.primaryColor,
                          secondaryColor: updateConfig.theme.global.secondaryColor,
                          backgroundColor: updateConfig.theme.global.backgroundColor,
                        },
                        stylesheet: updateConfig.theme.stylesheet,
                      },
                      auction: { hideSeller: updateConfig.auction?.hideSeller },
                    }}
                    onReady={() => setIsLoading(false)}
                  />
                )}
              </Col>

              <Col xs={24}>
                <Tabs defaultActiveKey={embedded ? 'wordpress' : 'html'}>
                  <Tabs.TabPane tab="HTML" key="html">
                    <SyntaxHighlighter language="html" style={a11yDark} showLineNumbers>
                      {`<!-- Add this lines to your html -->
<script src="${env.MONEGRAPH_AUCTIONS_URL}/loader.js"></script>
<script type="text/javascript">
  let monegraph = iframeLoader("#yourElementId");
  monegraph.open({
    url: '${env.MONEGRAPH_AUCTIONS_URL}/auctions/${auctionIdParam ?? 'Auction Id Here'}',
    width: "100%",
    height: "100%",
    config: {
      theme: {
        global: {
          backgroundColor: "${updateConfig.theme.global.backgroundColor}",
          primaryColor: "${updateConfig.theme.global.primaryColor}",
          secondaryColor: "${updateConfig.theme.global.secondaryColor}",
        },
        ${updateConfig.theme.stylesheet ? `stylesheet: "${updateConfig.theme.stylesheet}"` : ''}
      },
      auction: {
        hideSeller: ${updateConfig.auction?.hideSeller}
      }
    },
  });
</script>`}
                    </SyntaxHighlighter>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Wordpress" key="wordpress">
                    <SyntaxHighlighter language="html" style={a11yDark} showLineNumbers>
                      {`<!-- Add this shortcode to your page -->
[monegraph_auction id="${auctionIdParam ?? 'Auction Id Here'}" hide_seller="${updateConfig.auction?.hideSeller}" /]`}
                    </SyntaxHighlighter>
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
