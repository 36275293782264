import { gql } from 'apollo-boost';

export const GET_ORGANIZATION_WALLETS = gql`
  query GetORganizationWallets($id: String) @api(name: "polygon") {
    seats(where: { organization: $id }) {
      id
      name
      avatar
      description
    }
  }
`;

export function fetchGallery(sortBy = 'dateStarted', sortDirection = 'asc') {
  return gql`
  query GetGallery($wallets: [String]) @api(name: "ethereum") {
    accounts(where: {id_in: $wallets}) {
      id
      auctions(orderBy: ${sortBy}, orderDirection: ${sortDirection}) {
        id
        type
        status
        dateStarted
        dateCreated
        dateFinalized
        dateEnding
        reservePriceInETH
        quantity
        version
        highestBid {
          bidder {
            id
          }
          amount
        }
        seller {
          id
        }
        history {
          id
          type
          user {
            id
          }
          timestamp
          transactionHash
        }
        bids {
          id
        }
        nft {
          id
          name
          description
          creative
          artist
          quantity
          collection {
            id
            address
            name
          }
        }
      }
    }

    _meta {
      block {
        hash
        number
      }
    }
  }
`;
}
