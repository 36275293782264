/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useCallback } from 'react';

export function useNfts(nfts: Array<string> | undefined) {
  const [tokens, setTokens] = useState<any[] | undefined>(undefined);

  const fetcher = useCallback(async (urls) => {
    const results = await Promise.all(
      urls.map(async (nft: any) => {
        const res = await fetch(nft.metadataUrl);
        const metadata = await res.json();

        const token = {
          ...nft,
          title: metadata.name,
          description: metadata.description,
          creative: metadata.image,
          externalUrl: metadata.external_url,
          archive: metadata?.properties?.archive_link?.description,
          archiveSHA: metadata?.properties?.archive_link_signature?.description,
          animationUrl: metadata.animation_url,
          royaltyAddress: metadata?.properties?.rights_agreement_values?.$ADDRESS,
          json: metadata.json || {},
        };

        return token;
      })
    );

    setTokens(results);
  }, []);

  useEffect(() => {
    if (nfts) {
      fetcher(nfts);
    }
  }, [JSON.stringify(nfts)]);

  return tokens;
}
