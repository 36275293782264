/* eslint-disable css-modules/no-undef-class */
import React from 'react';

import { Layout, Typography, Space } from 'antd';

import useIsEmbedded from 'hooks/use-is-embedded';
import styles from 'layout/Footer.module.css';

import { FooterProps } from './Footer.types';

const { Footer } = Layout;

const { Text } = Typography;

export default function PageFooter({ fixed = false }: FooterProps) {
  const embedded = useIsEmbedded();
  const year = new Date().getFullYear();

  return embedded ? null : (
    <Footer className={fixed ? styles.footerFixed : styles.footer}>
      <Space direction="vertical">
        <Text className={styles.text}>
          © {year} Metadyn Inc.&nbsp;
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/maps/place/231+Bowery,+New+York,+NY+10002/@40.7221318,-73.9927702,17z/data=!3m1!4b1!4m2!3m1!1s0x89c25985d96d7625:0xdc21aec511322dfd">
            250 West Broadway 4, New York, New York 10013
          </a>
          .
        </Text>
        <Text className={styles.text}>
          Say{' '}
          <a className={styles.link} href="mailto:hello@metadyn.io?subject=Say%20hello">
            hello@metadyn.io
          </a>
          . Follow us on{' '}
          <a className={styles.link} href="https://twitter.com/Metadyn_io">
            Twitter
          </a>
          ,{' '}
          <a className={styles.link} href="http://monegraph.tumblr.com">
            Tumblr
          </a>
          , and{' '}
          <a className={styles.link} href="https://www.facebook.com/monegraph">
            Facebook
          </a>
        </Text>
      </Space>
    </Footer>
  );
}
