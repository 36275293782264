import { useEffect } from 'react';

import { Button, Result, Steps, StepsProps } from 'antd';
import { ResultStatusType } from 'antd/lib/result';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { progressAtom } from './state';

const { Step } = Steps;

export default function Progress() {
  const progress = useRecoilValue(progressAtom);
  const resetProgress = useResetRecoilState(progressAtom);

  useEffect(() => {
    return () => resetProgress();
  }, []);

  return progress.status !== '' ? (
    <Result
      status={progress.status as ResultStatusType}
      title={progress.title}
      subTitle={progress.subTitle}
      extra={
        progress.status === 'error'
          ? [
              <Button key="resolve" type="primary" shape="round" size="large" onClick={resetProgress}>
                Resolve Issues
              </Button>,
            ]
          : []
      }>
      <Steps
        current={progress.steps.current}
        percent={progress.steps.percent}
        status={progress.steps.status as StepsProps['status']}>
        <Step title="Validating" description="Validating that all information provided is correct" />
        <Step title="Uploading Metadata" description="Uploading the token metadata and media to IPFS" />
        <Step title="Saving" description="Saving the public mints to the blockchain" />
      </Steps>
    </Result>
  ) : null;
}
