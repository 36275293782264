import { useMemo, useCallback } from 'react';
import { useEffect } from 'react';

import { FormOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Image } from 'antd';
import Meta from 'antd/lib/card/Meta';
import isMobile from 'is-mobile';
import { get, has, values } from 'lodash';
import { useHistory, useLocation } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import Logo from 'assets/metadyn.png';
import useVerifyOrganization from 'hooks/use-verify-organization';
import { login } from 'state/accounts';

import MetaMaskLogo from '../../../assets/metamask.svg';
import WalletConnectLogo from '../../../assets/walletconnect.svg';

import styles from './Login.module.css';
import UnauthorizedAccount from './components/Forbidden';

export default function Login() {
  const wallet = useWallet();
  const location = useLocation<{ nextPath?: string }>();
  const { contents: user } = useRecoilValueLoadable(login(wallet.account));
  const history = useHistory();
  const active = useMemo(() => user && wallet.status === 'connected', [wallet.status, user]);
  const [verified, context, { loading: loadingSignature }] = useVerifyOrganization(user?.organization?.id);

  const hasAccess = useMemo(() => {
    return wallet.status === 'connected' && has(user, 'permissions')
      ? !!values(get(user, 'permissions', [])).filter(Boolean).length
      : null;
  }, [user, wallet.status]);

  useEffect(() => {
    if (hasAccess) {
      if (verified.organizationId) {
        history.replace(location.state?.nextPath || '/');
      } else {
        context.sign();
      }
    }
  }, [hasAccess, verified.organizationId]);

  const connectMetamask = useCallback(() => (active ? null : wallet.connect('injected')), [active]);
  const walletconnect = useCallback(() => (active ? null : wallet.connect('walletconnect')), [active]);

  const metamask = useMemo(() => {
    const actions = [
      <Button
        type="text"
        size="large"
        className={styles.walletButton}
        href="https://metamask.io/download.html"
        target="_blank"
        rel="noreferrer">
        Create Wallet
      </Button>,
    ];

    // @ts-ignore
    if (window.web3) {
      actions.unshift(
        <Button type="text" size="large" className={styles.walletButton} onClick={connectMetamask}>
          Connect
        </Button>
      );
    }

    return isMobile() === false ? (
      <Card
        className={styles.walletCard}
        style={{ width: 300 }}
        cover={<img height="180" alt="example" src={MetaMaskLogo} />}
        actions={actions}>
        <Meta title="MetaMask" description="Connect or Create a wallet with MetaMask" />
      </Card>
    ) : null;
  }, [connectMetamask]);

  const signin = useMemo(() => {
    return hasAccess && !verified.organization ? (
      <Card
        className={styles.walletCard}
        style={{ width: 300 }}
        cover={<FormOutlined style={{ fontSize: '64px', color: '#950F7F', marginTop: 20 }} />}
        actions={[
          <Button
            loading={loadingSignature}
            type="text"
            size="large"
            className={styles.walletButton}
            onClick={() => context.sign()}>
            Sign in
          </Button>,
        ]}>
        <Meta title="Sign in" description="Please sign in to access the platform" />
      </Card>
    ) : null;
  }, [hasAccess, verified.organizationId, loadingSignature]);

  const cards = useMemo(() => {
    return signin
      ? [signin]
      : [
          metamask,
          <Card
            className={styles.walletCard}
            cover={<img height="180" alt="example" src={WalletConnectLogo} />}
            actions={[
              <Button type="text" size="large" className={styles.walletButton} onClick={walletconnect}>
                Connect
              </Button>,
              <Button
                type="text"
                size="large"
                className={styles.walletButton}
                href="https://registry.walletconnect.org/"
                target="_blank"
                rel="noreferrer">
                Create Wallet
              </Button>,
            ]}>
            <Meta title="WalletConnect" description="Connect wallet with WalletConnect or Create Wallet" />
          </Card>,
        ];
  }, [signin, metamask]);

  return (
    <div className={styles.walletContainer}>
      <Row align="middle" justify="center" className="pt-3">
        <Col style={{ textAlign: 'center' }}>
          <Image preview={false} src={Logo} width="30%" />
        </Col>
      </Row>

      <div className={styles.walletInnerContainer}>
        <UnauthorizedAccount account={wallet.account} isAdmin={hasAccess} />

        {cards}
      </div>
    </div>
  );
}
