import React, { useMemo } from 'react';

import { InfoCircleTwoTone } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import cx from 'classnames';
import makeBlockie from 'ethereum-blockies-base64';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { useWallet } from 'use-wallet';

import { login } from 'state/accounts';
import { formatNumber } from 'utils/numberFormatter';

import styles from './BidRow.module.css';
import { BidRowProps } from './BidRow.types';

export function BidRow({ history }: BidRowProps) {
  const wallet = useWallet();
  const account = wallet.account?.toLowerCase();
  const title = useMemo(() => {
    const type = history.type;

    if (type === 'Created') return 'Listed by';
    if (type === 'Minted') return 'Minted by';
    if (type === 'Bid') return 'Bid placed by';
    if (type === 'Finalized') return 'Settled by';

    return 'Bid placed by';
  }, [history.type]);

  const { contents: user } = useRecoilValueLoadable(login(history.user.id));

  const bid = useMemo(() => {
    return history.bid ? (
      <Col>
        <Row>
          <Col className="bid-row-price">
            <Typography.Title level={4} className="m-0">
              {formatNumber(history.bid.amount)} ETH
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="bid-row-price-usd">
            <Typography.Title level={5} className={cx(['m-0 text-end', styles.usdPrice])} type="secondary">
              ${formatNumber(history.bid.amountInUSD)}
            </Typography.Title>
          </Col>
        </Row>
      </Col>
    ) : (
      <Col span={6}></Col>
    );
  }, [history.bid]);

  const rowClass = cx({
    'my-history': account === history.user.id,
  });

  return (
    <Row className={`${styles.container} py-2 px-2 mt-2 ${rowClass} bid-row`} align="middle" wrap={false}>
      <Col className="bid-row-avatar">
        <Avatar size={42} src={makeBlockie(`${user.id}`)} />
      </Col>

      <Col flex={1} className="px-2 bid-row-container">
        <Row>
          <Col flex={1}>
            <Row>
              <Col className="bid-row-title">
                <Typography.Title level={5} className="m-0">
                  {title}&nbsp;
                </Typography.Title>
              </Col>
              <Col className="bid-row-username">
                <Typography.Title level={5} className="m-0" type="secondary">
                  @{history.user.username}
                </Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="bid-row-date">
                <Typography.Text type="secondary">
                  {moment(history.timestamp, 'X').format('MMM D[,] YYYY [at] hh:mm a')}
                </Typography.Text>
              </Col>
            </Row>
          </Col>

          {bid}
        </Row>
      </Col>
      <Col>
        <Row align="middle" justify="center">
          <Link
            to={{
              pathname: `https://etherscan.io/tx/${history.transactionHash}`,
            }}
            target="_blank">
            <InfoCircleTwoTone className={styles.infoIcon} />
          </Link>
        </Row>
      </Col>
    </Row>
  );
}
