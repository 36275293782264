export const EditionsRules = [
  { required: true, message: 'Quantity is required' },
  {
    transform(value: any) {
      return parseInt(value);
    },
    async validator(field: any, value: number) {
      if (value && isNaN(value)) {
        throw new Error(`Invalid number`);
      }

      if (value < 0) {
        throw new Error(`Editions must be greater than 0`);
      }
    },
  },
];
export const PriceRules = [
  {
    required: true,
    message: 'Please set the reserve price',
  },

  {
    async validator(_: any, value: number) {
      if (isNaN(value)) {
        throw new Error('Invalid number');
      } else if (value < 0) {
        throw new Error('Price cannot be less than 0 ETH');
      }
    },
  },
];

export const PercentageRules = [
  {
    required: true,
    message: 'Royalty percentage is required',
  },
  {
    transform(value: any) {
      return parseInt(value);
    },
    async validator(field: any, value: number) {
      if (value && isNaN(value)) {
        throw new Error(`Invalid percentage`);
      }

      if (value < 0 || value > 100) {
        throw new Error(`Royalty of ${value}% is not between 0% and 100%`);
      }
    },
  },
];

export const YearRules = [
  { required: true, message: 'Year is required' },
  {
    transform(value: any) {
      return parseInt(value);
    },
    async validator(field: any, value: number) {
      if (value && isNaN(value)) {
        throw new Error(`Invalid Year`);
      }

      const currentYear = new Date().getFullYear();

      if (isNaN(value) || value < 1900 || value > currentYear) {
        throw new Error(`Please enter a valid year between 1900 and ${currentYear}`);
      }
    },
  },
];
