import { get, mapValues } from 'lodash';

import env from 'lib/env';

export const infuraNetworks = {
  1: 'mainnet',
  3: 'ropsten',
  4: 'rinkeby',
  5: 'goerli',
  42: 'kovan',
};

const infuraId = env.INFURA_ID;

export const currentNetwork = parseInt(env.ETHERNET_CHAIN_ID);
export const currentNetworkName = get(infuraNetworks, env.ETHERNET_CHAIN_ID, 'Unknown');

export default mapValues(infuraNetworks, (network: string) => {
  return `https://${network}.infura.io/v3/${infuraId}`;
});
