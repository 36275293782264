import { atom } from 'recoil';

import { Config } from 'iframeconfig.types';

export const iframeConfigState = atom<Config>({
  key: 'iframeloader.config',
  default: {
    theme: {
      global: {
        backgroundColor: '#f0f2f5',
        primaryColor: '#95107f',
        secondaryColor: '#000000',
      },
    },
    auction: {
      hideSeller: true,
    },
  },
});

export default {
  atoms: { iframeConfigState },
};
