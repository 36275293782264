import React, { useMemo } from 'react';

import { Form, Input } from 'antd';

const { Item } = Form;

export default function ReservePrice({ form }: any) {
  const rules = useMemo(
    () => [
      {
        required: true,
        message: 'Please set the reserve price',
      },

      {
        async validator(_: any, value: string) {
          if (value?.length) {
            const num = value.replace(/[^0-9\.,]/, '');
            const parsed = parseFloat(num);

            if (isNaN(parsed)) {
              throw new Error('Invalid number');
            } else if (parsed <= 0) {
              throw new Error('Minimum reserve price has to be greater than 0 ETH');
            }

            form.setFieldsValue({ reserve: num });
          }
        },
      },
    ],
    [form]
  );

  return (
    <Item label="Reserve Price" required name="reserve" extra="Reserve price in Ethereum" rules={rules}>
      <Input addonAfter="ETH" />
    </Item>
  );
}
