import crypto from 'crypto';

import { Metadata } from './metadata.types';

export function shaFromIPFS(link: string) {
  const uri = new URL(link);

  return uri.pathname.replace('//', '').split('/')[0];
}

export function sha256(value: string) {
  return crypto.createHash('sha256').update(value).digest('hex');
}

export default function createMetadata(values: any): Metadata {
  const contractSHA = sha256(values.language);

  return {
    name: values.title,
    description: values.description,
    external_url: values.externalUrl || '',
    animation_url: values.animationUrl || '',
    image: values.creative,

    attributes: [
      {
        trait_type: 'Artist',
        value: values.artist,
      },
      {
        trait_type: 'Rights Agreement',
        value: values.languageIPFS,
      },
      {
        trait_type: 'Royalty',
        value: values.royalty,
      },
      {
        trait_type: 'Royalty Address',
        value: values.royaltyAddress,
      },
      {
        trait_type: 'Archive Link',
        value: values.archive || 'Not applicable',
      },
      ...(values.traits?.map((trait: any) => {
        trait.display_type = trait.display_type.toLowerCase();

        if (trait.display_type === 'date') {
          trait.value = trait.value.format('X');
        }

        return trait.display_type === 'string'
          ? {
              trait_type: trait.trait_type,
              value: trait.value,
            }
          : trait;
      }) ?? []),
    ],

    properties: {
      name: {
        type: 'string',
        description: values.title,
      },

      description: {
        type: 'string',
        description: values.description,
      },

      image: {
        type: 'string',
        description: values.creative,
      },

      rights_agreement: {
        type: 'string',
        description: values.languageIPFS,
      },

      rights_agreement_signature_type: {
        type: 'string',
        description: 'SHA-256',
      },

      rights_agreement_signature: {
        type: 'string',
        description: contractSHA,
      },

      archive_link: {
        type: 'string',
        description: values.archive,
      },

      archive_link_signature_type: {
        type: 'string',
        description: 'SHA-256',
      },

      archive_link_signature: {
        type: 'string',
        description: values.archiveSHA,
      },

      rights_agreement_values: {
        ...values.contractVariables,
        $CONTRACTSHAHASH: contractSHA,
      },
    },
  };
}
