import { transform } from 'lodash';

const react = 'REACT_APP_';
const gatsby = 'GATSBY_';

export default transform(
  process.env,
  (env, value, key) => {
    if (key.indexOf(react) === 0) {
      env[key.replace(react, '')] = value as string;
    } else if (key.indexOf(gatsby) === 0) {
      env[key.replace(gatsby, '')] = value as string;
    }

    return env;
  },
  {
    NODE_ENV: process.env.NODE_ENV,
  } as Record<string, string>
) as Record<string, string>;
