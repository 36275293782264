import React, { ReactNode, useMemo, useState } from 'react';

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { find, findIndex } from 'lodash';

import SortableItem from './SortableItem';

interface SortableListInputProps<T> {
  onChange?: (value: Array<T>) => void;
  label?: string;
  name?: string;
  value?: Array<T>;
  renderItem: ({ item, index, dragging }: { item: T; index: number; dragging: boolean }) => ReactNode;
  keyExtractor: (item: T) => string;
}

export default function SortableListInput<T>({
  value = [],
  onChange,
  renderItem,
  keyExtractor,
}: SortableListInputProps<T>) {
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event: any) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = findIndex(value, ['value', active.id]);
      const newIndex = findIndex(value, ['value', over.id]);

      const result = arrayMove(value, oldIndex, newIndex);
      onChange?.(result);
    }

    setActiveId(null);
  }

  function handleCancel(event: any) {
    setActiveId(null);
  }

  const itemKeys = useMemo(() => value.map(keyExtractor), [value, keyExtractor]);
  const activeItem = find(value, ['value', activeId]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleCancel}
      modifiers={[restrictToVerticalAxis]}>
      <SortableContext items={itemKeys} strategy={verticalListSortingStrategy}>
        {value.map((item, index) => {
          const key = keyExtractor(item);
          return (
            <SortableItem key={key} id={key}>
              {renderItem({ item, index, dragging: key === activeId })}
            </SortableItem>
          );
        })}
      </SortableContext>
      <DragOverlay>{activeItem ? renderItem({ item: activeItem, index: -1, dragging: false }) : null}</DragOverlay>
    </DndContext>
  );
}
