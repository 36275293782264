import { useCallback, useMemo } from 'react';

import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRecoilState } from 'recoil';

import { dataSourceAtom, editableKeysAtom } from '../state';

export default function ToggleEditing() {
  const [dataSource] = useRecoilState(dataSourceAtom);
  const [editableKeys, setEditableKeys] = useRecoilState<any[]>(editableKeysAtom);

  const onClick = useCallback(() => {
    setEditableKeys(editableKeys.length ? [] : dataSource.map((data) => data.id));
  }, [dataSource, editableKeys]);

  const icon = useMemo(() => {
    return editableKeys.length ? <SaveOutlined /> : <EditOutlined />;
  }, [editableKeys]);

  return dataSource.length ? (
    <Button icon={icon} size="small" shape="circle" type="primary" onClick={onClick}></Button>
  ) : null;
}
