// eslint-disable-next-line import/order
import 'whatwg-fetch';
import './App.css';

import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AppContent } from './AppContent';
import { GlobalStyles } from './GlobalStyles';

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <GlobalStyles />

        <AppContent />
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
