import { useCallback, useMemo, useState } from 'react';

import ProForm from '@ant-design/pro-form';
import { Form, Button, Switch, Row, Col, Typography, Divider } from 'antd';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useWallet } from 'use-wallet';
import { v4 as uuidv4 } from 'uuid';

import Beneficiaries from 'components/BeneficiariesInput/BeneficiariesInput';
import BulkMint from 'components/BulkMint/BulkMint';
import useDataSourceValidator from 'components/BulkMint/hooks/use-datasource-validator';
import Discount from 'components/DiscountInput/DiscountInput';
import TokenQuantity from 'components/TokenQuantity/TokensAllowed';

import { ERC1155 } from 'constants/collection';
import { useCollection } from 'hooks/use-collection';

import EndTime from './EndTime';
import Id from './Id';
import Name from './Name';
import Progress from './Progress';
import { PublicMintFormValue } from './PublicMintForm.type';
import StartTime from './StartTime';
import Wallets from './Wallets';
import Conditions from './conditions/Conditions';
import { progressAtom } from './state';

interface PublicMintFormProps {
  initialValues?: PublicMintFormValue;
  onFinish: (values: PublicMintFormValue) => void;
}

export default function PublicMintForm({ initialValues, onFinish }: PublicMintFormProps) {
  const wallet = useWallet();
  const params = useParams<any>();
  const [progress, setProgress] = useRecoilState(progressAtom);

  const [form] = ProForm.useForm<PublicMintFormValue>();

  const [enableAllowList, setEnableAllowList] = useState(!!initialValues?.allowList || false);
  const [enableGeneralPublic, setEnableGeneralPublic] = useState(!!initialValues?.generalPublic || false);

  const [collection] = useCollection(wallet?.account, params.contract);

  const isERC1155 = collection?.type === ERC1155;

  const validateDataSource = useDataSourceValidator(isERC1155, params.contract);

  const setValidating = useCallback(() => {
    setProgress((progress) => {
      return {
        ...progress,
        status: 'info',
        title: 'Processing Public Mint',
        subTitle: '',
        steps: {
          current: 0,
          percent: 0,
          status: 'process',
        },
        errors: [],
      };
    });
  }, []);

  const onFormSubmit = useCallback(
    async (values: PublicMintFormValue) => {
      setValidating();

      const response = await validateDataSource();

      if (!response.hasError) {
        values.nfts = response.nfts;

        onFinish(values);
      } else {
        setProgress((progress) => {
          return {
            ...progress,
            status: 'error',
            title: 'Processing Public Mint',
            subTitle: '',
            steps: {
              current: 0,
              percent: 0,
              status: 'error',
            },
            errors: ['Error validating token definitions'],
          };
        });
      }
    },
    [validateDataSource, setProgress]
  );

  const generateId = useMemo(() => {
    return uuidv4();
  }, []);

  // @ts-ignore
  global.form = form;

  return (
    <>
      <Progress />
      <Form
        style={{
          display: progress.status ? 'none' : 'block',
        }}
        id="publicMintForm"
        wrapperCol={{ span: 24 }}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFormSubmit}
        scrollToFirstError
        initialValues={initialValues ?? { id: generateId, language: '' }}>
        <Id />
        <Name />
        <Divider />
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Title level={5}>Allow List</Typography.Title>
          </Col>
          <Col>
            <Switch checked={enableAllowList} onChange={(checked) => setEnableAllowList(checked)} />
          </Col>
        </Row>

        {enableAllowList ? (
          <>
            <StartTime name={['allowList', 'startTime']} />
            <EndTime name={['allowList', 'endTime']} minimumDateField={['allowList', 'startTime']} />
            <Row gutter={16}>
              <Col xs={12}>
                <TokenQuantity name={['allowList', 'tokensAllowed']} />
              </Col>
              <Col xs={12}>
                <Discount required={false} name={['allowList', 'discount']} label={'Discount'} />
              </Col>
            </Row>
            <Wallets name={['allowList', 'wallets']} />
          </>
        ) : null}
        <Divider />
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Title level={5}>General Public</Typography.Title>
          </Col>
          <Col>
            <Switch checked={enableGeneralPublic} onChange={(checked) => setEnableGeneralPublic(checked)} />
          </Col>
        </Row>

        {enableGeneralPublic ? (
          <>
            <StartTime name={['generalPublic', 'startTime']} />
            <EndTime name={['generalPublic', 'endTime']} minimumDateField={['generalPublic', 'startTime']} />
            <Row gutter={16}>
              <Col xs={12}>
                <TokenQuantity name={['generalPublic', 'tokensAllowed']} />
              </Col>
              <Col xs={12}>
                <Discount required={false} name={['generalPublic', 'discount']} label={'Discount'} />
              </Col>
            </Row>
          </>
        ) : null}

        <Divider />
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Title level={5}>Conditions</Typography.Title>
          </Col>
        </Row>
        <Conditions />
        <Divider />

        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Title level={5}>Beneficiaries</Typography.Title>
          </Col>
        </Row>

        <Divider />
        <Beneficiaries percentage={5} />
        <Divider />

        <BulkMint
          name="nfts"
          isERC1155={isERC1155}
          form={form}
          collectionId={collection?.id}
          initialValue={initialValues?.nfts}
        />

        <Divider />

        <Form.Item>
          <Button type="primary" className="w-100 btn-primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
