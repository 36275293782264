import React from 'react';

import { Typography } from 'antd';
import cx from 'classnames';
import { useRecoilState } from 'recoil';

import { selectedFilter } from '../Gallery.state';

import styles from './ListItem.module.css';

export default function ArtistListItem({ filter }: any) {
  const [selected, setSelected] = useRecoilState(selectedFilter);
  const active = selected === filter.id;

  return (
    <li className={styles.item} onClick={() => setSelected(active ? null : filter.id)}>
      <Typography.Title level={5} className={cx([styles.text, { [styles.activeItem]: active }])}>
        {filter.name}
      </Typography.Title>
    </li>
  );
}
