import React, { ChangeEvent, useCallback, useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';
import { useSetRecoilState } from 'recoil';

import { useQueryResultsFromHash } from 'hooks/use-query.state';

import { previewState } from '../../state/preview';

const { Item } = Form;

export default function Creative({ hash, form }: { hash: string; form: FormInstance }) {
  const collection = useQueryResultsFromHash(hash);
  const updatePreview = useSetRecoilState(previewState);
  const creative = collection?.creative;
  const onChange = useCallback((event: ChangeEvent<any>) => {
    updatePreview((preview) => {
      return { ...preview, creative: event.target.value };
    });
  }, []);

  useEffect(() => {
    if (creative) {
      form.setFieldsValue({
        creative,
      });

      updatePreview((preview) => {
        return {
          ...preview,
          creative,
        };
      });
    }
  }, [creative]);

  return (
    <Item
      label="Creative"
      required
      name="creative"
      extra="Creative extracted from the nft token"
      normalize={(s) => s.trim()}>
      <Input readOnly={!!creative} onBlur={onChange} />
    </Item>
  );
}
