import { useCallback } from 'react';

import { message } from 'antd';
import { useRecoilState } from 'recoil';

import { dataSourceAtom, editableKeysAtom } from './../state/index';
import useParseCSV from './use-parse-csv';

export default function useImportCSV(form: any, name: string, actionRef: any) {
  const [dataSource, setDataSource] = useRecoilState<any[]>(dataSourceAtom);
  const [, setEditableKeys] = useRecoilState<any[]>(editableKeysAtom);
  const parser = useParseCSV(form, name, actionRef);

  const importer = useCallback(
    (file: any) => {
      var reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          try {
            const rows = parser(reader.result);

            setDataSource([...dataSource, ...rows]);
            setEditableKeys([]);
          } catch (e: any) {
            message.error(e.message);
          }
        }
      };

      reader.readAsText(file.file.originFileObj);
    },
    [dataSource, setDataSource]
  );

  return importer;
}
