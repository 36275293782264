import { useCallback } from 'react';

import { clone, filter, includes, isPlainObject, size } from 'lodash';
import { useRecoilState } from 'recoil';

import { dataSourceAtom, editableKeysAtom, errorsAtom } from 'components/BulkMint/state';

import apolloClient from 'lib/apollo/client';
import { FETCH_TOKEN_IDS } from 'queries/collection';

import useColumns from './use-columns';

async function validateAvailability(collectionId: string, tokenIds: string[]) {
  const { data } = await apolloClient.query({
    query: FETCH_TOKEN_IDS,
    variables: {
      collectionId,
      tokenIds,
    },
  });

  const claimed: string[] = [];

  data.publicMints.forEach((publicMint: any) => {
    publicMint.tokens.forEach((token: any) => {
      if (token.token) {
        claimed.push(token.tokenId);
      }
    });
  });

  const existingTokens = data.collection.tokens.filter(
    (token: any) => tokenIds.includes(token.tokenId) && !claimed.includes(token.tokenId)
  );

  if (existingTokens.length === 1) {
    throw Error(`The token "${existingTokens[0].tokenId}" already exist in the collection`);
  }

  if (existingTokens.length > 0) {
    throw Error(
      `The following tokens "${existingTokens
        .map((token: any) => token.tokenId)
        .join(', ')}" already exist in the collection`
    );
  }
}

export default function useDataSourceValidator(isERC1155: boolean, collection: string) {
  const columns = useColumns(isERC1155);
  const [dataSource, setDataSource] = useRecoilState(dataSourceAtom);
  const [, setEditableKeys] = useRecoilState(editableKeysAtom);
  const [, setErrors] = useRecoilState(errorsAtom);

  return useCallback(async () => {
    const errors: any[] = [];
    const messages: Record<string, any> = {
      global: [],
    };
    const output: any[] = [];

    setErrors({
      global: [],
    });

    setEditableKeys([]);

    for (let i = 0; i < dataSource.length; i++) {
      const field = clone(dataSource[i]);
      const rowMessages: Record<string, any> = {
        title: '',
        messages: [],
      };

      for (let r = 0; r < columns.length; r++) {
        const column: any = columns[r];

        field[column.dataIndex] = column?.formItemProps?.normalize
          ? column?.formItemProps?.normalize(field[column.dataIndex])
          : field[column.dataIndex];

        for (let j = 0; j < column?.formItemProps?.rules?.length || 0; j++) {
          const rule = column?.formItemProps?.rules[j];

          if (isPlainObject(rule)) {
            if (rule.transform) {
              field[column.dataIndex] = rule.transform(field[column.dataIndex]);
            }

            if (rule.required && !field[column.dataIndex]) {
              rowMessages.messages.push(`missing required field ${column.title}`);
            }

            if (rule.validator) {
              try {
                await rule.validator(undefined, field[column.dataIndex]);
              } catch (e: any) {
                rowMessages.messages.push(`${column.title} failed to validate "${e.message}"`);
              }
            }
          }
        }
      }

      if (rowMessages.messages.length === 0) {
        output.push(field);
      } else {
        if (field.tokenId) {
          rowMessages.title = `Token Id ${field.tokenId}`;
        } else if (field.title) {
          rowMessages.title = field.title;
        } else {
          rowMessages.title = 'Error';
        }

        messages[rowMessages.title] = rowMessages;
        errors.push(field);
      }
    }

    if (errors.length) {
      setEditableKeys(errors.map((item) => item.id));
      setDataSource([...errors, ...output]);
    }

    if (!dataSource.length) {
      messages.global.push(`Please add at least one token defintion`);
    }

    const ids = dataSource.map((item) => item.tokenId);
    const duplicates = filter(ids, (val, i, iteratee) => includes(iteratee, val, i + 1));

    if (duplicates.length) {
      messages.global.push(`Duplicate token id(s) found ${duplicates.join(',')}`);
    }

    try {
      await validateAvailability(collection, ids);
    } catch (e: any) {
      messages.global.push(e.message);
    }

    setErrors(messages);

    return {
      nfts: output,
      hasError: size(messages) > 1 || messages.global.length !== 0,
    };
  }, [dataSource]);
}
