import { Form, DatePicker } from 'antd';

import { AuctionType } from 'transforms/auction.types';

const { Item } = Form;

interface StartTimeProps {
  auctionType: AuctionType;
}

export default function StartTime({ auctionType }: StartTimeProps) {
  if (auctionType === AuctionType.BUYNOW) {
    return null;
  }

  return (
    <Item label="Start Time" required name="start" extra="Date at which auction will start">
      <DatePicker
        className="w-100"
        showTime={{
          minuteStep: 15,
        }}
        format="YYYY-MM-DD HH:mm"
      />
    </Item>
  );
}
