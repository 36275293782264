import { useMemo } from 'react';

import { Alert, Tree } from 'antd';
import type { DataNode } from 'antd/lib/tree';
import { each } from 'lodash';
import { useRecoilValue } from 'recoil';

import { errorsAtom } from '../state';

export default function ErrorList() {
  const errors = useRecoilValue(errorsAtom);
  const output: DataNode[] = useMemo(() => {
    const treeData: DataNode[] = [];

    if (errors.global.length) {
      treeData.push({
        title: 'Validation Errors',
        key: '0',
        children: errors.global.map((error: string, idx: number) => {
          return {
            title: error,
            key: `0-${idx}`,
          };
        }),
      });
    }

    let count = 0;

    each(errors, (error, key) => {
      if (key !== 'global' && error.messages.length) {
        const index = ++count;
        treeData.push({
          title: error.title,
          key: `row-${index}`,
          children: error.messages.map((message: string, idx: number) => {
            return {
              title: message,
              key: `row-${index}-${idx}`,
            };
          }),
        });
      }
    });

    return treeData;
  }, [errors]);

  const tree = useMemo(() => {
    return <Tree treeData={output} />;
  }, [output]);

  return output.length ? <Alert message="Validation Errors" description={tree} type="error" /> : null;
}
