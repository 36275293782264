import { selectorFamily } from 'recoil';

import apollo from 'lib/apollo/client';

import { FETCH_ORGANIZATION } from './Organization.queries';

export const organizationSelector = selectorFamily({
  key: 'organization',
  get: (organizationId) => async () => {
    let result = {
      id: organizationId,
      name: '',
      seats: 1,
      accounts: [],
    };

    if (organizationId) {
      const request = await apollo.query({
        query: FETCH_ORGANIZATION,
        variables: {
          id: organizationId,
        },
      });

      return request.data.organizations[0];
    }

    return result;
  },
});
