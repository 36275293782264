import React from 'react';

import { Form, Select } from 'antd';

import { AuctionType } from 'transforms/auction.types';

const { Item } = Form;
const { Option } = Select;

export default function AuctionTypeField() {
  return (
    <Item
      label="Auction Type"
      name="auctionType"
      initialValue={''}
      required
      rules={[
        {
          required: true,
          message: 'Auction type is required',
        },
      ]}>
      <Select defaultValue="" style={{ width: '100%' }}>
        <Option value="">Auction Type</Option>
        <Option value={AuctionType.TRADITIONAL}>Traditional Auction</Option>
        <Option value={AuctionType.BUYNOW}>Buy Now Auction</Option>
      </Select>
    </Item>
  );
}
