import React from 'react';

import { Route } from 'react-router-dom';

import { RouteConfig } from 'config/routes.types';

export default function CompiledRoute(routeProps: RouteConfig ) {
  const exact = routeProps.exact === false ? false : true;

  return (
    <Route
      path={routeProps.path}
      exact={exact}
      render={(props) => {
        return <routeProps.component {...props} />;
      }}
    />
  );
}
