import { Form, Input } from 'antd';

const { Item } = Form;
const { TextArea } = Input;

const rules = [
  {
    required: true,
    message: 'Please provide the description for this creative.',
  },
];

export default function Description({ previewSetter }: any) {
  return (
    <Item
      label="Description"
      rules={rules}
      name="description"
      extra="A work description that appears on 3rd party sites">
      <TextArea  autoSize />
    </Item>
  );
}
