import React from 'react';
import { useCallback } from 'react';

import { FormInstance, TreeSelect, Form } from 'antd';
import { useWallet } from 'use-wallet';

import useLocationQuery from 'hooks/use-location-query';
import { useCollectionTree } from 'pages/admin/create/hooks/use-collection-tree';

const { Item } = Form;

export default function Asset({ form }: { form: FormInstance }) {
  const wallet = useWallet();
  const location = useLocationQuery();

  const [treeData] = useCollectionTree(wallet.account);

  const onChange = useCallback(
    (value) => {
      let contract = '';
      let tokenId = '';

      if (value.indexOf('-') !== -1) {
        [contract, tokenId] = value.split('-');
      } else {
        form.setFieldsValue({
          creative: '',
          name: '',
          description: '',
        });
      }

      form.setFieldsValue({
        tokenId,
        contract,
      });

      form.validateFields(['pseudo']);
    },
    [form]
  );

  return (
    <Item label="Asset" required name="asset" extra="Select the asset for the auction">
      <TreeSelect
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        placeholder="Select Asset"
        treeDefaultExpandAll
        defaultValue={
          location.has('tokenId') && location.has('address')
            ? `${location.get('address')}-${location.get('tokenId')}`
            : void 0
        }
        onChange={onChange}
      />
    </Item>
  );
}
