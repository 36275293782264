import { useCallback } from 'react';

import { parse } from 'csv/dist/esm/sync';
import { camelCase, each } from 'lodash';

import { csvColumnMap, columnAliases } from '../constants/csv-columns';

export default function useParseCSV(form: any, name: string, actionRef: any) {
  const parser = useCallback(
    (content: string) => {
      const headerMap: Record<string, string> = {};
      const output = parse(content, {
        columns(header) {
          return header.map((column: string) => {
            let name = camelCase(column);

            if (columnAliases[name]) {
              name = columnAliases[name];
            }

            headerMap[name] = column;

            return name;
          });
        },
      });

      const csvColumns = Object.keys(csvColumnMap);

      each(output[0], (_, key) => {
        if (csvColumns.indexOf(key) === -1) {
          throw new Error(`Unknown header found "${headerMap[key]}"`);
        }
      });

      return output
        .map((row: any, index: number) => {
          const isEmpty = !Object.values(row).filter(Boolean).length;

          return isEmpty
            ? false
            : {
                id: new Date().getTime() + index,
                editable: true,
                ...row,
              };
        })
        .filter(Boolean);
    },
    [name, form, actionRef]
  );

  return parser;
}
