import { atom, selectorFamily } from 'recoil';

import useMonitorQuery from 'hooks/use-monitor-query';

import { FETCH_TOKENS_BY_COLLECTIONS } from './Tokens.queries';

export const burnState = atom<Record<string, any>>({
  key: 'collection.token.burn',
  default: {
    visible: false,
    id: null,
    max: 1,
    transacting: false,
  },
});

export const collectionsAtom = atom<Record<string, any>>({
  key: 'collection.tokens',
  default: {},
});

export const collectionFamily = selectorFamily<Record<string, any>, string>({
  key: 'collection.tokens.selector',
  get(collectionId: string) {
    return ({ get }) => {
      const collections = get(collectionsAtom);

      return collections[collectionId.toLowerCase()];
    };
  },
});

export function useWatchCollection(accountId: string | null, collectionId: string, organization: string) {
  return useMonitorQuery(
    {
      query: FETCH_TOKENS_BY_COLLECTIONS,
      variables: {
        accountId: accountId?.toLowerCase(),
        collectionId: collectionId.toLowerCase(),
        organization,
      },
      transform(response) {
        const { data } = response;
        let collection = data?.collection;

        if (!collection) {
          collection = {
            id: collectionId.toLowerCase(),
            address: collectionId,
            tokens: [],
          };
        }

        return {
          contract: data.organizationContract,
          collection: {
            ...collection,
            tokens: collection.tokens.filter((token: Record<string, any>) => token.holders.length > 0),
          },
        };
      },
    },
    [accountId, collectionId]
  );
}
