import { Form, Modal, Input } from 'antd';
// @ts-ignore
import addressCheck from 'ethereum-checksum-address';
import { useWallet } from 'use-wallet';
import web3 from 'web3';

import Controller from 'contracts/controller';
import useContract from 'hooks/use-contract';
import useExecuteMethod from 'hooks/use-execute-method';
import env from 'lib/env';

export default function UserForm({ visible, closeModal }: any) {
  const [form] = Form.useForm();
  const wallet = useWallet();
  const controller = useContract(Controller, {
    address: env.MONEGRAPH_CONTROLLER,
    provider: new web3.providers.HttpProvider(env.MONEGRAPH_RELAY_PROVIDER),
  });
  const executeMethod = useExecuteMethod();

  const walletRules = [
    {
      required: true,
      async validator(field: any, address: string) {
        addressCheck.toChecksumAddress(address);
      },
    },
  ];

  const saveUser = () => {
    form.submit();
  };

  const onFinish = async (values: any) => {
    alert('switch to relay method');
    await executeMethod(
      controller.grantRole,
      ['0xafd412158ad8a0b56aeadccf609843677ba8e5373ecaa03bb8daac2189532d38', values.wallet],
      {
        from: wallet.account,
      }
    );

    closeModal();
  };

  return (
    <Modal visible={visible} title="New User" onOk={saveUser} onCancel={closeModal}>
      <Form form={form} id={'userForm'} name="userForm" size="large" layout="vertical" onFinish={onFinish}>
        <Form.Item label="Wallet" name="wallet" rules={walletRules}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
