import React from 'react';

import { Form, Input } from 'antd';
import { parseInt } from 'lodash';

import { ERC1155 } from 'constants/collection';

import styles from './Editions.module.css';

const { Item } = Form;

const rules = [
  {
    required: true,
    message: 'Please set the number of editions',
  },
  {
    async validator(field: any, value: string) {
      const num = parseInt(value);

      if (isNaN(num) || num <= 0) {
        throw new Error(`Editions must be a positive number, received ${num}`);
      }
    },
  },
];

export default function Editions({ type }: any) {
  return type === ERC1155 ? (
    <Item
      label="Editions"
      rules={rules}
      name="quantity"
      extra="The number of editions to be minted"
      validateFirst={true}
      normalize={(value) => {
        return value.replace(/[^\d]+/g, '');
      }}>
      <Input className={styles.input} />
    </Item>
  ) : null;
}
