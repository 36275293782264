import { gql } from 'apollo-boost';

export const FEATURED_AUCTION = gql`
  query GetFeaturedAuction @api(name: "ethereum") {
    auctions(first: 1, orderBy: dateStarted, orderDirection: desc) {
      id
      type
      status
      dateStarted
      dateCreated
      dateFinalized
      dateEnding
      quantity
      reservePriceInETH
      version
      highestBid {
        bidder {
          id
        }
        amount
      }
      seller {
        id
      }
      history {
        id
        type
        user {
          id
        }
        timestamp
        transactionHash
      }
      bids {
        id
        bidder {
          id
        }
        datePlaced
        history {
          id
          type
          timestamp
          transactionHash
        }
      }
      nft {
        id
        name
        description
        creative
        quantity
        tokenId
        collection {
          id
        }
      }
    }
  }
`;

export const AUCTIONS = gql`
  query GetAuctions @api(name: "ethereum") {
    auctions(orderBy: dateCreated, orderDirection: desc, where: { dateCreated_gt: 1624939400, status: "Open" }) {
      id
      type
      status
      dateStarted
      dateCreated
      dateFinalized
      dateEnding
      quantity
      reservePriceInETH
      version
      seller {
        id
      }
      history {
        id
        type
        user {
          id
        }
        timestamp
        transactionHash
      }
      nft {
        id
        name
        description
        creative
        quantity
        tokenId
        collection {
          id
        }
      }
    }
  }
`;
