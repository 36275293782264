export const ArchiveSHARule = [
  {
    async validator(_: any, value: string) {
      if (value?.length) {
        if (value.length < 64) {
          throw new Error('Invalid sha256 hash, hash should be 64 characters in length');
        }
      }
    },
  },
];
