import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';

import { Layout, Grid, Spin, Modal } from 'antd';
import { useWallet } from 'use-wallet';

import useCheckNetwork from 'hooks/use-check-network';
import { currentNetwork, currentNetworkName } from 'lib/wallet-connect/connectors';
import usePermissions from 'pages/admin/hooks/use-permissions';

import styles from './AdminLayout.module.css';
import { AdminLayoutProps } from './AdminLayout.types';
import Footer from './admin/Footer';
import Header from './admin/Header';
import Sidebar from './admin/Sidebar';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function AdminLayout({ children }: AdminLayoutProps) {
  const wallet = useWallet();
  const check = useCheckNetwork();
  const breakpoints = useBreakpoint();
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const { loading } = usePermissions();
  const isMobile = breakpoints.md === false;
  const wrongNetwork = wallet.account && wallet.chainId !== currentNetwork;

  const monitorNetwork = useCallback(async () => {
    if (wrongNetwork) {
      const connected = await check();

      if (!connected) {
        Modal.destroyAll();

        Modal.warning({
          title: 'Incorrect Ethereum Network',
          content: `You are currently connected to the ${wallet.networkName} network, please switch to the ${currentNetworkName} network`,
          okButtonProps: {
            hidden: true,
          },
          closable: false,
        });
      }
    } else {
      Modal.destroyAll();
    }
  }, [wrongNetwork]);

  useEffect(() => {
    setTimeout(monitorNetwork, 300);
  }, [wrongNetwork]);

  if (loading) {
    return (
      <Spin size="large">
        <Layout className={styles.loadinContainer}></Layout>
      </Spin>
    );
  }

  return (
    <Layout className={styles.container}>
      <Sidebar collapsed={sidebarCollapse} isMobile={isMobile} onCollapse={setSidebarCollapse} />
      <Layout className={styles.main}>
        <Header
          isMobile={isMobile}
          onShowDrawer={() => {
            setSidebarCollapse(false);
          }}
        />
        <Content className={styles.contentContainer}>{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
}
