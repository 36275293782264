import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';

import Condition from './Condition';

const { List } = Form;

export default function Conditions() {
  return (
    <List name="conditions">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, i) => (
            <Condition key={field.key} field={field} onRemove={() => remove(i)} />
          ))}
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            Add Condition
          </Button>
        </>
      )}
    </List>
  );
}
