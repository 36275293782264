import React, { useCallback, useState } from 'react';

import { Button, Card, Col, Form, Row, Spin, Typography } from 'antd';

import Layout from 'layout/AdminLayout';

import OrganizationForm from '../components/OrganizationForm';

const { useForm } = Form;

export default function MyOrganization() {
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onFinish = (values: any) => {
    setLoading(true);
  };

  const onChangeFields = useCallback(() => {
    setDisabled(form.getFieldsError().some((item) => item.errors.length > 0));
  }, [form]);

  return (
    <Layout>
      <Row className="mb-3">
        <Col span={24}>
          <Card>
            <Typography.Title>My Organization</Typography.Title>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={23}>
          <Card>
            <Row justify="center" className="p-2">
              <Col xs={24} md={18} lg={12}>
                <Spin spinning={loading}>
                  <OrganizationForm organizationId="" initialValues={{}} form={form} onFinish={onFinish} />
                  <Form form={form} onFieldsChange={onChangeFields}>
                    <Form.Item>
                      <Button type="primary" className="w-100 btn-primary mt-3" htmlType="submit" disabled={disabled}>
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
